import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the events gearlist settings page.
 */
const eventsGearlistSettingsMeta: RouteMeta = {
  name: 'events.gearlist.settings',
  path: '/events/gearlist/settings',
  title: 'Settings',
  description: {
    name: 'description',
    content: 'Events - Gearlist Settings'
  },
  showOnNav: true,
  order: 1,
  requiresAuth: true,
  show: true,
  permissions: ['subscribers settings | view']
}

export default eventsGearlistSettingsMeta
