import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Outfit By Product page.
 */
const outfitByProductPageMeta: RouteMeta = {
  name: 'vfits.products.',
  title: 'Outfit By Product',
  path: '/vfits/outfit-by-product',
  description: {
    name: 'description',
    content: 'Outfit By Product Page'
  },
  //isBeta: true,
  order: 1,
  requiresAuth: true,
  show: true,
  permissions: ['vfits outfit by product | view']
}

export default outfitByProductPageMeta
