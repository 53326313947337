import type * as T from '~/factories/api/azure.api.types'
import type * as IT from './inventory.types'

export async function getSingleProductInventory(
  region: T.region,
  stockCode: string,
  query: IT.GetSingleProductInventoryQuery | null = null
): Promise<IT.InventoryItemResult | null> {
  return await useWretch('FrAPI')
    .query(query ?? {})
    .get(`/inventory/inventory/inventory/${region}/${stockCode}`)
    .json<IT.InventoryItemResult>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getSingleProductInventory:', err)
      return null
    })
}
