/**
 * Auth State
 * 2 errors on this file
 */
import printerStore from '~/features/printer/store/state'
import type {
  Menu_type_tabs as Menu_type_tabsTYPE,
  PermissionsTypes
} from './types/permissions'
import type { PrinterStateType } from '~/features/printer/store/types'
import type { UserType } from '~/features/auth/api/types'
import type { AIMessageType } from '~/features/chatbot/api/types'

export type Menu_type_tabs = Menu_type_tabsTYPE

const countries = [
  {
    name: 'RSA',
    label: 'South Africa',
    short_code: 'za',
    language: 'en',
    coordinates: [18.4233, -33.918861],
    zoom: 3.5,
    selected: true
  },
  {
    name: 'EUR',
    label: 'Germany',
    short_code: 'de',
    language: 'de',
    coordinates: [1.1413863, 43.8120775],
    zoom: 4,
    selected: true
  },
  {
    name: 'USA',
    label: 'United States',
    short_code: 'us',
    language: 'en',
    coordinates: [-109.5149962, 39.1350247],
    zoom: 4,
    selected: true
  },
  {
    name: 'AUS',
    label: 'Australia',
    short_code: 'au',
    language: 'en',
    coordinates: [128.167426, -26.4354576],
    zoom: 3.5,
    selected: true
  },
  {
    name: 'AUS_DOAU',
    label: 'Dometic Australia',
    short_code: 'au',
    language: 'en',
    coordinates: [128.167426, -26.4354576],
    zoom: 4,
    selected: false
  }
]

export const initialState_auth: initialStateType_auth = {
  user: null,
  notifications: {
    unread: [],
    messages: []
  },
  showDrawer: true,
  showHeaderToolbar: true,
  darkMode: false,
  dashboardCards: [
    {
      name: 'stats-panel',
      id: 1
    },
    {
      name: 'motivational-message-card',
      id: 2
    },
    {
      name: 'quicklinks',
      id: 2
    },
    {
      name: 'right-panel',
      id: 3
    }
  ],
  theme: {
    type: 'light',
    label: 'Light',
    icon: 'img:/icons/sun.svg'
  },
  themeStyle: {
    type: 'chowey',
    label: 'Chowey',
    icon: 'fal fa-paw'
  },
  motivationalMessage: '',
  // @ts-expect-error This may be problematic later. FIXME: @Arne
  selectedCountry: countries[0],
  // @ts-expect-error This may be problematic later. FIXME: @Arne
  countries,
  printer: printerStore,
  currentPage: '',
  currentPageMeta: {
    title: '',
    meta: {
      description: {
        name: 'description',
        content: ''
      }
    },
    path: '',
    functions: []
  },
  loggedIn: false,
  showLoginModal: false,

  /**
   * Chatbot state
   */
  chats: [],
  selectedChat: {
    id: 0,
    name: '',
    messages: [],
    date: ''
  },

  /**
   * Routes
   */
  routes: []
}

/**
 * User permissions type
 */
export type permissionsTypes = PermissionsTypes

/**
 * User Auth interface
 */
export type initialStateType_auth = {
  user: UserType | null
  showDrawer: boolean
  showHeaderToolbar: boolean
  dashboardCards: {
    name: string
    id: number
  }[]
  darkMode: boolean
  theme: themeType
  themeStyle: themeStyleType
  selectedCountry: countrytype
  countries: countrytype[]
  printer: PrinterStateType
  currentPage: string
  currentPageMeta: pageMetaType
  loggedIn: boolean
  showLoginModal: boolean
  motivationalMessage: string
  /**
   * Chatbot state
   */
  chats: {
    id: number
    name: string
    messages: AIMessageType[]
    date: string
  }[]
  selectedChat: {
    id: number
    name: string
    messages: AIMessageType[]
    date: string
  }
  routes: {
    name: 'FSYNC' | 'FRAPIS' | string
    APIs: {
      id: string
      name: string
    }[]
  }[]
}

export type pageMetaType = {
  title: string
  description?: string
  path?: string
  meta?: {
    description: {
      name: string
      content: string
    }
  }
  functions?: {
    name: string
    description: string
    actions?: {
      name: string
      description: string
      API_CALL: string
    }[]
  }[]
}

export type themeType = {
  type: 'light' | 'dark'
  label: string
  icon: string
}

export type themeStyleType = {
  type: 'neo' | 'chowey'
  label: string
  icon: string
}

/**
 * User Register interface
 */
export interface registerTypes {
  name: string
  email: string
  password: string
  repeatPassword: string
}

/**
 * User Login interface
 */
export interface loginTypes {
  body: {
    email: string
    password: string
  }
  rememberMe: boolean
}

/**
 * User passwordReset interface
 */
export interface passwordResetTypes {
  token: string
  data: { password: string }
}

export type CountryName = 'RSA' | 'EUR' | 'USA' | 'AUS' | 'AUS_DOAU'
export type CountryNamesMapped = ['RSA', 'EUR', 'USA', 'AUS', 'AUS_DOAU']

export interface countrytype {
  name: CountryName
  label: string
  short_code: string
  language: string
  coordinates: number[]
  zoom: number
  selected: boolean
}

export type tokenTypes =
  | 'authorization_token'
  | 'ftoken'
  | 'syspro_authorization_token'
