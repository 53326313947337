import {
  CampfirePrinter,
  printStrategies
} from '~/features/printer/store/printer-class.client'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      CampfirePrinter,
      printStrategies
    }
  }
})
