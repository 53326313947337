async function downloadPerformanceReviewSummary(
  performanceReviewId: number,
  timezoneOffset: number = 0
): Promise<Blob | null> {
  return await useWretch('FrAPI')
    .get(
      `/performance_review/documents/summary/${performanceReviewId}?timezoneOffset=${timezoneOffset}`
    )
    .blob()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in downloadPerformanceReviewSummary:', err)
      return null
    })
}

export const document = {
  downloadPerformanceReviewSummary
}
