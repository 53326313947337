/**
 * ================================================
 * Auth factory API
 * This factory contains all the methods for interacting with the auth feature.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */

import type {
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions
} from '@tanstack/vue-query'

import { useMutation, useQuery } from '@tanstack/vue-query'
/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'
import useAuthStore from '~/features/auth/store'
import { httpErrorHelper } from '~/factories/api/azure.api.error.helper'
import { setHeader } from '~/src/boot/axios'
import { themeColours, themeStyles } from '~/config/themes.config'
import { version } from '~/package.json'

export const isSyncingUser = ref(false)

/**
 * @description Login the user call
 */
export const login = async (
  payload: T.loginRequest
): Promise<T.loginResponse | Error> => {
  isSyncingUser.value = true
  return await useWretch('permissionsAPI')
    .url('/auth/login')
    .json(payload)
    .post()
    .unauthorized((err) => {
      const errorMessage = JSON.parse(err.message).message
      throw new Error(errorMessage || 'Login failed')
    })
    .json<T.loginResponse>()
    .then(async (res) => {
      if (!res) {
        throw new Error('Login failed')
      }
      return await setUserProfileWithTokenAndUser(res)
    })
    .finally(() => (isSyncingUser.value = false))
}

/**
 * Login the user mutation
 */

const useLogin = (payload: {
  username?: T.loginRequest['username']
  password?: T.loginRequest['password']
  options?: UseMutationOptions<T.loginResponse, Error, T.loginRequest, unknown>
}): UseMutationReturnType<T.loginResponse, Error, T.loginRequest, unknown> =>
  useMutation({
    mutationFn: async (data: T.loginRequest) => {
      const response = await login(data)
      if (response instanceof Error) {
        throw response
      }

      return response
    },
    ...payload.options
  })

/**
 * Logout - Call
 */
export const logout = async (): Promise<T.logoutResponse> =>
  await useWretch('permissionsAPI')
    .url('/auth/logout')
    .get()
    .res()
    .then(() => {
      logtail({
        message: `
        ====================
        logout success for ${(useAuthStore().user?.id && useAuthStore().user?.email) || 'no user'}
        ====================
        `,
        user: useAuthStore().user?.id ? useAuthStore().user?.email : 'no user',
        appVersion: version
      })

      const ftoken = useCookie('ftoken')
      const authorization_token = useCookie('authorization_token')
      const syspro_authorization_token = useCookie('syspro_authorization_token')

      ftoken.value = null
      authorization_token.value = null
      syspro_authorization_token.value = null
      localStorage.removeItem('authorization_token')
      useAuthStore().user = null
      useAuthStore().showLoginModal = true
      useAuthStore().loggedIn = false
      useAuthStore().motivationalMessage = ''

      useRouter().push('/')

      return { success: true }
    })
    .catch((err) => err)

/**
 * Logout - Query
 *
 * Custom hook for logging out.
 * @returns The result of the useQuery hook for logging out.
 */
const useLogout = (options?: UseQueryOptions) =>
  useQuery({
    queryKey: ['logout'],
    queryFn: logout,
    ...options
  })

const useVerifyMe = (payload: { options?: UseQueryOptions<T.loginResponse> }) =>
  useQuery({
    queryKey: ['verify'],
    queryFn: () =>
      useWretch('permissionsAPI')
        .url('/auth/ad/verify')
        .post()
        .json<T.loginResponse>()
        .then(async (res) => {
          return await setUserProfileWithTokenAndUser(res)
        })
        .catch((err) => {
          console.log('login error', err)

          notifier({
            message: err.message,
            type: 'error'
          })
          return err
        }),
    ...payload.options
  })

/**
 * Fetch the user profile query
 */
const useFetchMe = (options?: UseQueryOptions<T.fetchMeResponse>) => {
  const authorization_token_cookie = useCookie('authorization_token')
  const authorization_token_local = localStorage
    .getItem('authorization_token')
    ?.toString()

  const user = useUser()

  const token = computed(
    () =>
      !!(
        authorization_token_cookie.value !== null &&
        authorization_token_cookie.value !== undefined
      ) ||
      !!(
        authorization_token_local &&
        authorization_token_local !== undefined &&
        authorization_token_local.length > 0
      )
  )

  return useQuery({
    queryKey: ['fetchMe'],
    queryFn: async () => {
      isSyncingUser.value = true
      return await useWretch('permissionsAPI')
        .url('/auth/me')
        .get()
        .error(401, async (err) => {
          notifier({
            message: JSON.parse(err.message).message,
            type: 'error'
          })

          await logout()
          return false
        })
        .json<T.fetchMeResponse>()
        .then(async (data) => await setUserProfileWithTokenAndUser(data))
        .catch((err) => err)
        .finally(() => (isSyncingUser.value = false))
    },
    // Every 10 minute
    refetchInterval: 600000,
    enabled: !!user.value?.id || token.value,
    ...options
  })
}

/**
 * Set user profile with token and user data. Cookies are set here
 */
const setUserProfileWithTokenAndUser = async (
  res: T.loginResponse
): Promise<T.loginResponse> => {
  const authStore = useAuthStore()

  const user = useUser()

  const themeColour = computed({
    get: () => authStore.theme,
    set: (value) => (authStore.theme = value)
  })

  const themeStyle = computed({
    get: () => authStore.themeStyle,
    set: (value) => (authStore.themeStyle = value)
  })

  const authorization_token = useCookie('authorization_token')

  // Clear the token if it's not present
  if (!res.adtoken) {
    if (authorization_token.value && authorization_token.value.length > 0) {
      authorization_token.value = null
    }

    if (localStorage.getItem('authorization_token')) {
      localStorage.removeItem('authorization_token')
    }
  }

  if (res.adtoken) {
    authorization_token.value = res.adtoken

    // if token length is longer than the max allowed cookie length, save it in local storage
    if (res?.adtoken?.length > 4093) {
      localStorage.setItem('authorization_token', res?.adtoken)
    }
  }

  if (user.value && !user.value?.profilePicture) {
    user.value.profilePicture = (await getProfilePicture(res.adtoken)) || ''
  }

  authStore.loggedIn = true

  user.value = {
    ...user.value,
    ...res.user
  }

  themeColour.value =
    themeColours.find(({ type }) => type === res.user.themeColor) ||
    themeColour.value
  themeStyle.value =
    themeStyles.find(({ type }) => type === res.user.themeStyle) ||
    themeStyle.value

  await authStore.getRoutes()

  await setHeader()

  return res
}

const blobToDataUrl = (blob: Blob) =>
  new Promise<string>((resolve) => {
    const a = new FileReader()
    a.onload = () => {
      resolve(a.result as string)
    }
    a.readAsDataURL(blob)
  })

/**
 * Get Profile picture
 */
export const getProfilePicture = async (token?: string | null | undefined) =>
  await useWretch('FrAuthAPI')
    .auth(`Bearer ${token}`)
    .url('/api/system/retrieveUserDetails/me/photo')
    .get()
    .error(404, () => null)
    .blob()
    .then((data) => blobToDataUrl(data))
    .catch(() => '')

/**
 * @description Get the Syspro business object token from the AD token
 * @param region authRegion
 * @returns {T.authenticateSysproOperatorResponse}
 */
export const getSysproBusinessObjectTokenFromAdToken = async (
  region: T.authRegion
): Promise<T.authenticateSysproOperatorResponse> =>
  await useWretch('FrAuthAPI')
    .url(`/api/system/retrieveUserDetails/me/sysproToken/${region}`)
    .get()
    .error(401, () => {
      return null
    })
    .json<T.authenticateSysproOperatorResponse>()
    .then((response) => {
      const syspro_authorization_token = useCookie('syspro_authorization_token')
      syspro_authorization_token.value = response.access_token
      return response
    })
    .catch((err) => err)

/**
 * @description Get the Syspro operator information``
 * @param region authRegion
 * @returns {T.authenticateSysproOperatorResponse}
 */
export const getSysproOperatorInfo =
  async (): Promise<T.SysproOperatorInfoResponse | null> =>
    await useWretch('frSysproAuth')
      .url('/api/system/operator/info')
      .get()
      .json<T.SysproOperatorInfoResponse>()
      .then((response) => {
        return response
      })
      .catch((err) => {
        console.error('Error in getSysproOperatorInfo:', err)
        return null
      })

/**
 * @description Get the Syspro business object token from the AD token
 * @companyId sysproCompany
 * @userName string
 * @password string
 * @param [silentNoAuthError=false] boolean
 * @returns {T.authenticateSysproOperatorResponse}
 */
export const authenticateSysproOperator = async (
  companyId: T.sysproCompany,
  userName: string,
  password: string,
  silentNoAuthError = false
): Promise<T.authenticateSysproOperatorResponse> =>
  await useWretch('FrAPI')
    .url('/performance_review/authorize/erp')
    .json({
      company: companyId,
      username: userName,
      password: password
    })
    .post()
    .error(500, (error) => {
      if (silentNoAuthError) {
        return null
      } else {
        httpErrorHelper(error)
      }
    })
    .json<T.authenticateSysproOperatorResponse>()
    .then((response) => {
      const syspro_authorization_token = useCookie('syspro_authorization_token')
      syspro_authorization_token.value = response.access_token
      return response
    })
    .catch((err) => err)

export const clearSysproToken = () => {
  const syspro_authorization_token = useCookie('syspro_authorization_token')
  syspro_authorization_token.value = null
}

const getPartitionValues = async (
  collection: string,
  partition: string
): Promise<T.SettingsDictionary> =>
  await useWretch('FrAuthAPI')
    .url(`/api/platform/keyValueStore/${collection}/${partition}`)
    .get()
    .json<T.SettingsDictionary>()
    .then((res) => res)
    .catch((err) => err)

const getPartitionKeyValue = async (
  collection: string,
  partition: string,
  key: string,
  silentNoAuthError = false
): Promise<T.Setting> =>
  await useWretch('FrAuthAPI')
    .url(`/api/platform/keyValueStore/${collection}/${partition}/${key}`)
    .get()
    .error(500, (error) => {
      if (silentNoAuthError) {
        return null
      } else {
        httpErrorHelper(error)
      }
    })
    .error(404, (error) => {
      //setting not found
      if (silentNoAuthError) {
        return null
      } else {
        httpErrorHelper(error)
      }
    })
    .json<T.Setting>()
    .then((res) => res)
    .catch((err) => err)

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useFetchMe,
  useLogout,
  useVerifyMe
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  useLogin
}

/**
 * ================================================
 * Platform ?
 * ================================================
 */
export const platform = {
  getPartitionValues,
  getPartitionKeyValue
}

/**
 * ================================================
 * Factory
 * ================================================
 */
const auth = {
  getSysproBusinessObjectTokenFromAdToken,
  getSysproOperatorInfo,
  authenticateSysproOperator,
  clearSysproToken,
  login,
  logout,
  queries,
  platform,
  mutations
}

export default auth
