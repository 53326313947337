import type { PrinterType } from './types'

export default [
  {
    printer_config: 'delivery_notes',
    printer_name: 'Delivery Notes',
    printer: null,
    auto_print: false,
    copies: 1,
    paper_size: 'A4',
    paper_sizes: ['A4'],
    paper_media: 'DefaultSize',
    paper_fit: false,
    paper_autocenter: false,
    paper_autorotate: false,
    paper_grayscale: false,
    file_name: 'delivery_notes',
    paper_type: null,
    contents: ''
  } as PrinterType,
  {
    printer_config: 'carrier_labels',
    printer_name: 'Carrier Labels',
    printer: null,
    auto_print: false,
    copies: 1,
    paper_size: '4x6',
    paper_sizes: ['4x6'],
    file_type: 2,
    paper_media: 'DefaultSize',
    paper_fit: false,
    paper_autocenter: false,
    paper_autorotate: false,
    paper_grayscale: false,
    file_name: 'carrier_labels',
    paper_type: null,
    contents: ''
  } as PrinterType,
  {
    printer_config: 'component_labels',
    printer_name: 'Component Labels',
    printer: null,
    auto_print: false,
    copies: 1,
    paper_size: '',
    paper_sizes: [],
    file_type: 2,
    paper_media: 'DefaultSize',
    paper_fit: false,
    paper_autocenter: false,
    paper_autorotate: false,
    paper_grayscale: false,
    file_name: 'component_labels',
    paper_type: null,
    contents: ''
  } as PrinterType,
  {
    printer_config: 'collection_labels',
    printer_name: 'Collection Labels',
    printer: null,
    auto_print: false,
    copies: 1,
    paper_size: '',
    paper_sizes: [],
    file_type: 2,
    paper_media: 'DefaultSize',
    paper_fit: false,
    paper_autocenter: false,
    paper_autorotate: false,
    paper_grayscale: false,
    file_name: 'collection_labels',
    paper_type: null,
    contents: ''
  } as PrinterType,
  {
    printer_config: 'receipts',
    printer_name: 'Receipts',
    printer: null,
    auto_print: false,
    copies: 1,
    paper_size: '',
    paper_sizes: [],
    file_type: 2,
    paper_media: 'DefaultSize',
    paper_fit: false,
    paper_autocenter: false,
    paper_autorotate: false,
    paper_grayscale: false,
    file_name: 'receipts',
    paper_type: null,
    contents: ''
  } as PrinterType,
  {
    printer_config: 'tax_invoices',
    printer_name: 'Tax Invoices',
    printer: null,
    auto_print: false,
    copies: 1,
    paper_size: '',
    paper_sizes: [],
    file_type: 2,
    paper_media: 'DefaultSize',
    paper_fit: false,
    paper_autocenter: false,
    paper_autorotate: false,
    paper_grayscale: false,
    file_name: 'tax_invoices',
    paper_type: null,
    contents: ''
  } as PrinterType,
  {
    printer_config: 'quotes',
    printer_name: 'Quotes',
    printer: null,
    auto_print: false,
    copies: 1,
    paper_size: '',
    paper_sizes: [],
    file_type: 2,
    paper_media: 'DefaultSize',
    paper_fit: false,
    paper_autocenter: false,
    paper_autorotate: false,
    paper_grayscale: false,
    file_name: 'quotes',
    paper_type: null,
    contents: ''
  } as PrinterType
]
