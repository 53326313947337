/**
 * ================================================
 * Sales Feature - Affiliates Factory
 * This factory contains all the methods for interacting with the affiliates feature.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import {
  type QueryFunctionContext,
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import qs from 'qs'
import { exportFile } from 'quasar'

/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'

/**
 * ================================================
 * API Routes
 * ================================================
 */
const API_ROUTE = '/api/v2/sales/affiliates'

/**
 * @description getPayments - Call | Retrieves payments data based on the provided pagination parameters.
 * @param pagination - The pagination parameters to be used for retrieving payments data.
 * @returns A promise that resolves to the payments data response.
 */
export const getPayments = async (
  context: QueryFunctionContext
): Promise<T.getPaymentsResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_ROUTE}/payments`)
    .query(
      qs.stringify(context.queryKey[1], { encode: false, skipNulls: true })
    )
    .get()
    .json<T.getPaymentsResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * @description getPayments - Query | Returns a query result for getting payments.
 * @param payload An object containing the pagination and options for the query.
 * @returns The query result for getting payments.
 */
const useGetPayments = (payload: {
  pagination: ComputedRef<T.PaginationType>
  options?: UseQueryOptions<T.getPaymentsResponse>
}) =>
  useQuery({
    queryKey: ['getPayments', payload.pagination],
    queryFn: getPayments,
    ...payload.options
  })

/**
 * @description getAccounts - Call | Retrieves a list of accounts based on the provided pagination parameters.
 * @param pagination - The pagination parameters to use for the request.
 * @returns A Promise that resolves to the response data or rejects with an error.
 */
export const getAccounts = async (
  context: QueryFunctionContext
): Promise<T.getAccountsResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_ROUTE}/accounts`)
    .query(
      qs.stringify(context.queryKey[1], { encode: false, skipNulls: true })
    )
    .get()
    .json<T.getAccountsResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * @description getAccounts - Query | Custom hook to fetch accounts data from the API.
 * @param payload - Object containing pagination and options for the query.
 * @param payload.pagination - ComputedRef object containing pagination data.
 * @param payload.options - Optional options for the useQuery hook.
 * @returns Query result object containing accounts data.
 */
const useGetAccounts = (payload: {
  pagination: ComputedRef<T.PaginationType>
  options?: UseQueryOptions<T.getAccountsResponse>
}) =>
  useQuery({
    queryKey: ['getAccounts', payload.pagination],
    queryFn: getAccounts,
    ...payload.options
  })

/**
 * @description getAccount - Call | Retrieves the account information for the given account ID.
 * @param accountId - The ID of the account to retrieve.
 * @returns A promise that resolves to the account information.
 */
export const getAccount = async (
  context: QueryFunctionContext
): Promise<T.getAccountResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_ROUTE}/accounts/${context.queryKey[1]}`)
    .get()
    .json<T.getAccountResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * @description getAccount - Query | Retrieves the account information for the given account ID.
 * @param payload - Object containing accountId and optional options for useQuery.
 * @returns Query result containing account data.
 */
const useGetAccount = (payload: {
  accountId: ComputedRef<number>
  options?: UseQueryOptions<T.getAccountResponse>
}) =>
  useQuery({
    queryKey: ['getAccount', payload.accountId],
    queryFn: getAccount,
    ...payload.options
  })

/**
 * @description Create account - Call | Creates a new account with the given payload.
 * @param payload - The request payload for creating a new account.
 * @returns A promise that resolves to the response of the create account request.
 */
export const createAccount = async (
  payload: T.createAccountRequest
): Promise<T.createAccountResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_ROUTE}/accounts`)
    .json(payload)
    .post()
    .json<T.createAccountResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * @description Create account - Query | A custom hook that returns a function to create a new account using the `useMutation` hook from `react-query`.
 * @param payload An object containing optional `UseMutationOptions` to configure the mutation.
 * @returns A function to create a new account mutation.
 */
const useCreateAccount = (payload: {
  options?: UseMutationOptions<
    T.createAccountResponse,
    unknown,
    T.createAccountRequest,
    Error
  >
}) =>
  useMutation({
    mutationFn: createAccount,
    ...payload.options
  })

/**
 * @description searchEmails - Call | Searches for emails based on the given search string.
 * @param payload An object containing the search string.
 * @returns A promise that resolves to the search results.
 */
export const searchEmails = async (payload: {
  search: string
}): Promise<T.searchEmailsResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_ROUTE}/accounts/search-emails`)
    .json(payload)
    .post()
    .json<T.searchEmailsResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * @description searchEmails - Query | A custom hook that searches for emails using the `searchEmails` function and returns the result of the mutation.
 * @param payload An object containing optional `options` to configure the mutation.
 * @returns The result of the mutation.
 */
const useSearchEmails = (payload: {
  options?: UseMutationOptions<
    T.searchEmailsResponse,
    unknown,
    T.searchEmailsRequest,
    Error
  >
}) =>
  useMutation({
    mutationFn: searchEmails,
    ...payload.options
  })

/**
 * @description getTransactions - Call | Retrieves transactions from the API based on the provided pagination parameters.
 * @param pagination - The pagination parameters to use for the API request.
 * @returns A promise that resolves to the response data from the API.
 */
export const getTransactions = async (
  context: QueryFunctionContext
): Promise<T.getTransactionsResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_ROUTE}/transactions`)
    .query(
      qs.stringify(context.queryKey[1], { encode: false, skipNulls: true })
    )
    .get()
    .json<T.getTransactionsResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * @description getTransactions - Query | Returns a query result for getting transactions.
 * @param payload An object containing pagination and options.
 * @returns A query result for getting transactions.
 */
const useGetTransactions = (payload: {
  pagination: ComputedRef<T.PaginationType>
  options?: UseQueryOptions<T.getTransactionsResponse>
}) =>
  useQuery({
    queryKey: ['getTransactions', payload.pagination],
    queryFn: getTransactions,
    ...payload.options
  })

/**
 * @description Export Transactions - Call | Exports transactions to a file and downloads it.
 * @param payload - The request payload.
 * @returns A promise that resolves to the response object.
 */
export const exportTransactions = async (
  payload: T.exportTransactionsRequest
): Promise<T.exportTransactionsResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_ROUTE}/transactions/export`)
    .json(payload)
    .post()
    .json<T.exportTransactionsResponse>()
    .then(({ contents, file_name, file_type }) => {
      const byteCharacters = atob(contents)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], {
        type: file_type
      })

      // Download Excel
      exportFile(file_name, blob)
    })
    .catch((err) => err)

/**
 * @description Export Transactions - Mutation | A custom hook that returns a function to export transactions.
 * @param payload - An object containing optional parameters for the mutation.
 * @param payload.options - Options to configure the mutation behavior.
 * @returns A function to export transactions.
 */
const useExportTransactions = (payload: {
  options?: UseMutationOptions<
    T.exportTransactionsResponse,
    unknown,
    T.exportTransactionsRequest,
    Error
  >
}) =>
  useMutation({
    mutationFn: exportTransactions,
    ...payload.options
  })

/**
 * getWithdrawals - Call | Retrieves a list of withdrawals based on the provided pagination parameters.
 * @param pagination - The pagination parameters to use for retrieving the withdrawals.
 * @returns A promise that resolves to the response containing the list of withdrawals.
 */
export const getWithdrawals = async (
  context: QueryFunctionContext
): Promise<T.getWithdrawalsResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_ROUTE}/withdrawals`)
    .query(
      qs.stringify(context.queryKey[1], { encode: false, skipNulls: true })
    )
    .get()
    .json<T.getWithdrawalsResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * getWithdrawals - Query | Returns a query result for getting withdrawals.
 * @param payload - An object containing pagination and options.
 * @param payload.pagination - A ComputedRef of type PaginationType.
 * @param payload.options - An optional object of type UseQueryOptions<getWithdrawalsResponse>.
 * @returns A query result of type getWithdrawalsResponse.
 */
const useGetWithdrawals = (payload: {
  pagination: ComputedRef<T.PaginationType>
  options?: UseQueryOptions<T.getWithdrawalsResponse>
}) =>
  useQuery({
    queryKey: ['getWithdrawals', payload.pagination],
    queryFn: getWithdrawals,
    ...payload.options
  })

/**
 * payAccount - Call | Sends a request to record a payment for an affiliate account.
 * @param payload - The payment request payload.
 * @returns A promise that resolves to the payment response.
 */
export const payAccount = async (
  payload: T.payAccountRequest
): Promise<T.payAccountResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_ROUTE}/withdrawals/record-payment`)
    .json(payload)
    .post()
    .json<T.payAccountResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * payAccount - Mutation | A custom hook that returns a function to pay an account.
 * @param payload - An object containing optional mutation options.
 * @returns A function to pay an account.
 */
const usePayAccount = (payload: {
  options?: UseMutationOptions<
    T.payAccountResponse,
    unknown,
    T.payAccountRequest,
    Error
  >
}) =>
  useMutation({
    mutationFn: payAccount,
    ...payload.options
  })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetPayments,
  useGetAccounts,
  useGetAccount,
  useGetTransactions,
  useGetWithdrawals
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  useCreateAccount,
  useSearchEmails,
  useExportTransactions,
  usePayAccount
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const salesAffiliateApiFactory = {
  queries,
  mutations
}
