import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Content - Landing Pages page.
 */
const contentLandingPagesMeta: RouteMeta = {
  name: 'content.landing-pages',
  title: 'Landing Pages',
  path: '/content/landing-pages',
  description: {
    name: 'description',
    content: 'Content - Landing Pages'
  },
  showOnNav: true,
  order: 0,
  show: true,
  requiresAuth: true,
  permissions: ['content landing pages | view']
}

export default contentLandingPagesMeta
