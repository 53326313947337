import { type region } from '~/factories/api/azure.api.types'

import * as DT from './document-generator.types'

export async function generateBase64Document(
  region: region,
  documentId: string,
  documentType: DT.GeneratorDocumentType,
  language: DT.DocumentLanguage = DT.DocumentLanguage.english,
  query?: DT.Base64DocumentGeneratorQuery
): Promise<string | null> {
  return await useWretch('FrAPI')
    .query(query ?? {})
    .post(
      JSON.stringify({
        documentId: documentId,
        documentType: documentType,
        language: language
      }),
      `/document/DocumentGeneratorV2/generate/${region}`
    )
    .text((text) => text)
    .catch((err) => {
      console.error('Error in generateBase64Document:', err)
      return null
    })
}

export async function indexBase64Document(
  region: region,
  documentId: string,
  documentType: DT.GeneratorDocumentType,
  language: DT.DocumentLanguage = DT.DocumentLanguage.english
): Promise<boolean | null> {
  return await useWretch('FrAPI')
    .query({ indexOnly: true })
    .post(
      JSON.stringify({
        documentId: documentId,
        documentType: documentType,
        language: language
      }),
      `/document/DocumentGeneratorV2/generate/${region}`
    )
    .res()
    .then((res) => {
      return res.status === 200 || res.status === 202 || res.status === 204
        ? true
        : false
    })
    .catch((err) => {
      console.error('Error in indexBase64Document:', err)
      return null
    })
}

export async function generateBlobDocument(
  region: region,
  documentId: string,
  documentType: DT.GeneratorDocumentType,
  language: DT.DocumentLanguage,
  query?: DT.BlobDocumentGeneratorQuery
): Promise<Blob | null> {
  return await useWretch('FrAPI')
    .query(query ?? {})
    .post(
      JSON.stringify({
        documentId: documentId,
        documentType: documentType,
        language: language
      }),
      `/document/DocumentGeneratorV2/generateFile/${region}`
    )
    .blob()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in generateBlobDocument:', err)
      return null
    })
}

export async function sendDocumentAsEmail(
  region: region,
  documentId: string,
  documentType: DT.GeneratorDocumentType,
  language: DT.DocumentLanguage,
  toEmail: string,
  subject: string,
  body: string,
  fromEmail?: string | null,
  ccEmail?: string | null,
  saveACopy?: boolean | null
): Promise<boolean | null> {
  return await useWretch('FrAPI')
    .post(
      JSON.stringify({
        documentId: documentId,
        documentType: documentType,
        language: language,
        fromEmail: fromEmail ?? '',
        toEmail: toEmail,
        ccEmail: ccEmail ?? '',
        subject: subject,
        body: body,
        saveACopy: saveACopy ?? false
      }),
      `/document/DocumentGeneratorV2/email/${region}`
    )
    .res(() => {
      //no content return true to indicate success
      return true
    })
    .catch((err) => {
      console.error('Error in sendDocumentAsEmail:', err)
      return null
    })
}
