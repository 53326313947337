import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Factory - Assign Barcode page
 */
const factoryAssignBarcodeMeta: RouteMeta = {
  name: 'factory.assign-barcode',
  path: '/factory/assign-barcode',
  title: 'Assign Barcode',
  description: {
    name: 'description',
    content: 'Factory - Assign Barcode'
  },
  showOnNav: true,
  order: 0,
  requiresAuth: true,
  show: true,
  permissions: ['factory assign barcode | view']
}

export default factoryAssignBarcodeMeta
