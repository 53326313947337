/**
 * ================================================
 * Sales Orders API
 * This factory contains all the methods for interacting with the sales orders feature.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import { type UseQueryOptions, useQuery } from '@tanstack/vue-query'
import qs from 'qs'
import { type WritableComputedRef } from 'vue'
import { exportFile } from 'quasar'

/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'

/**
 * ================================================
 * API Routes
 * ================================================
 */
const API_PREFIX = '/api/v2/sales'

/**
 * GetOrders - Call
 *
 * Retrieves orders based on the provided payload.
 * @param payload - The request payload.
 * @returns A promise that resolves to the response containing the orders.
 */
export const getOrders = async (
  payload: T.getOrdersRequest
): Promise<T.getOrdersResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_PREFIX}/orders?${payload.regions}`)
    .query(
      qs.stringify(
        {
          ...payload.pagination
        },
        { encode: false, skipNulls: true }
      )
    )
    .get()
    .json<T.getOrdersResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * GetOrders - Query
 *
 * Custom hook for fetching orders.
 * @param payload - The payload object containing the required parameters.
 * @param payload.regions - The reactive reference to the regions.
 * @param payload.pagination - The reactive reference to the pagination settings.
 * @param payload.options - Optional query options.
 * @returns The result of the useQuery hook.
 */
const useGetOrders = (payload: {
  regions: WritableComputedRef<string>
  pagination: WritableComputedRef<{
    page: number
    rowsPerPage: number
    rowsNumber: number
    sortBy: string
    descending: boolean
    filter: string | null
    companyId: number | null
    customerId: number | null
  }>
  options?: UseQueryOptions<T.getOrdersResponse, Error>
}) =>
  useQuery({
    queryKey: ['orders', payload.pagination, payload.regions],
    queryFn: () =>
      getOrders({
        pagination: payload.pagination.value,
        regions: payload.regions.value
      }),
    ...payload.options
  })

/**
 * GetOrder - Call
 *
 * Retrieves an order from the server.
 * @param payload - The request payload containing the entity ID of the order.
 * @returns A promise that resolves to the order response.
 */
export const getOrder = async (
  payload: T.getOrderRequest
): Promise<T.getOrderResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_PREFIX}/orders/${payload.entity_id}`)
    .get()
    .json<T.getOrderResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * GetOrder - Query
 *
 * Retrieves an order using the provided entity ID.
 * @param payload - The payload object containing the entity ID and optional query options.
 * @returns A promise that resolves to the result of the query.
 */
const useGetOrder = (payload: {
  entity_id: ComputedRef<number>
  options?: UseQueryOptions<T.getOrderResponse>
}) =>
  useQuery({
    queryKey: ['order', payload.entity_id],
    queryFn: () =>
      getOrder({
        entity_id: payload.entity_id.value
      }),
    ...payload.options
  })

/**
 * ExportOrders - Call
 *
 * Exports orders based on the provided payload.
 * @param payload - The request payload containing the necessary information for exporting orders.
 * @returns A promise that resolves to the response containing the exported orders.
 */
export const exportOrders = async (
  payload: T.exportOrdersRequest
): Promise<T.exportOrdersResponse> => {
  const orders = payload.orders.map(({ entity_id }) => `orders[]=${entity_id}`)

  return await useWretch('FsyncAPI')
    .url(`${API_PREFIX}/orders?${payload.regions}`)
    .query(
      qs.stringify(
        {
          ...payload.pagination,
          ...orders,
          export: 1
        },
        { encode: false, skipNulls: true }
      )
    )
    .get()
    .json<T.exportOrdersResponse>()
    .then(({ contents, fileName }) =>
      exportFile(
        fileName,
        blobHelper(contents, fileName, 'application/vnd.ms-excel')
      )
    )
    .catch((err) => err)
}

/**
 * useExportOrders - Query
 *
 * Custom hook for exporting orders.
 * @param payload - The payload object containing the necessary parameters.
 * @returns The result of the useQuery hook.
 */
const useExportOrders = (payload: {
  regions: string
  pagination: WritableComputedRef<{
    page: number
    rowsPerPage: number
    rowsNumber: number
    sortBy: string
    descending: boolean
    filter: string | null
    companyId: number | null
    customerId: number | null
  }>
  orders: Ref<T.ordersType[]>
  options?: UseQueryOptions<T.exportOrdersResponse, Error>
}) =>
  useQuery({
    queryKey: [
      'exportOrders',
      payload.pagination.value.page,
      payload.regions,
      payload.pagination.value?.customerId || '',
      payload.pagination.value?.companyId || ''
    ],
    queryFn: () =>
      exportOrders({
        pagination: payload.pagination.value,
        regions: payload.regions,
        orders: payload.orders.value
      }),
    ...payload.options
  })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetOrder,
  useGetOrders,
  useExportOrders
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const salesOrdersApiFactory = {
  queries,
  mutations
}
