/**
 * ================================================
 * Warehouse -> Labels -> Component Labels -> API
 * This factory contains all the methods for interacting with the warehouse component labels API.
 * ================================================
 */

/**
 * ================================================
 * NOTES: 👍
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import {
  type QueryFunctionContext,
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery
} from '@tanstack/vue-query'

/**
 * ================================================
 * Custom packages
 * ================================================
 */
import type * as T from './types'

/**
 * ================================================
 * API Routes
 * ================================================
 */
// const API_PREFIX = '/api/v2/warehouse'
// const API_PREFIX_V1 = '/api/v1/location'

/**
 * Get product info - Call
 */
const getProductInfo = async (
  context: QueryFunctionContext
): Promise<T.warehouseGetProductInfoResponse> =>
  await useWretch('FrAPI')
    .url(
      `/product/Product/detail/${context.queryKey[1]}/${context.queryKey[2]}`
    )
    .query({
      RetailOnly: true
    })
    .get()
    .json<T.warehouseGetProductInfoResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Get Product Info - Query
 */
const useGetProductInfo = (payload: {
  region: ComputedRef<string>
  sku: ComputedRef<string>
  options?: UseQueryOptions<T.warehouseGetProductInfoResponse>
}) =>
  useQuery({
    queryKey: ['WarehouseLocations', payload.region, payload.sku],
    queryFn: getProductInfo,
    ...payload.options
  })

/**
 * create component label - Call
 */
const printComponentLabel = async (
  payload: T.warehouseCreateComponentLabelRequest
): Promise<T.warehouseCreateComponentLabelResponse> =>
  await useWretch('FrAPI')
    .url('/warehouse/Labels/componentLabel')
    .query({
      pageSize: payload?.paperSize,
      wrapDataUri: true,
      convertToImage: false
    })
    .json({
      store_id: payload.store_id,
      sku: payload.sku,
      description: payload.description,
      orderNo: payload.orderNo,
      sizeAndWeight: payload.sizeAndWeight,
      barcode: payload.barcode,
      qty: payload.qty
    })
    .post()
    .text()
    .then((res) => res)
    .catch((err) => err)

/**
 * create component label - Mutation
 */
const useCreateComponentLabel = (
  options?: UseMutationOptions<
    T.warehouseCreateComponentLabelResponse,
    unknown,
    T.warehouseCreateComponentLabelRequest,
    Error
  >
) =>
  useMutation({
    mutationFn: printComponentLabel,
    ...options
  })

/**
 * createStoreCollectionLabel - Call
 */
const createStoreCollectionLabel = async (
  payload: T.warehouseCreateStoreCollectionLabelRequest
): Promise<T.warehouseCreateStoreCollectionLabelResponse> =>
  await useWretch('FrAPI')
    .url('/warehouse/labels/storeCollectionLabel')
    .query({
      pageSize: payload?.pageSize,
      wrapDataUri: true
    })
    .json(payload)
    .post()
    .text()
    .then((res) => res)
    .catch((err) => err)

/**
 * createStoreCollectionLabel - Mutation
 */
const useCreateStoreCollectionLabel = (
  options?: UseMutationOptions<
    T.warehouseCreateStoreCollectionLabelResponse,
    unknown,
    T.warehouseCreateStoreCollectionLabelRequest,
    Error
  >
) =>
  useMutation({
    mutationFn: createStoreCollectionLabel,
    ...options
  })

/**
 * Get stores - Call
 */
const getStores = async (): Promise<T.warehouseGetStoresResponse> =>
  await useWretch('FsyncAPI')
    .url('/api/v2/stores')
    .get()
    .json<T.warehouseGetStoresResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Get stores - Query
 */
const useGetStores = (
  options?: UseQueryOptions<T.warehouseGetStoresResponse>
) =>
  useQuery({
    queryKey: ['WarehouseStores'],
    queryFn: getStores,
    ...options
  })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetProductInfo,
  useGetStores
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  useCreateComponentLabel,
  useCreateStoreCollectionLabel
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const warehouseComponentLabelsAPIFactory = {
  queries,
  mutations
}
