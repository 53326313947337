import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Warehouse Shipping Dashboard page.
 */
const warehouseShippingDashboardPageMeta: RouteMeta = {
  name: 'warehouse.shipping-dashboard',
  path: '/warehouse/Shipping-dashboard',
  title: 'Shipping Dashboard',
  description: {
    name: 'description',
    content: 'Warehouse - Shipping Dashboard'
  },
  showOnNav: true,
  keepalive: false,
  order: 2,
  requiresAuth: true,
  show: true,
  permissions: ['warehouse shipping dashboard | view']
}

export default warehouseShippingDashboardPageMeta
