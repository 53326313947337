/**
 * This is the countries factory, it contains all the api calls related to countries Feature on Campfire.
 */
import { type UseQueryOptions, useQuery } from '@tanstack/vue-query'
import { useOnline } from '@vueuse/core'
import type * as T from './types'
import { useCountryStore } from '~/features/country/store'
/**
 * API Routes
 */
const API_PREFIX = '/api/v2/countries'

/**
 * get Countries
 */
const useGetCountries = (
  options?: UseQueryOptions<T.getCountriesResponse[]>
) => {
  const countryStore = useCountryStore()

  const countries = computed(() => countryStore.countries)

  return useQuery({
    queryKey: ['countries'],
    queryFn: () =>
      useWretch('FsyncAPI')
        .url(API_PREFIX)
        .get()
        .json<T.getCountriesResponse>()
        .then((res) => res)
        .catch((err) => err),
    enabled: !!useTokenSwitch('fsync') && useOnline(),
    refetchOnWindowFocus: false,
    initialData: countries.value,
    ...(options || {})
  })
}

export const getCountries = async () => {
  const countryStore = useCountryStore()

  return await useWretch('FsyncAPI')
    .url(API_PREFIX)
    .get()
    .json<T.getCountriesResponse>()
    .then((res) => {
      countryStore.countries = res
    })
    .catch((err) => err)
}

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetCountries
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const countriesApiFactory = {
  queries,
  mutations
}
