import type * as VT from './vehicles.types'

export async function getVehicleYears(): Promise<VT.GetVehicleYearsResponse | null> {
  return await useWretch('FsyncAPI')
    .get('/api/v1/vehicle/years')
    .json<VT.GetVehicleYearsResponse>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getVehicleYears:', err)
      return null
    })
}

export async function getVehicleMakes(
  yearId: number
): Promise<VT.GetVehicleMakesResponse | null> {
  return await useWretch('FsyncAPI')
    .get(`/api/v1/vehicle/years/${yearId}/makes`)
    .json<VT.GetVehicleMakesResponse>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getVehicleYears:', err)
      return null
    })
}

export async function getVehicleModels(
  yearId: number,
  makeId: number
): Promise<VT.GetVehicleModelsResponse | null> {
  return await useWretch('FsyncAPI')
    .get(`/api/v1/vehicle/years/${yearId}/makes/${makeId}/models`)
    .json<VT.GetVehicleModelsResponse>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getVehicleYears:', err)
      return null
    })
}

export async function getVehicleOptions(
  yearId: number,
  makeId: number,
  modelId: number
): Promise<VT.GetVehicleOptionsResponse | null> {
  return await useWretch('FsyncAPI')
    .get(
      `/api/v1/vehicle/years/${yearId}/makes/${makeId}/models/${modelId}/options`
    )
    .json<VT.GetVehicleOptionsResponse>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getVehicleOptions:', err)
      return null
    })
}

export async function getVehicleProducts(
  yearId: number,
  makeId: number,
  modelId: number,
  optionId: number
): Promise<VT.GetVehicleDataResponse | null> {
  return await useWretch('FsyncAPI')
    .get(
      `/api/v1/vehicle/years/${yearId}/makes/${makeId}/models/${modelId}/options/${optionId}/products`
    )
    .json<VT.GetVehicleDataResponse>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getVehicleOptions:', err)
      return null
    })
}
