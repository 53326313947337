import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Warehouse - Product Labels page.
 */
const warehouseLabelsProductLabelsMeta: RouteMeta = {
  name: 'warehouse.labels.product-labels',
  title: 'Product Labels',
  path: '/warehouse/labels/product-labels',
  description: {
    name: 'description',
    content: 'Warehouse - Product Labels'
  },
  showOnNav: true,
  order: 5,
  requiresAuth: true,
  show: true,
  permissions: []
}

export default warehouseLabelsProductLabelsMeta
