import type { RouteMeta } from 'vue-router'

const catalogDetailsPageMeta: RouteMeta = {
  name: 'catalog.product-info',
  title: 'Product Info',
  path: '/catalog/product-info',
  description: {
    name: 'description',
    content: 'Catalog - Product Info'
  },
  showOnNav: true,
  order: 3,
  show: true,
  requiresAuth: true,
  isBeta: true,
  permissions: ['catalog product info | view']
}

export default catalogDetailsPageMeta
