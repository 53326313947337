/**
 * ================================================
 * Catalog product-comparison API
 * This factory contains all the methods for interacting with the catalog product-comparison feature.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import {
  type QueryFunctionContext,
  type UseQueryOptions,
  useQuery
} from '@tanstack/vue-query'
/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'

/**
 * API Routes
 */
const API_PREFIX = '/api/v2/catalog'
/**
 * compareProducts - Call
 */
export const compareProducts = async (
  context: QueryFunctionContext
): Promise<T.compareProductsResponse> => {
  const [a, b, c] = context
    .queryKey[1] as T.compareProductsRequest['compareList']
  let products: string[] | string = []
  if (c) {
    products.push(c.sku)
  }
  if (b) {
    products.push(b.sku)
  }
  if (a) {
    products.push(a.sku)
  }

  products = products.map((a: string) => `skus[]=${a}`).join('&')
  return await useWretch('FsyncAPI')
    .get(`${API_PREFIX}/product-comparison?${products}`)
    .json<T.compareProductsResponse>()
    .then((products) => products)
    .catch((err) => err)
}

/**
 * compareProducts - Query
 */
const useCompareProducts = (payload: {
  compareList: ComputedRef<T.compareProductsRequest['compareList']>
  options?: UseQueryOptions<T.compareProductsResponse>
}) =>
  useQuery({
    queryKey: ['compareProducts', payload.compareList],
    queryFn: compareProducts,
    ...payload.options
  })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useCompareProducts
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const catalogProductComparisonApiFactory = {
  queries,
  mutations
}
