import { Notify } from 'quasar'

export enum Position {
  topLeft = 'top-left',
  top = 'top',
  topRight = 'top-right',
  left = 'left',
  center = 'center',
  right = 'right',
  bottomLeft = 'bottom-left',
  bottom = 'bottom',
  bottomRight = 'bottom-right'
}

export function notifyMessage(
  message: string,
  color: string,
  icon?: string,
  timeout = 12000,
  caption?: string,
  position = Position.bottomRight
) {
  Notify.create({
    message: message,
    caption: caption,
    position: position,
    color: color,
    icon: icon,
    timeout
  })
}

export function notifier({
  message,
  type,
  timeout = 12000,
  caption,
  position = Position.bottomRight
}: {
  message: string
  type: 'warning' | 'error' | 'info' | 'success'
  timeout?: number
  caption?: string
  position?: Position
}) {
  switch (type) {
    case 'warning':
      notifyMessage(
        message,
        'orange-8',
        'fad fa-exclamation',
        timeout,
        caption,
        position
      )
      break
    case 'error':
      notifyMessage(message, 'red', 'cancel', timeout, caption, position)
      break
    case 'info':
      notifyMessage(message, 'blue', 'fad fa-info', timeout, caption, position)
      break
    case 'success':
      notifyMessage(
        message,
        'green',
        'fad fa-check',
        timeout,
        caption,
        position
      )
      break
    default:
      notifyMessage(
        message,
        'blue',
        'fad fa-info',
        timeout,
        caption,
        position
      )
  }
}

//Specific notifiers
export enum NotificationType {
  information = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error'
}

export function notifyInformation(
  message: string,
  caption?: string,
  timeout = 12000,
  position = Position.bottomRight
) {
  notifier({
    message: message,
    type: NotificationType.information,
    timeout: timeout,
    caption: caption,
    position: position
  })
}

export function notifySuccess(
  message: string,
  caption?: string,
  timeout = 12000,
  position = Position.bottomRight
) {
  notifier({
    message: message,
    type: NotificationType.success,
    timeout: timeout,
    caption: caption,
    position: position
  })
}

export function notifyWarning(
  message: string,
  caption?: string,
  timeout = 12000,
  position = Position.bottomRight
) {
  notifier({
    message: message,
    type: NotificationType.warning,
    timeout: timeout,
    caption: caption,
    position: position
  })
}

export function notifyError(
  message: string,
  caption?: string,
  timeout = 12000,
  position = Position.bottomRight
) {
  notifier({
    message: message,
    type: NotificationType.error,
    timeout: timeout,
    caption: caption,
    position: position
  })
}
