import type * as T from '../../api/types'

export const initialState_subscribers: initialStateType_subscribers = {
  entries: [],
  makes: [],
  models: [],
  vehicle: {
    makeOptions: [],
    modelOptions: []
  },
  session: {
    content: {
      header: '',
      subheader: '',
      textarea: '',
      textarea_header: ''
    },
    store_id: null,
    store_name: null,
    event: null,
    label: null,
    start_date: null,
    end_date: null,
    user_id: null,
    value: null
  },
  winner: null,
  hideEmail: true,
  us_languages: [
    {
      label: 'English',
      value: 5
    },
    {
      label: 'Spanish',
      value: 16
    }
  ],
  de_languages: [
    {
      label: 'English (EU)',
      value: 9
    },
    {
      label: 'English (UK)',
      value: 17
    },
    {
      label: 'German',
      value: 8
    },
    {
      label: 'French',
      value: 12
    },
    {
      label: 'Spanish',
      value: 14
    },
    {
      label: 'Dutch',
      value: 15
    }
  ]
}

export type initialStateType_subscribers = {
  entries: T.entriesType[]
  makes: T.makeOptions[]
  models: T.modelOptions[]
  vehicle: {
    makeOptions: T.makeOptions[]
    modelOptions: T.modelOptions[]
  }
  session: T.sessionType
  winner: T.entriesType | null
  hideEmail: boolean
  us_languages: {
    label: string
    value: number
  }[]
  de_languages: {
    label: string
    value: number
  }[]
}
