import { httpErrorHelperOnOk } from '~/factories/api/azure.api.error.helper'
import type * as T from '~/factories/api/azure.api.types'
import type * as CT from './cash.types'

export async function doCashUp(
  region: T.region,
  query: CT.DoCashUpQuery,
  cashUpRequest: CT.CashUpRequest
): Promise<string | null> {
  return await useWretch('FrAPI')
    .url(`/order/api/Cash/${region}/CashUp`)
    .query(query)
    .json(cashUpRequest)
    .post()
    .text((text) => text)
    .catch((err) => {
      console.error('Error in doCashUp:', err)
      return null
    })
}

export async function getCashUpReports(
  region: T.region
): Promise<CT.CashUpReport[] | null> {
  return await useWretch('FrAPI')
    .get(`/order/api/Cash/${region}/CashUp`)
    .json<CT.CashUpReport[]>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getCashUpReports:', err)
      return null
    })
}

export async function reprintCashUp(
  region: T.region,
  query: CT.ReprintCashUpQuery
): Promise<string | null> {
  return await useWretch('FrAPI')
    .query(query)
    .get(`/order/api/Cash/${region}/CashUp/Reprint`)
    .text((text) => text)
    .catch((err) => {
      console.error('Error in reprintCashUp:', err)
      return null
    })
}

export async function transferCash(
  region: T.region,
  transferCashRequest: CT.TransferCashRequest
): Promise<CT.TransferCashResponse | null> {
  return await useWretch('frSysproAPI')
    .post(
      JSON.stringify(transferCashRequest),
      `/order/api/Cash/${region}/XferCash`
    )
    .json<CT.TransferCashResponse>()
    .then((res) => {
      httpErrorHelperOnOk(res)
      return res
    })
    .catch((err) => {
      console.error('Error in transferCash:', err)
      return null
    })
}

export async function getCashTransfers(
  region: T.region,
  query: CT.CashTransfersQuery
): Promise<CT.CashTransfer[] | null> {
  return await useWretch('FrAPI')
    .query(query)
    .get(`/order/api/Cash/${region}/XferCash`)
    .json<CT.CashTransfer[]>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getCashTransfers:', err)
      return null
    })
}

export async function reprintCashTransfer(
  region: T.region,
  query: CT.ReprintCashTransferQuery
): Promise<string | null> {
  return await useWretch('FrAPI')
    .query(query)
    .get(`/order/api/Cash/${region}/XferCash/Reprint`)
    .text((text) => text)
    .catch((err) => {
      console.error('Error in reprintCashTransfer:', err)
      return null
    })
}
