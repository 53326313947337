import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the sales - Invoices page.
 */
const salesInvoicesMeta: RouteMeta = {
  name: 'sales.invoices',
  title: 'Invoices',
  path: '/sales/invoices',
  order: 4,
  show: true,
  requiresAuth: true,
  permissions: ['sales invoices | view']
}

export default salesInvoicesMeta
