export const themeColours: themeColourType[] = [
  {
    label: 'Light',
    type: 'light',
    icon: 'fa fa-sun'
  },
  {
    label: 'Dark',
    type: 'dark',
    icon: 'fa fa-moon'
  }
]

export const themeStyles: themeStyleType[] = [
  {
    label: 'Neo',
    type: 'neo',
    icon: 'fal fa-robot'
  },
  {
    label: 'Chowey',
    type: 'chowey',
    icon: 'fal fa-paw'
  }
]

export type themeColourType = {
  type: 'light' | 'dark'
  label: string
  icon: string
}

export type themeStyleType = {
  type: 'neo' | 'chowey'
  label: string
  icon: string
}
