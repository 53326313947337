import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Warehouse - Bulk Bin Labels page.
 */
const warehouseLabelsBulkBinLabelsMeta: RouteMeta = {
  name: 'warehouse.labels.bulk-bin-labels',
  title: 'Bulk Bin Labels',
  path: '/warehouse/labels/bulk-bin-labels',
  order: 1,
  requiresAuth: true,
  show: true,
  permissions: [],
  showOnNav: true,
  description: {
    name: 'description',
    content: 'Warehouse - Bulk Bin Labels'
  },
}

export default warehouseLabelsBulkBinLabelsMeta
