import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Warehouse Shipping Details page.
 */
const shippingDetailsPageMeta: RouteMeta = {
  name: 'warehouse.shipping-details',
  path: '/warehouse/shipping-details',
  title: 'Shipping Details',
  description: {
    name: 'description',
    content: 'Warehouse - Shipping Details'
  },
  showOnNav: true,
  keepalive: false,
  order: 2,
  requiresAuth: true,
  show: true,
  permissions: ['warehouse shipping details | view']
}

export default shippingDetailsPageMeta
