import type * as AT from './auth.types'

export async function loginUser(
  payload: AT.loginRequest
): Promise<AT.loginResponse | AT.Error> {
  return await useWretch('permissionsAPI')
    .url('/auth/login')
    .json(payload)
    .post()
    .unauthorized((err) => {
      const errorMessage = JSON.parse(err.message).message
      throw new Error(errorMessage || 'Login failed')
    })
    .json<AT.loginResponse>()
    .then(async (res) => {
      if (!res) {
        throw new Error('Login failed')
      }

      return res
    })
}
