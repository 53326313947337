import lang from "quasar/lang/en-US.js"
import iconSet from "quasar/icon-set/material-icons.js"
import { Notify,Dialog,Loading,LoadingBar } from "quasar"


export const componentsWithDefaults = {  }

export const appConfigKey = "nuxtQuasar"

export const quasarNuxtConfig = {
  lang,
  iconSet,
  components: {"defaults":{}},
  plugins: {Notify,Dialog,Loading,LoadingBar},
  config: {"brand":{"primary":"#dd4b39","secondary":"#26a69a","accent":"#eebf00","positive":"#21ba45","negative":"#c10015","info":"#31ccec","warning":"#f2c037","emerald":"#10b981"}}
}