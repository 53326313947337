import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the login page.
 * @auth Does not require authentication
 */
const authLoginPageMeta: RouteMeta = {
  name: 'auth.login',
  title: 'Login',
  path: '/auth/login',
  description: {
    name: 'description',
    content: 'Login to your account.'
  },
  order: 0,
  requiresAuth: false,
  show: true,
  permissions: []
}

export default authLoginPageMeta
