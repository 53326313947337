import type { RouteMeta } from 'vue-router'
import { useSysproStore } from '~/features/syspro/store/syspro-store'

/**
 * Represents the metadata for the Warehouse - Movements - Goods in Transit page
 */
const warehouseMovementsGoodsInTransitMeta: RouteMeta = {
  name: 'warehouse.movements.goods-in-transit',
  title: 'Goods in Transit',
  path: '/warehouse/movements/goods-in-transit',
  order: 1,
  requiresAuth: true,
  show: true,
  permissions: ['warehouse movements goods in transit | view'],
  beforeEnter: () => {
    useSysproStore()
  },
  showOnNav: true,
  description: {
    name: 'description',
    content: 'Warehouse - Movements - Goods in Transit'
  }
}

export default warehouseMovementsGoodsInTransitMeta
