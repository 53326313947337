import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the sales - shipments page.
 */
const salesShipmentsMeta: RouteMeta = {
  name: 'sales.shipments',
  title: 'Shipments',
  path: '/sales/shipments',
  order: 6,
  show: true,
  requiresAuth: true,
  permissions: ['sales shipments | view']
}

export default salesShipmentsMeta
