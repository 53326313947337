import type { RouteMeta } from 'vue-router'

const frediPageMeta: RouteMeta = {
  name: 'fredi',
  title: 'FREDI',
  path: '/fredi',
  icon: 'fad fa-microchip',
  order: 10,
  show: true,
  requiresAuth: true,
  keepalive: false,
  permissions: ['fredi | view']
}

export default frediPageMeta
