import qs from 'qs'

import type * as T from './warehouse.types'
import {
  type QueryFunctionContext,
  type UseQueryOptions,
  useQuery
} from '@tanstack/vue-query'

/**
 * This is the warehouse factory, it contains all the api calls related to warehouse Feature on Campfire.
 */

// API Routes
// const API_PREFIX = '/api/v2/warehouse'
// const API_PREFIX_V1 = '/api/v1/location'

/**
 * ====================
 * Movements
 * ====================
 */

/**
 * Goods in Transit - call
 */

export const getGoodsInTransit =
  async (): Promise<T.warehouseGetGoodsInTransitResponse> =>
    await useWretch('frSysproAPI')
      .get('/warehouse/GoodsInTransit')
      .json<T.warehouseGetGoodsInTransitResponse>()
      .then((res) => {
        return res.sort(function (
          a: { gitReference: string },
          b: { gitReference: string }
        ) {
          return a.gitReference
            .toLowerCase()
            .localeCompare(b.gitReference.toLowerCase())
        })
      })
      .catch((err) => err)

/**
 * Goods in Transit - query
 */
const useGoodsInTransit = (
  options?: UseQueryOptions<T.warehouseGetGoodsInTransitResponse>
) =>
  useQuery({
    queryKey: ['getGoodsInTransit'],
    queryFn: getGoodsInTransit,
    ...options
  })

/**
 * getGitTransactionLines - call
 */
export const getGitTransactionLines = async (
  context: QueryFunctionContext
): Promise<T.warehouseGetGitTransactionLinesResponse[]> =>
  await useWretch('frSysproAPI')
    .get(`/warehouse/GoodsInTransit/${context.queryKey[1]}`)
    .json<T.warehouseGetGitTransactionLinesResponse[]>()
    .then((res) => {
      return res
    })
    .catch((err) => err)

/**
 * getGitTransactionLines - query
 */
const useGitTransactionLines = (
  payload: T.warehouseGetGitTransactionLinesRequest,
  options?: UseQueryOptions<T.warehouseGetGitTransactionLinesResponse[]>
) =>
  useQuery({
    queryKey: ['getGitTransactionLines', payload.gitReference],
    queryFn: getGitTransactionLines,
    ...options
  })

/**
 * Get Warehouses - call
 */
export const getWarehouses =
  async (): Promise<T.warehouseGetWarehousesResponse> =>
    await useWretch('frSysproAPI')
      .get('/warehouse/api/Warehouses')
      .json<T.warehouseGetWarehousesResponse>()
      .then((data) => {
        const newWarehouses: any[] = []

        for (const key in data) {
          newWarehouses.push({ id: key, description: data[key] })
        }

        return newWarehouses.sort(function (a, b) {
          return a.id.toLowerCase().localeCompare(b.id.toLowerCase())
        })
      })
      .catch((err) => err)

/**
 * Get Warehouses - query
 */
const useGetWarehouses = (
  options?: UseQueryOptions<T.warehouseGetWarehousesResponse>
) =>
  useQuery({
    queryKey: ['getWarehouses'],
    queryFn: getWarehouses,
    ...options
  })

export const getHistoricalImmediateTransfers = async (
  warehouse: string,
  query: T.historicalImmediateTransfersQuery
): Promise<T.historicalImmediateTransfersResponse[] | null> =>
  await useWretch('frSysproAPI')
    .query(query)
    .get(`/warehouse/api/ImmediateTransfer/${warehouse}`)
    .json<T.historicalImmediateTransfersResponse[]>()
    .then((data) => {
      return data
    })
    .catch((err) => {
      console.error('Error in getHistoricalImmediateTransfers:', err)
      return null
    })

/**
 * Search for places - call
 */
const API_ROUTE_FIND = '/customer/Location/geocode'

export const searchForPlaces = async (
  context: QueryFunctionContext
): Promise<T.warehouseSearchForPlacesResponse> =>
  await useWretch('FrAPI')
    .get(
      `${API_ROUTE_FIND}?${qs.stringify({
        Address: context.queryKey[1],
        provider: 'GoogleMaps'
      })}`
    )
    .json<T.warehouseSearchForPlacesResponse>()
    .then((data) => {
      return {
        ...data,
        results: data.results
      }
    })

/**
 * Search for places - query
 */
const useSearchForPlaces = (payload: {
  query: ComputedRef<string>
  options?: UseQueryOptions<T.warehouseSearchForPlacesResponse>
}) =>
  useQuery({
    queryKey: ['searchForPlaces', payload.query],
    queryFn: searchForPlaces,
    ...payload.options
  })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  /**
   * Goods in Transit
   */
  useGoodsInTransit,
  useGitTransactionLines,
  /**
   * get Warehouses
   */
  useGetWarehouses,

  /**
   * Search for places
   */
  useSearchForPlaces
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {}
