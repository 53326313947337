import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Warehouse - Component Labels page.
 */
const warehouseLabelsComponentLabelsMeta: RouteMeta = {
  name: 'warehouse.labels.component-labels',
  title: 'Component Labels',
  path: '/warehouse/labels/component-labels',
  description: {
    name: 'description',
    content: 'Warehouse - Component Labels'
  },
  showOnNav: true,
  order: 3,
  requiresAuth: true,
  show: true,
  permissions: []
}

export default warehouseLabelsComponentLabelsMeta
