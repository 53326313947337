import { initialState_auth } from './state'

type APIReference = {
  [key: string]: string | { id: string; name: string }[]
  name: 'FSYNC' | 'FRAPIS' | string
  APIs: {
    id: string
    name: string
  }[]
}

export const useAuthStore = defineStore('global', {
  state: () => initialState_auth,

  getters: {
    _selectedCountriesString: (state) => {
      return (word = 'regions') =>
        state.countries
          .filter((country) => country.selected)
          .map((country) => `${word}[]=${country.name.toLowerCase()}`)
          .join('&')
    }
  },

  actions: {
    /**
     * Get the API routes.
     * @returns The API routes.
     */
    async getRoutes() {
      this.routes = await useWretch('permissionsAPI')
        .url('/router/references')
        .get()
        .json<APIReference[]>()
    },

    /**
     * Get the API routes by name.
     * @param name The name of the API.
     * @returns The API routes.
     */
    async getRoutesByName(baseName: string, ApiName: string) {
      await this.getRoutes()

      return (
        this.routes
          .find((route) => route.name === baseName)
          ?.APIs.find((api) => api.name === ApiName) || null
      )
    }
  },
  persist: true,
})

export default useAuthStore
