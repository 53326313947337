import qs from 'qs'
import { setupCache } from 'axios-cache-interceptor'

import httpErrorHelper from '~/factories/api/error.helper'
import axios from 'axios'

const httpInstance = ({ baseURL }: { baseURL: string | undefined }) => {
  const instance = axios.create({
    paramsSerializer: {
      encode: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    },
    baseURL
  })

  // Add a request interceptor that serializes the params
  instance.interceptors.request.use((config) => {
    config.paramsSerializer = {
      indexes: null
    }
    return config
  })

  // Add a response interceptor that handles errors
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      return httpErrorHelper(error)
    }
  )

  return setupCache(instance)
}

// the following is used to set the header for the axios instances, this should be removed once axios is removed
// FIXME: Remove this later.
export async function setHeader() {
  try {
    fsyncAPIInstance.defaults.headers.common.Authorization =
      'Bearer ' + useTokenSwitch()
    frApiInstance.defaults.headers.common.Authorization =
      'Bearer ' + useTokenSwitch('azure')
    frAuthInstance.defaults.headers.common.Authorization =
      'Bearer ' + useTokenSwitch('azure')
  } catch (error) {
    console.error('Error setting headers', error)
  }
}

// Fsync API
const fsyncAPIInstance = httpInstance({ baseURL: process.env.FR_FSYNC_API_URL })
// FR API
const frApiInstance = httpInstance({ baseURL: process.env.FR_API_URL })
// Auth API
const frAuthInstance = httpInstance({ baseURL: process.env.FR_AUTH_URL })

export { fsyncAPIInstance, frApiInstance, frAuthInstance }
