import { useSysproStore } from '~/features/syspro/store/syspro-store'
import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Warehouse - Bin Labels page.
 */
const warehouseLabelsBinLabelsMeta: RouteMeta = {
  name: 'warehouse.labels.bin-labels',
  title: 'Bin Labels',
  path: '/warehouse/labels/bin-labels',
  description: {
    name: 'description',
    content: 'Warehouse - Bin Labels'
  },
  showOnNav: true,
  order: 0,
  requiresAuth: true,
  show: true,
  permissions: [],
  beforeEnter: () => {
    useSysproStore()
  }
}

export default warehouseLabelsBinLabelsMeta
