import { type ObjectValues } from '~/types/enums.type'

export const ThumbnailImageFormat = {
  jpg: 'jpg',
  png: 'png'
} as const

export type ThumbnailImageFormatValues = ObjectValues<
  typeof ThumbnailImageFormat
>
