import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the regions admin page
 */
const regionsAdminPageMeta: RouteMeta = {
  name: 'admin.regions',
  title: 'Regions',
  path: '/admin/regions',
  description: {
    name: 'description',
    content: 'Admin - Regions'
  },
  showOnNav: true,
  order: 3,
  requiresAuth: true,
  show: true,
  icon: 'fas fa-globe-africa',
  permissions: ['admin | view']
}

export default regionsAdminPageMeta
