/**
 * ================================================
 * Sales Giftcards API
 * This factory contains all the methods for interacting with the sales giftcards feature.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import { type UseQueryOptions, useQuery } from '@tanstack/vue-query'
import qs from 'qs'
import { exportFile } from 'quasar'

/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'

/**
 * ================================================
 * API Routes
 * ================================================
 */
const API_PREFIX = '/api/v2/sales/giftcards'

/**
 * Get GiftCard Accounts - Call
 * @param {pagination} pagination
 * @returns {Promise<giftCardAccountsResponse>}
 */
export const getGiftCardAccounts = async (
  pagination: {
    page: number
    sortBy: string
    descending: boolean
    rowsPerPage: number
    filter: string | null
    rowsNumber: number
  },
  regions: string
): Promise<T.giftCardAccountsResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_PREFIX}/accounts?${regions}`)
    .query(
      qs.stringify(
        {
          ...pagination
        },
        { encode: false, skipNulls: true }
      )
    )
    .get()
    .json<T.giftCardAccountsResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Get GiftCard Accounts - Query
 */
const useGetGiftCardAccounts = (payload: {
  pagination: ComputedRef<{
    page: number
    sortBy: string
    descending: boolean
    rowsPerPage: number
    filter: string | null
    rowsNumber: number
  }>
  regions: ComputedRef<string>
  options?: UseQueryOptions<T.giftCardAccountsResponse>
}) =>
  useQuery({
    queryKey: ['GiftCardAccounts', payload.regions, payload.pagination],
    queryFn: () =>
      getGiftCardAccounts(payload.pagination.value, payload.regions.value),
    ...payload.options,
    refetchOnWindowFocus: false
  })

/**
 * Export GiftCard Accounts - Call
 * @param {giftCardExportEntryTypeRequest} params
 * @returns {Promise<giftCardExportEntryTypeResponse>}
 */
export const getExportedGiftCardAccounts = async (payload: {
  params: T.giftCardExportEntryTypeRequest
  regions: string
}): Promise<T.giftCardExportEntryTypeResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_PREFIX}/accounts/export?${payload.regions}`)
    .query(
      qs.stringify(
        {
          ...payload.params
        },
        { encode: false, skipNulls: true }
      )
    )
    .get()
    .json<T.giftCardExportEntryTypeResponse>()
    .then(({ contents, file_name, file_type }) =>
      exportFile(file_name, blobHelper(contents, file_name, file_type))
    )
    .catch((err) => err)

/**
 * Export GiftCard Accounts - Query
 */
const useGetExportedGiftCardAccounts = (payload: {
  params: ComputedRef<T.giftCardExportEntryTypeRequest>
  region: ComputedRef<string>
  options?: UseQueryOptions<T.giftCardExportEntryTypeResponse>
}) =>
  useQuery({
    queryKey: ['GiftCardAccountsExport', payload.region],
    queryFn: () =>
      getExportedGiftCardAccounts({
        params: payload.params.value,
        regions: payload.region.value
      }),
    ...payload.options,
    refetchOnWindowFocus: false
  })

/**
 * Get GiftCard Orders - Call
 * @param {pagination} pagination
 * @returns {Promise<giftCardOrdersResponse>}
 */
export const getGiftCardOrders = async (
  pagination: T.PaginationType,
  regions: string
): Promise<T.giftCardOrdersResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_PREFIX}/orders?${regions}`)
    .query(
      qs.stringify(
        {
          ...pagination
        },
        { encode: false, skipNulls: true }
      )
    )
    .get()
    .json<T.giftCardOrdersResponse>()
    .then((res) => res)
    .catch((err) => err)
/**
 * Get GiftCard Orders - Query
 */
const useGetGiftCardOrders = (payload: {
  pagination: ComputedRef<T.PaginationType>
  regions: ComputedRef<string>
  options?: UseQueryOptions<T.giftCardOrdersResponse>
}) =>
  useQuery({
    queryKey: ['GiftCardOrders', payload.regions, payload.pagination],
    queryFn: () =>
      getGiftCardOrders(payload.pagination.value, payload.regions.value),
    ...payload.options,
    refetchOnWindowFocus: false
  })

/**
 * Export GiftCard orders - Call
 */
export const getExportedGiftCardOrders = async (payload: {
  params: T.giftCardExportEntryTypeRequest
  regions: string
}): Promise<T.giftCardExportEntryTypeResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_PREFIX}/orders/export?${payload.regions}`)
    .query(
      qs.stringify(
        {
          ...payload.params
        },
        { encode: false, skipNulls: true }
      )
    )
    .get()
    .json<T.giftCardExportEntryTypeResponse>()
    .then(({ contents, file_name, file_type }) =>
      exportFile(file_name, blobHelper(contents, file_name, file_type))
    )
    .catch((err) => err)

/**
 * Export GiftCard orders - Query
 */
// const useGetExportedGiftCardOrders = (payload: {
//   options?: UseMutationOptions<
//     T.giftCardExportEntryTypeResponse,
//     Error,
//     T.giftCardExportEntryTypeRequest,
//     unknown
//   >
// }) =>
//   useMutation({
//     mutationFn: (payload: {
//       params: T.giftCardExportEntryTypeRequest
//       regions: string[]
//     }) =>
//       getExportedGiftCardOrders({
//         params: payload.params,
//         regions: payload.regions
//       }),
//     ...payload.options
//   })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetGiftCardAccounts,
  useGetExportedGiftCardAccounts,
  useGetGiftCardOrders
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const salesGiftcardApiFactory = {
  getExportedGiftCardOrders,
  getExportedGiftCardAccounts,
  queries,
  mutations
}
