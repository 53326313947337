/**
 * ================================================
 * Warehouse -> Labels -> QR Labels -> API
 * This factory contains all the methods for interacting with the warehouse qr labels API.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */

import type { UseMutationOptions } from '@tanstack/vue-query'
import { useMutation } from '@tanstack/vue-query'

/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'

/**
 * ================================================
 * API Routes
 * ================================================
 */

/**
 * create qr label - Call
 */
const printQRLabel = async (
  payload: T.warehouseCreateQRLabelRequest
): Promise<T.warehouseCreateQRLabelResponse> =>
  await useWretch('FrAPI')
    .url('/warehouse/Labels/fittingInstructionQRLabelFile')
    .query({
      pageSize: payload.pageSize
    })
    .json({
      sku: payload.sku,
      description: payload.description,
      url: `https://www.frontrunneroutfitters.com/installation-guides?sku=${payload.sku}`,
      job: payload.job
    })
    .post()
    .blob()
    .then((res) => {
      const blob = window.URL.createObjectURL(
        new Blob([res], { type: 'application/pdf' })
      )

      return blob
    })
    .catch((err) => err)

/**
 * create qr label - Mutation
 */
const useCreateQRLabel = (
  options?: UseMutationOptions<
    T.warehouseCreateQRLabelResponse,
    unknown,
    T.warehouseCreateQRLabelRequest,
    Error
  >
) =>
  useMutation({
    mutationFn: printQRLabel,
    ...options
  })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  useCreateQRLabel
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const warehouseQRLabelsAPIFactory = {
  queries,
  mutations
}
