import revive_payload_client_4sVQNw7RlN from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/buildhome/repo/.nuxt/pwa-icons-plugin.ts";
import pwa_client_Eorgdr12jA from "/opt/buildhome/repo/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_client_LcKgStRyi6 from "/opt/buildhome/repo/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_sb2LSEEy8f from "/opt/buildhome/repo/node_modules/nuxt-quasar-ui/dist/runtime/plugin.js";
import i18n_yfWm7jX06p from "/opt/buildhome/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_AUP22rrBAc from "/opt/buildhome/repo/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import dompurify_html_client_VOUn5jEdfF from "/opt/buildhome/repo/plugins/dompurify-html.client.ts";
import persistedstate_o0qkr1uoQ4 from "/opt/buildhome/repo/plugins/persistedstate.ts";
import vue_jsprintmanager_client_3FjaoXFymO from "/opt/buildhome/repo/plugins/vue-jsprintmanager.client.ts";
import vue_query_wrmMkNpEpe from "/opt/buildhome/repo/plugins/vue-query.ts";
import vue_showdown_client_fDYuKE8JU9 from "/opt/buildhome/repo/plugins/vue-showdown.client.ts";
import vue_virtual_scroller_client_o9JJatl5SA from "/opt/buildhome/repo/plugins/vue-virtual-scroller.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_Eorgdr12jA,
  plugin_client_LcKgStRyi6,
  plugin_sb2LSEEy8f,
  i18n_yfWm7jX06p,
  plugin_AUP22rrBAc,
  dompurify_html_client_VOUn5jEdfF,
  persistedstate_o0qkr1uoQ4,
  vue_jsprintmanager_client_3FjaoXFymO,
  vue_query_wrmMkNpEpe,
  vue_showdown_client_fDYuKE8JU9,
  vue_virtual_scroller_client_o9JJatl5SA
]