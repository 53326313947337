/**
 * ================================================
 * Warehouse Transfer API Factory
 * This factory contains all the methods for interacting with the warehouse transfer feature.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import {
  type QueryFunctionContext,
  type UseQueryOptions,
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import qs from 'qs'
/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'

/**
 * Get warehouses - Call
 *
 * Retrieves the list of warehouses.
 * @param payload - The request payload.
 * @returns A promise that resolves to the response containing the list of warehouses.
 */
export const getWarehouses = async (
  context: QueryFunctionContext
): Promise<T.warehouseGetWarehousesResponse> => {
  return await useWretch('FrAPI')
    .url('/warehouse/api/Warehouses/Stockable')
    .query(
      qs.stringify(
        { region: context.queryKey[1] },
        { encode: false, skipNulls: true }
      )
    )
    .get()
    .json<T.warehouseGetWarehousesResponse>()
    .then((res) => res)
    .catch((err) => err)
}

/**
 * Get warehouses - query
 *
 * Custom hook for fetching warehouses based on the specified region.
 * @param payload - The payload object containing the region and optional query options.
 * @returns The result of the useQuery hook for fetching warehouses.
 */
const useGetWarehouses = (payload: {
  region: ComputedRef<T.regionTypes>
  options?: UseQueryOptions<T.warehouseGetWarehousesResponse>
}) =>
  useQuery({
    queryKey: ['SuggestedPlaces', payload.region],
    queryFn: getWarehouses,
    refetchOnWindowFocus: false,
    ...payload.options
  })

/**
 * Get Warehouse Transfer - Call
 *
 * Retrieves warehouse transfers based on the provided payload.
 * @param payload - The request payload containing region and warehouse information.
 * @returns A promise that resolves to an object containing the warehouse name, incoming transfers, and outgoing transfers.
 */
export const getWarehouseTransfers = async (
  payload: T.warehouseGetWarehouseTransfersRequest
): Promise<{
  warehouse: string
  in: Record<string, T.warehouseType[]>
  out: Record<string, T.warehouseType[]>
}> =>
  await useWretch('FrAPI')
    .url(
      `/warehouse/GoodsInTransit/${payload.region}/${payload.warehouse}/queue`
    )
    .json({})
    .post()
    .json<T.warehouseGetWarehouseTransfersResponse>()
    .then((res) => {
      const inTransfers = res.in.reduce(
        (acc, transfer) => {
          const warehouse = transfer.sourceWarehouse
          if (!acc[warehouse]) acc[warehouse] = []

          acc[warehouse].push(transfer)
          return acc
        },
        {} as Record<string, T.warehouseType[]>
      )

      const outTransfers = res.out.reduce(
        (acc, transfer) => {
          const warehouse = transfer.targetWarehouse
          if (!acc[warehouse]) acc[warehouse] = []

          acc[warehouse].push(transfer)
          return acc
        },
        {} as Record<string, T.warehouseType[]>
      )

      return {
        warehouse: payload.warehouse,
        in: inTransfers,
        out: outTransfers
      }
    })
    .catch((err) => err)

/**
 * Get Warehouse Transfer - query
 *
 * Custom hook for fetching warehouse transfers.
 * @returns The mutation function for fetching warehouse transfers.
 */
const useGetWarehouseTransfers = () =>
  useMutation({
    mutationFn: getWarehouseTransfers
  })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetWarehouses
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  useGetWarehouseTransfers
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const warehouseTransferApiFactory = {
  queries,
  mutations
}
