/**
 * ================================================
 * Sales Graphs API
 * This factory contains all the methods for interacting with the sales graphs feature.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import { type UseQueryOptions, useQuery } from '@tanstack/vue-query'
import qs from 'qs'

/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'

// API Routes
const API_PREFIX = '/order/api/graph'

export const getGraphData = async (
  company: string,
  payload: T.GraphDataRequest
): Promise<T.GraphDataResponse> => {
  const params = qs.stringify(payload)

  return await useWretch('FrAPI')
    .url(`${API_PREFIX}/${company}`)
    .query(params)
    .get()
    .json<T.GraphDataResponse>()
    .then((res) => res)
    .catch((err) => err)
}

const useGetGraphData = (payload: {
  company: ComputedRef<string>
  fromDate: ComputedRef<string>
  toDate: ComputedRef<string>
  minOccurrences: ComputedRef<number>
  minRelationships: ComputedRef<number>
  revenueChannel: ComputedRef<string>
  stockCode: ComputedRef<string>
  options?: UseQueryOptions<T.GraphDataResponse>
}) =>
  useQuery({
    queryKey: [
      'graphData',
      payload.company,
      payload.fromDate,
      payload.toDate,
      payload.minOccurrences,
      payload.minRelationships,
      payload.revenueChannel,
      payload.stockCode
    ],
    queryFn: () =>
      getGraphData(payload.company.value, {
        fromDate: payload.fromDate.value,
        toDate: payload.toDate.value,
        minOccurrences: payload.minOccurrences.value,
        minRelationships: payload.minRelationships.value,
        revenueChannel: payload.revenueChannel.value,
        stockCode: payload.stockCode.value
      }),
    refetchOnWindowFocus: false,
    ...payload.options
  })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetGraphData
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const salesGraphsApiFactory = {
  queries,
  mutations
}
