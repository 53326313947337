/**
 * ================================================
 * Product details factory API
 * This factory contains all the methods for interacting with the product details feature.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import {
  type QueryFunctionContext,
  type UseQueryOptions,
  useQuery
} from '@tanstack/vue-query'
/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'

/**
 * ================================================
 * API routes
 * ================================================
 */
const API_PREFIX = '/api/v2/catalog'

/**
 * Get product details by sku - Call
 *
 * Retrieves the details of a product from the API.
 * @param payload - The request payload containing the SKU of the product.
 * @returns A Promise that resolves to the product details.
 */
export const getProductDetails = async (
  context: QueryFunctionContext
): Promise<T.productDetailsResponse['product']> =>
  await useWretch('FsyncAPI')
    .get(`${API_PREFIX}/products/${context.queryKey[1]}`)
    .json<T.productDetailsResponse>()
    .then(({ product }) => product)
    .catch((err) => err)

/**
 * Get product details by sku - Query
 *
 * Custom hook for fetching product details.
 * @param sku - The SKU of the product.
 * @param options - Additional options for the query.
 * @returns The result of the query.
 */
const useGetProductDetails = (
  sku: ComputedRef<string>,
  options?: UseQueryOptions<T.productDetailsResponse['product']>
) =>
  useQuery({
    queryKey: ['productDetails', sku],
    queryFn: getProductDetails,
    ...options
  })

/**
 * Get product where used by sku - Call
 *
 * Retrieves the list of parent products where the specified product is used.
 * @param payload The request payload containing the SKU of the product.
 * @returns A promise that resolves to an array of parent products.
 */
export const getProductWhereUsed = async (
  context: QueryFunctionContext
): Promise<T.productWhereUsedResponse['parents']> =>
  await useWretch('FsyncAPI')
    .get(`${API_PREFIX}/products/where-used/${context.queryKey[1]}`)
    .json<T.productWhereUsedResponse>()
    .then(({ parents }) => parents)
    .catch((err) => err)

/**
 * Get product where used by sku - Query
 *
 * Retrieves the product where used information for a given SKU.
 * @param sku - The SKU of the product.
 * @param options - The options for the query.
 * @returns A promise that resolves to the product where used information.
 */
const useGetProductWhereUsed = (
  sku: ComputedRef<string>,
  options?: UseQueryOptions<T.productWhereUsedResponse['parents']>
) =>
  useQuery({
    queryKey: ['productDetailsWhereUsed', sku],
    queryFn: getProductWhereUsed,
    ...options
  })

/**
 * Product Bom factory - Call
 *
 * Retrieves the bill of materials (BOM) for a product.
 * @param payload The request payload containing the SKU.
 * @returns A promise that resolves to the BOM response.
 */
export const getProductBom = async (
  context: QueryFunctionContext
): Promise<T.productBomResponse> =>
  await useWretch('FrAPI')
    .url(`/product/Product/bomExplodedFlat/${context.queryKey[1]}`)
    .query({ RetailBomOnly: false })
    .get()
    .json<T.productBomResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Product Bom factory - Query
 *
 * Custom hook for fetching product Bill of Materials (BOM) data.
 * @param sku - The SKU of the product.
 * @param options - Additional options for the useQuery hook.
 * @returns The result of the useQuery hook.
 */
const useGetProductBom = (
  sku: ComputedRef<string>,
  options?: UseQueryOptions<T.productBomResponse>
) =>
  useQuery({
    queryKey: ['productBom', sku],
    queryFn: getProductBom,
    ...options
  })

/**
 * Get product group by sku and region - Call
 *
 * Retrieves the product group details from the API.
 * @param payload - The request payload containing the region and SKU.
 * @returns A promise that resolves to the product group response.
 */
export const getProductGroup = async (
  context: QueryFunctionContext
): Promise<T.productGroupResponse> =>
  await useWretch('FrAPI')
    .get(
      `/product/Product/detail/${context.queryKey[1]}/${context.queryKey[2]}`
    )
    .json<T.productGroupResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Get product group by sku and region - Query
 *
 * Retrieves the product group information based on the provided SKU and region.
 * @param payload - The payload object containing the SKU and region.
 * @param options - The options for the useQuery hook.
 * @returns The result of the useQuery hook.
 */
const useGetProductGroup = (
  payload: {
    sku: ComputedRef<string>
    region: ComputedRef<string>
  },
  options?: UseQueryOptions<T.productGroupResponse>
) =>
  useQuery({
    queryKey: ['productGroup', payload.region, payload.sku],
    queryFn: getProductGroup,
    ...options
  })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetProductDetails,
  useGetProductWhereUsed,
  useGetProductBom,
  useGetProductGroup
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const catalogProductDetailsFactory = {
  queries,
  mutations
}
