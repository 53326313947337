import type * as T from '~/factories/api/azure.api.types'
import { type PosProduct } from './product.types'

export async function getPosProducts(
  region: T.region
): Promise<PosProduct[] | null> {
  return await useWretch('FrAPI')
    .get(`/product/Product/pos/${region}/items`)
    .json<PosProduct[]>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getPosProducts:', err)
      return null
    })
}
