/**
 * ================================================
 * Warehouse Transfer API Factory
 * This factory contains all the methods for interacting with the warehouse transfer feature.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import {
  type UseMutationOptions,
  type UseMutationReturnType,
  type UseQueryOptions,
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import { useOnline } from '@vueuse/core'
/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'
import { useSubscribersStore } from '~/features/events/subscribers/store'

/**
 * ================================================
 * API Routes
 * ================================================
 */
export const API_PREFIX = '/api/v2/competitions'

/**
 * Get Activities
 *
 * Custom hook for fetching activities.
 * @param payload - The payload object.
 * @param payload.options - The options for the useQuery hook.
 * @returns The result of the useQuery hook.
 */
const useGetActivities = (payload?: {
  options?: UseQueryOptions<T.getActivitiesResponse[], Error>
}) =>
  useQuery({
    queryKey: ['getActivities'],
    queryFn: () =>
      useWretch('FsyncAPI')
        .url(`${API_PREFIX}/activities`)
        .get()
        .json<T.getActivitiesResponse[]>()
        .then((res) => res)
        .catch((err) => err),
    enabled: !!useTokenSwitch('fsync') && useOnline(),
    ...payload?.options
  })

/**
 * Get Sessions
 *
 * Custom hook for fetching sessions data.
 * @param payload - The payload object.
 * @param payload.options - The options for the useQuery hook.
 * @returns The result of the useQuery hook.
 */
const useGetSessions = (payload: {
  options?: UseQueryOptions<T.getSessionsResponse>
}) =>
  useQuery({
    queryKey: ['sessions'],
    queryFn: () =>
      useWretch('FsyncAPI')
        .url(`${API_PREFIX}/sessions`)
        .get()
        .json<T.getSessionsResponse>()
        .then((res) =>
          res.map((session) => {
            return {
              ...session,
              content: {
                header: session.content.header || '',
                subheader: session.content.subheader || '',
                textarea_header: session.content.textarea_header || '',
                textarea: session.content.textarea || ''
              }
            }
          })
        )
        .catch((err) => err),
    enabled: !!useTokenSwitch('fsync') && useOnline(),
    refetchOnWindowFocus: false,
    ...payload.options
  })

/**
 * create Session
 *
 * Creates a session using the provided payload.
 * @param payload The payload for creating a session.
 * @returns A mutation hook for creating a session.
 */
const useCreateSession = (payload: {
  options?: UseMutationOptions<
    T.createSessionResponse['session'],
    Error,
    T.createSessionPayloadType,
    unknown
  >
}) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('FsyncAPI')
        .url(`${API_PREFIX}/sessions/create`)
        .json(payload)
        .put()
        .json<T.createSessionResponse>()
        .then((res) => res.session)
        .catch((err) => err),
    ...payload.options
  })

/**
 * Get Session Content
 *
 * Custom hook for fetching session content.
 * @param payload - The payload object.
 * @param payload.sessionId - The ID of the session.
 * @param payload.options - Optional query options.
 * @returns The result of the useQuery hook.
 */
const useSessionContent = (payload: {
  sessionId: number
  options?: UseQueryOptions<T.getSessionContentResponse, Error>
}) =>
  useQuery({
    queryKey: ['getSessionContent', payload.sessionId],
    queryFn: (context) =>
      useWretch('FsyncAPI')
        .url(`${API_PREFIX}/sessions/content/${context.queryKey[1]}`)
        .get()
        .json<T.getSessionContentResponse>()
        .then((res) => res)
        .catch((err) => err),
    enabled: !!useTokenSwitch('fsync') && useOnline(),
    refetchOnWindowFocus: false,
    ...payload.options
  })

/**
 * update Session Content
 *
 * A custom hook for updating session content.
 * @param payload - The payload object.
 * @param payload.options - The options for the mutation.
 * @returns The result of the mutation.
 */
const useUpdateSessionContent = (payload: {
  options?: UseMutationOptions<
    T.updateSessionContentResponse,
    Error,
    T.updateSessionContentRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('FsyncAPI')
        .url(`${API_PREFIX}/sessions/content/${payload.sessionId}`)
        .json(payload.content)
        .post()
        .json<T.updateSessionContentResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...payload.options
  })

/**
 * Get Session Entries
 *
 * Retrieves session entries for a given session ID.
 * @param sessionId The ID of the session.
 * @returns A promise that resolves to an array of session entries.
 */
const useGetSessionEntries = (payload: {
  //sessionId: ComputedRef<number | null>
  sessionId: number
  options?: UseQueryOptions<T.getSessionEntriesResponse[], Error>
}) => {
  const entries = computed(() => useSubscribersStore().entries)

  return useQuery({
    queryKey: ['getSessionEntries', payload.sessionId],
    queryFn: (context) =>
      useWretch('FsyncAPI')
        .url(`${API_PREFIX}/sessions/${context.queryKey[1]}/entries`)
        .get()
        .json<T.getSessionEntriesResponse[]>()
        .then((res) => res)
        .catch((err) => err),
    enabled:
      !!useTokenSwitch('fsync') && useOnline() && !!payload.sessionId,
    refetchOnWindowFocus: false,
    initialData: entries.value,
    ...payload.options
  })
}

/**
 * Update Session Entries
 */
const useUpdateSessionEntries = (payload: {
  sessionId?: number
  options?: UseMutationOptions<
    T.updateSessionEntriesResponse[],
    Error,
    T.updateSessionEntriesRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('FsyncAPI')
        .url(`${API_PREFIX}/sessions/${payload.sessionId}/entries`)
        .json(payload.entries)
        .put()
        .json<T.updateSessionEntriesResponse[]>()
        .then((res) => res)
        .catch((err) => err),
    ...payload.options
  })

/**
 * download Makes Models
 */
const useDownloadMakesModels = (payload?: {
  options?: UseQueryOptions<T.downloadModelMakesResponse>
}) =>
  useQuery({
    queryKey: ['downloadMakesModels'],
    queryFn: () =>
      useWretch('FsyncAPI')
        .url('/api/v2/vfits/pouchdb/makesModels')
        .get()
        .json<T.downloadModelMakesResponse>()
        .then((res) => {
          notifier({
            message: 'Makes and Models downloaded successfully',
            type: 'success'
          })

          return res.map((entry, index) => {
            return {
              ...entry,
              value: entry.make_id,
              label: entry.name,
              key: index,
              _id: entry.name
            }
          })
        })
        .catch((err) => err),
    enabled: !!useTokenSwitch('fsync') && useOnline(),
    refetchOnWindowFocus: false,
    ...(payload?.options || {})
  })

/**
 * ==========================
 * Entries
 * ==========================
 */

/**
 * put Entries
 */
const usePutEntries = (payload: {
  sessionId?: number
  options?: UseMutationOptions<
    T.putEntriesResponse,
    Error,
    T.putEntriesRequest,
    unknown
  >
}): UseMutationReturnType<
  T.putEntriesResponse,
  Error,
  T.putEntriesRequest,
  unknown
> =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('FsyncAPI')
        .url(`${API_PREFIX}/sessions/${payload.sessionId}/entries`)
        .json(payload)
        .put()
        .json<T.putEntriesResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...payload.options
  })

/**
 * GetYears
 */
export function getYears() {
  const d = new Date()
  const cYear = d.getFullYear() + 1

  const years = []
  let i

  for (i = cYear; i >= 1960; i--) {
    years.push(i)
  }

  return years
}

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetSessions,
  useSessionContent,
  useGetSessionEntries,
  useDownloadMakesModels,
  useGetActivities
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  useCreateSession,
  useUpdateSessionContent,
  usePutEntries,
  useUpdateSessionEntries
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const subscribersApiFactory = {
  getYears,
  queries,
  mutations
}
