/**
 * ================================================
 * Catalog Product Positions API
 * This factory contains all the methods for interacting with the catalog product positions feature.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import {
  type QueryFunctionContext,
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery
} from '@tanstack/vue-query'
// import { Notify } from 'quasar'
import { type WritableComputedRef } from 'vue'
/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'

/**
 * getCategories - Call
 */
export const getCategories = async (): Promise<
  T.getCategoriesResponse['categories']
> =>
  await useWretch('FsyncAPI')
    .get('/api/v2/catalog/product-positions/categories/')
    .json<T.getCategoriesResponse>()
    .then((res) => {
      return res.categories
    })
    .catch((err) => err)

/**
 * getCategories - Query
 */
const useGetCategories = (
  options?: UseQueryOptions<T.getCategoriesResponse['categories']>
) =>
  useQuery({
    queryKey: ['getCategories'],
    queryFn: getCategories,
    ...options
  })

/**
 * getProducts - Call
 */
export const getProducts = async (
  context: QueryFunctionContext
): Promise<T.getProductsResponse['products']> =>
  await useWretch('FsyncAPI')
    .get(`/api/v2/catalog/product-positions/categories/${context.queryKey[1]}`)
    .json<T.getProductsResponse>()
    .then((res) => {
      return res.products
    })
    .catch((err) => err)

/**
 * getProducts query
 */
const useGetProducts = ({
  categoryId,
  options
}: {
  categoryId?: ComputedRef<number>
  options?: UseQueryOptions<T.getProductsResponse['products']>
}) =>
  useQuery({
    queryKey: ['getProducts', categoryId],
    queryFn: getProducts,
    ...options
  })

/**
 * saveProductPositions - Mutation
 */
export const saveProductPositions = async (
  payload: T.saveProductPositionsRequest
): Promise<T.saveProductPositionsResponse> =>
  await useWretch('FsyncAPI')
    .post(
      JSON.stringify(payload),
      '/api/v2/catalog/product-positions/categories'
    )
    .json<T.saveProductPositionsResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * saveProductPositions mutation
 */
const useSaveProductPositions = (payload: {
  categoryId?: WritableComputedRef<number | null | undefined>
  products?: T.saveProductPositionsRequest['products']
  options?: UseMutationOptions<
    T.saveProductPositionsResponse,
    unknown,
    T.saveProductPositionsRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: saveProductPositions,
    ...payload.options
  })

/**
 * addProductToCategory - Mutation
 */
export const addProductToCategory = async (
  payload: T.addProductToCategoryRequest
): Promise<T.addProductToCategoryResponse> =>
  await useWretch('FsyncAPI')
    .url(
      `/api/v2/catalog/product-positions/categories/${payload.categoryId}/product/${payload.sku}`
    )
    .json({
      sku: payload.sku
    })
    .post()
    .json<T.addProductToCategoryResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * addProductToCategory - Mutation
 */
const useAddProductToCategory = (payload: {
  categoryId?: WritableComputedRef<number | null | undefined>
  sku?: WritableComputedRef<string | null | undefined>
  options?: UseMutationOptions<
    T.addProductToCategoryResponse,
    unknown,
    T.addProductToCategoryRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: addProductToCategory,
    ...payload.options
  })
/**
 * removeProductFromCategory - Mutation
 */
export const removeProductFromCategory = async (
  payload: T.removeProductFromCategoryRequest
): Promise<T.removeProductFromCategoryResponse> =>
  await useWretch('FsyncAPI')
    .delete(
      `/api/v2/catalog/product-positions/categories/${payload.categoryId}/product/${payload.sku}`
    )
    .json<T.removeProductFromCategoryResponse>()
    .then(() =>
      notifier({
        message: `Product ${payload.sku} Removed`,
        type: 'success'
      })
    )
    .catch((err) => err)

/**
 * removeProductFromCategory - Mutation
 */

const useRemoveProductFromCategory = (payload: {
  categoryId?: WritableComputedRef<number | null | undefined>
  sku?: WritableComputedRef<string | null | undefined>
  options?: UseMutationOptions<
    T.removeProductFromCategoryResponse,
    unknown,
    T.removeProductFromCategoryRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: removeProductFromCategory,
    ...payload.options
  })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetCategories,
  useGetProducts
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  useSaveProductPositions,
  useAddProductToCategory,
  useRemoveProductFromCategory
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const catalogProductPositionsApiFactory = {
  queries,
  mutations
}
