import * as authFactory from './auth.factory'
import * as cashFactory from './cash.factory'
import * as customerFactory from './customer.factory'
import * as inventoryFactory from './inventory.factory'
import * as paymentFactory from './payment.factory'
import * as productFactory from './product.factory'
import * as salesFactory from './sales.factory'
import * as vehiclesFactory from './vehicles.factory'
import * as warehouseFactory from './warehouse.factory'

export const salesPointOfSaleApiFactory = {
  auth: authFactory,
  cash: cashFactory,
  customer: customerFactory,
  inventory: inventoryFactory,
  payment: paymentFactory,
  product: productFactory,
  sales: salesFactory,
  vehicles: vehiclesFactory,
  warehouse: warehouseFactory
}
