import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the sales - black friday page.
 */
const salesBlackFridayMeta: RouteMeta = {
  name: 'sales.black-friday',
  title: 'Black Friday / Cyber Weekend',
  path: '/sales/black-friday',
  order: 10,
  requiresAuth: true,
  show: true,
  permissions: ['sales black friday | view']
}

export default salesBlackFridayMeta
