import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Warehouse - Outer Labels page.
 */
const warehouseLabelsOuterLabelsMeta: RouteMeta = {
  name: 'warehouse.labels.outer-labels',
  title: 'Outer Labels',
  path: '/warehouse/labels/outer-labels',
  description: {
    name: 'description',
    content: 'Warehouse - Outer Labels'
  },
  showOnNav: true,
  order: 4,
  requiresAuth: true,
  show: true,
  permissions: []
}

export default warehouseLabelsOuterLabelsMeta
