import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the sales - giftcards page.
 */
const salesGiftcardsMeta: RouteMeta = {
  name: 'sales.giftcards',
  title: 'Gift Cards',
  path: '/sales/giftcards',
  order: 2,
  show: true,
  requiresAuth: true,
  permissions: ['sales gift cards | view'],
  tabs: [
    {
      value: 'accounts',
      label: 'Accounts',
      permissions: ['sales gift cards accounts | view']
    },
    {
      value: 'orders',
      label: 'Orders',
      permissions: ['sales gift cards orders | view']
    }
  ]
}

export default salesGiftcardsMeta
