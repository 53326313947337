/**
 * ================================================
 * Sales - Black Friday - API
 * This factory contains all the methods for interacting with the sales - black friday feature.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import { type UseQueryOptions, useQuery } from '@tanstack/vue-query'
/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'

/**
 * ================================================
 * API Routes
 * ================================================
 */
const API_ROUTE = '/api/v2/sales/black-friday'

/**
 * getStats - Call
 */
export const getStats = async (): Promise<T.getStatsResponse> =>
  await useWretch('FsyncAPI')
    .url(API_ROUTE)
    .get()
    .json<T.getStatsResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * getStats - Query
 */
export const useGetStats = (options?: UseQueryOptions<T.getStatsResponse>) =>
  useQuery({
    queryKey: ['getStats'],
    queryFn: getStats,
    ...options
  })
/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetStats
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const salesBlackFridayApiFactory = {
  queries,
  mutations
}
