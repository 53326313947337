import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the dashboard page
 */
const dashboardPageMeta: RouteMeta = {
  name: 'dashboard',
  title: 'Dashboard',
  path: '/',
  description: {
    name: 'description',
    content: 'Dashboard'
  },
  icon: 'fad fa-tachometer-fastest',
  order: 0,
  requiresAuth: false,
  show: true,
  permissions: []
}

export default dashboardPageMeta
