import { useSysproStore } from '~/features/syspro/store/syspro-store'
import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Factory - Labels - Product Labels page
 */
const factoryLabelsProductLabelsMeta: RouteMeta = {
  name: 'factory.labels.product-labels',
  path: '/factory/labels/product-labels',
  title: 'Product Labels',
  description: {
    name: 'description',
    content: 'Factory - Labels - Product Labels'
  },
  showOnNav: true,
  order: 1,
  show: true,
  requiresAuth: true,
  permissions: [],
  beforeEnter: () => {
    useSysproStore()
  }
}

export default factoryLabelsProductLabelsMeta
