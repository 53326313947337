import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Content - Gearzette page.
 */
const contentGearzetteMeta: RouteMeta = {
  name: 'content.gearzette',
  title: 'Gearzette',
  path: '/content/gearzette',
  description: {
    name: 'description',
    content: 'Content - Gearzette'
  },
  showOnNav: true,
  order: 1,
  show: true,
  requiresAuth: true,
  permissions: ['content gearzette | view']
}

export default contentGearzetteMeta
