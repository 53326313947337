/**
 * ================================================
 * Documents - Lost and Found POD - API Factory
 * This factory contains all the methods for interacting with the Lost and Found POD feature.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import {
  type QueryFunctionContext,
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import dayjs from 'dayjs'
import { type WritableComputedRef } from 'vue'
import { Notify } from 'quasar'
import qs from 'qs'
/**
 * ================================================
 * Custom packages
 * ================================================
 */
import type * as T from './types'

/**
 * ================================================
 * API Routes
 * ================================================
 */
const API_URL = '/document/LostAndFound'

/**
 * @description getDocuments - Call | Retrieves documents from the API based on the provided request payload.
 * @param payload - The request payload containing the region and document type.
 * @returns A promise that resolves to an array of documents with the lastModified property formatted as a string.
 * @throws An error if the API call fails.
 */
const getDocuments = async (context: QueryFunctionContext) =>
  await useWretch('FrAPI')
    .url(`${API_URL}/${context.queryKey[1]}/${context.queryKey[2]}`)
    .query(qs.stringify({ limit: 1000 }))
    .get()
    .json<T.getDocumentsResponse>()
    .then((response) =>
      response.map((document) => ({
        ...document,
        lastModified: dayjs(document?.lastModified).format(
          'MMMM D YYYY, h:mm:ss a'
        )
      }))
    )
    .catch((err) => err)

/**
 * @description getDocuments - query | A custom hook that fetches documents based on the provided document type and region.
 * @param payload - An object containing the document type, region, and optional query options.
 * @param payload.documentType - A writable computed reference to the document type.
 * @param payload.region - A writable computed reference to the region.
 * @param payload.options - Optional query options for the useQuery hook.
 * @returns An object containing the query result and query status.
 */
const useGetDocuments = (payload: {
  documentType: WritableComputedRef<string>
  region: WritableComputedRef<string>
  options?: UseQueryOptions<T.getDocumentsResponse>
}) =>
  useQuery({
    queryKey: ['documents', payload.region, payload.documentType],
    queryFn: getDocuments,
    refetchOnWindowFocus: false,
    ...payload.options
  })

/**
 * @description saveDocument - Call | Saves a document to the server.
 * @param payload - The request payload containing the document name and syspro invoice.
 * @returns A promise that resolves to the response data.
 * @throws An error if the request fails.
 */
const saveDocument = async (payload: T.saveDocumentRequest) =>
  await useWretch('FrAPI')
    .post(
      JSON.stringify({
        documentName: payload.documentName,
        sysproInvoice: payload.sysproInvoice
      }),
      `${API_URL}/${payload.region}/ReIndex/POD`
    )
    .json<T.saveDocumentResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * @description saveDocument - mutation | A hook that returns a function to save a document.
 * @param payload An object containing optional parameters for the save document function.
 * @param payload.documentName A writable computed reference to the document name.
 * @param payload.sysproInvoice A writable computed reference to the Syspro invoice.
 * @param payload.region A writable computed reference to the region.
 * @param payload.options Optional mutation options for the save document function.
 * @returns A function to save a document.
 */
const useSaveDocument = (payload: {
  documentName?: WritableComputedRef<string>
  sysproInvoice?: WritableComputedRef<string>
  region?: WritableComputedRef<string>
  options?: UseMutationOptions<
    T.saveDocumentResponse,
    unknown,
    T.saveDocumentRequest,
    Error
  >
}) =>
  useMutation({
    mutationFn: saveDocument,
    ...payload.options
  })

/**
 * deleteDocument - Call
 */
export const deleteDocument = async (payload: T.deleteDocumentRequest) =>
  await useWretch('FrAPI')
    .post(
      JSON.stringify({
        documentName: payload.documentName
      }),
      `${API_URL}/${payload.region}/Discard/${payload.documentType}`
    )
    .json<T.deleteDocumentResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * @description deleteDocument - mutation | A custom hook that deletes a document from the server.
 * @param payload An object containing the document name, document type, region, and mutation options.
 * @returns A mutation function that can be called to delete the document.
 */
function useDeleteDocument(payload: {
  documentName?: WritableComputedRef<string>
  documentType?: WritableComputedRef<string>
  region?: WritableComputedRef<string>
  options?: UseMutationOptions<
    T.deleteDocumentResponse,
    unknown,
    T.deleteDocumentRequest,
    Error
  >
}) {
  return useMutation({
    mutationFn: deleteDocument,
    ...payload.options
  })
}

/**
 * @description Get PDF - Call | Fetches a PDF document from the server.
 * @param payload - The request payload.
 * @returns An object containing the document name, type, and PDF data.
 * @throws An error if the PDF could not be fetched.
 */
const getPDF = async (
  context: QueryFunctionContext
): Promise<T.getPDFResponse> =>
  await useWretch('FrAPI')
    .url(`/document/DocumentV2/download/base64/${context.queryKey[1]}`)
    .query(
      qs.stringify(
        {
          DocumentType: context.queryKey[2],
          Name: context.queryKey[3],
          wrapAsDatauri: false
        },
        { encode: false, skipNulls: true }
      )
    )
    .get()
    .res(async (res) => {
      if (res.status === 200) {
        return await res.text()
      } else {
        throw new Error('Could not fetch PDF')
      }
    })
    .then((response) => {
      Notify.create({
        message: 'PDF fetched successfully',
        color: 'positive',
        position: 'bottom-right'
      })
      return {
        documentType: context.queryKey[2] as string,
        documentName: context.queryKey[3] as string,
        documentPDF: response
      }
    })
    .catch((error) => {
      Notify.create({
        message: 'PDF could not be fetched',
        color: 'negative',
        position: 'bottom-right'
      })
      throw error
    })

/**
 * Get PDF - query | Returns a `useQuery` hook for fetching a PDF document.
 * @param payload - An object containing the document name, document type, region, and optional query options.
 * @returns A `useQuery` hook for fetching a PDF document.
 */
const useGetPDF = (payload: {
  documentName: WritableComputedRef<string>
  documentType: WritableComputedRef<string>
  region: WritableComputedRef<string>
  options?: UseQueryOptions<T.getPDFResponse>
}) =>
  useQuery({
    queryKey: [
      'pdf',
      payload.region,
      payload.documentType,
      payload.documentName
    ],
    queryFn: getPDF,
    ...payload.options,
    refetchOnWindowFocus: false
  })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetDocuments,
  useGetPDF
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  useSaveDocument,
  useDeleteDocument
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const documentsLostAndFoundPodApiFactory = {
  queries,
  mutations
}
