import { initialState_subscribers } from './state'

export const useSubscribersStore = defineStore('Subscribers', {
  state: () => initialState_subscribers,

  actions: {
    setModels(data: typeof this.vehicle.modelOptions) {
      this.vehicle.modelOptions = data.map((entry, index) => ({
        ...entry,
        value: entry._id,
        label:
          entry.model_name +
          (entry.option_name !== '' ? ' - ' + entry.option_name : ''),
        make_id: entry.make_id,
        model_id: entry.model_id,
        option_id: entry.option_id,
        key: index
      }))
    }
  }
})
