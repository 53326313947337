import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Performance Reviews page.
 */
const performanceReviewsPageMeta: RouteMeta = {
  name: 'human-resources.performance-reviews',
  title: 'Performance Reviews',
  path: '/human-resources/performance-reviews',
  description: {
    name: 'description',
    content: 'Performance Reviews'
  },
  showOnNav: true,
  order: 0,
  show: true,
  requiresAuth: true,
  permissions: ['human resources performance reviews | view']
}

export default performanceReviewsPageMeta
