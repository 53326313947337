/**
 * ================================================
 * Catalog product-specs API
 * This factory contains all the methods for interacting with the catalog product-specs feature.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import {
  type QueryFunctionContext,
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery
} from '@tanstack/vue-query'
/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'

/**
 * API Routes
 */
const API_PREFIX = '/api/v2/catalog'
/**
 * compareProducts - Call
 */

// product specs
export const getProductSpecs = async (
  context: QueryFunctionContext
): Promise<T.productSpecsResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_PREFIX}/products/specs/${context.queryKey[1]}`)
    .get()
    .json<T.productSpecsResponse>()
    .then((res) => res)
    .catch((err) => {
      notifier({
        message: 'Failed to fetch product specs',
        type: 'error'
      })

      return err
    })

// product specs hook
const useGetProductSpecs = (
  payload: T.productSpecsRequest,
  options?: UseQueryOptions<T.productSpecsResponse>
) =>
  useQuery({
    queryKey: ['productSpecs', payload.sku],
    queryFn: getProductSpecs,
    ...options
  })

// product specs upload
export const postProductSpecs = async (
  payload: T.productSpecsUploadRequest
): Promise<T.productSpecsUploadResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_PREFIX}/products/specs`)
    .body(payload)
    .post()
    .json<T.productSpecsUploadResponse>()
    .then((res) => res)
    .catch((err) => err)

// product specs upload hook
const usePostProductSpecs = (
  options?: UseMutationOptions<
    T.productSpecsUploadResponse,
    unknown,
    T.productSpecsUploadRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: postProductSpecs,
    ...options
  })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetProductSpecs
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  usePostProductSpecs
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const catalogProductSpecsFactory = {
  queries,
  mutations
}
