import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Factory - Shipping Dimensions page
 */
const factoryShippingDimensionsMeta: RouteMeta = {
  name: 'factory.shipping-dimensions',
  path: '/factory/shipping-dimensions',
  title: 'Shipping Dimensions',
  description: {
    name: 'description',
    content: 'Factory - Shipping Dimensions'
  },
  showOnNav: true,
  order: 1,
  requiresAuth: true,
  show: true,
  permissions: ['factory shipping dimensions | view']
}

export default factoryShippingDimensionsMeta
