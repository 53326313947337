import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the users admin page
 */
const usersAdminPageMeta: RouteMeta = {
  name: 'admin.users',
  title: 'Users',
  path: '/admin/users',
  description: {
    name: 'description',
    content: 'Admin - Users'
  },
  showOnNav: true,
  order: 4,
  requiresAuth: true,
  show: true,
  icon: 'fas fa-users-cog',
  permissions: ['admin users | view']
}

export default usersAdminPageMeta
