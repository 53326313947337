import {
  ThumbnailImageFormat,
  type ThumbnailImageFormatValues
} from './image.enums'

export async function getProductThumbnailFromUrl(
  url: string
): Promise<Blob | null> {
  const headers = {
    Authorization: `Bearer ${useTokenSwitch('azure')}`
  }

  const response = await fetch(url, { headers })
  return !response.ok ? null : await response.blob()
}

export async function getProductThumbnail(
  stockCode: string,
  thumbnailImageFormat: ThumbnailImageFormatValues = ThumbnailImageFormat.jpg
): Promise<Blob | null> {
  return await useWretch('FrAPI')
    .get(`/product/api/thumbnail/${thumbnailImageFormat}/${stockCode}`)
    .blob()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getProductThumbnail:', err)
      return null
    })
}
