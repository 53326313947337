import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Warehouse - QR Labels page.
 */
const warehouseLabelsQRLabelsMeta: RouteMeta = {
  name: 'warehouse.labels.qr-labels',
  title: 'QR Labels',
  path: '/warehouse/labels/qr-labels',
  description: {
    name: 'description',
    content: 'Warehouse - QR Labels'
  },
  showOnNav: true,
  order: 6,
  requiresAuth: true,
  show: true,
  permissions: []
}

export default warehouseLabelsQRLabelsMeta
