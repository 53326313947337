import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Warehouse - Retail Labels page.
 */
const warehouseLabelsRetailLabelsMeta: RouteMeta = {
  name: 'warehouse.labels.retail-labels',
  title: 'Retail Labels',
  path: '/warehouse/labels/retail-labels',
  order: 7,
  requiresAuth: true,
  show: true,
  showOnNav: true,
  permissions: [],
  description: {
    name: 'description',
    content: 'Warehouse - Retail Labels'
  }
}

export default warehouseLabelsRetailLabelsMeta
