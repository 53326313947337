import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Factory - Labels - QR Labels page
 */
const factoryLabelsQRLabelsMeta: RouteMeta = {
  name: 'factory.labels.qr-labels',
  title: 'QR Labels',
  path: '/factory/labels/qr-labels',
  description: {
    name: 'description',
    content: 'Factory - Labels - QR Labels'
  },
  showOnNav: true,
  order: 2,
  show: true,
  requiresAuth: true,
  permissions: []
}

export default factoryLabelsQRLabelsMeta
