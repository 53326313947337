import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the warehouse transfer page.
 */
const warehousePickingDetailsPageMeta: RouteMeta = {
  name: 'warehouse.picking-details',
  title: 'Picking Details',
  path: '/warehouse/picking-details',
  description: {
    name: 'description',
    content: 'Warehouse - Picking Details'
  },
  showOnNav: true,
  keepalive: false,
  order: 1,
  requiresAuth: true,
  show: true,
  permissions: ['warehouse picking details | view']
}

export default warehousePickingDetailsPageMeta
