export function getDynamicPath(
  json: Record<string, unknown>,
  path: string
): unknown {
  const pathArray = path.split('.')

  let current: unknown = json
  for (const key of pathArray) {
    if (typeof current === 'object' && current !== null && key in current) {
      current = (current as Record<string, unknown>)[key]
    } else {
      return undefined // Return undefined if the path is invalid
    }
  }

  return current
}

export function isJSONString(str: string) {
  try {
    JSON.parse(str)
    return true
  } catch (error) {
    return false
  }
}
