import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the warehouse transfer page.
 */
const warehouseTransferPageMeta: RouteMeta = {
  name: 'warehouse.warehouse-transfers',
  title: 'Warehouse Transfers',
  path: '/warehouse/warehouse-transfers',
  description: {
    name: 'description',
    content: 'Warehouse - Warehouse Transfers'
  },
  showOnNav: true,
  keepalive: false,
  order: 4,
  requiresAuth: true,
  show: true,
  permissions: ['warehouse picking details | view']
}

export default warehouseTransferPageMeta
