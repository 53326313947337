import type {
  wrikeIssueType,
  wrikeUserType
} from '~/features/service-desk/api/types'

export type WrikeUserType = wrikeUserType

export const initialState_serviceDesk: initialStateType_serviceDesk = {
  wrikeUsers: [],
  wrikeUsersFiltered: [],
  wrikeUser: null,
  email: '',
  search: null,
  activeIssue: null,
  searchedIssues: [],
  currentPage: 'issues-page',
  issueForm: {
    assignees: [],
    users: [],
    issueType: 0,
    wrikeUsersFilter: [],
    childIssueType: 0,
    description: '',
    subject: '',
    summary: '',
    files: []
  },
  isAlert: false
}

type initialStateType_serviceDesk = {
  wrikeUsers: wrikeUserType[]
  wrikeUsersFiltered: wrikeUserType[]
  wrikeUser: wrikeUserType | null
  email: string
  search: wrikeIssueType | null
  activeIssue: wrikeIssueType | null
  searchedIssues: wrikeIssueType[]
  currentPage:
    | 'self-help-page'
    | 'create-wrike-issue-page'
    | 'issues-page'
    | 'reset-magento-password-page'
  issueForm: issueForm
  isAlert: boolean
}

export type issueForm = {
  assignees: wrikeUserType[]
  users: wrikeUserType[]
  issueType: number
  wrikeUsersFilter: wrikeUserType[]
  childIssueType: number
  description: string
  subject: string
  summary: string
  files: string[]
}
