import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the events subscribers form page.
 */
const eventsSubscribersFormMeta: RouteMeta = {
  name: 'events.subscribers.form',
  path: '/events/subscribers/form',
  title: 'Form',
  description: {
    name: 'description',
    content: 'Events - Subscribers Form'
  },
  showOnNav: false,
  order: 1,
  requiresAuth: false,
  show: true,
  permissions: []
}

export default eventsSubscribersFormMeta
