import { type region } from '~/factories/api/azure.api.types'
import type * as DT from './document.types'
import { type TokenTargetValues } from './document.enums'

export async function searchDocumentArchive(
  region: region,
  searchQuery: string,
  limit: number = 1000
): Promise<DT.DocumentArchiveIndexResultEntry[] | null> {
  return await useWretch('FrAPI')
    .get(
      `/document/DocumentV2/search?q=${searchQuery}&limit=${limit}&region=${region}`
    )
    .json<DT.DocumentArchiveIndexResultEntry[]>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in searchDocumentArchive:', err)
      return null
    })
}

export async function searchDocumentArchiveByTag(
  region: region,
  tags: DT.Tags,
  limit: number = 1000
): Promise<DT.DocumentArchiveIndexResultEntry[] | null> {
  return await useWretch('FrAPI')
    .post(
      JSON.stringify(tags ?? {}),
      `/document/DocumentV2/list/${region}?getAllTags=true&limit=${limit}`
    )
    .json<DT.DocumentArchiveIndexResultEntry[]>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in searchDocumentArchiveByTag:', err)
      return null
    })
}

export async function getDocumentTypes(): Promise<string[] | null> {
  return await useWretch('FrAPI')
    .get('/document/DocumentV2/doc/types')
    .json<string[]>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getDocumentTypes:', err)
      return null
    })
}

export async function retrieveDocument(
  region: region,
  query: DT.DownloadDocumentQuery
): Promise<Blob | null> {
  return await useWretch('FrAPI')
    .query(query ?? {})
    .get(`/document/DocumentV2/download/${region}`)
    .blob()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in retrieveDocument:', err)
      return null
    })
}

export async function retrieveBase64Document(
  region: region,
  query: DT.DownloadBase64DocumentQuery
): Promise<string | null> {
  return await useWretch('FrAPI')
    .query(query ?? {})
    .get(`/document/DocumentV2/download/base64/${region}`)
    .text()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in retrieveBase64Document:', err)
      return null
    })
}

export async function retrieveDocumentFromUrl(
  tokenTarget: TokenTargetValues,
  url: string
): Promise<Blob | null> {
  return await useWretch(tokenTarget)
    .get(url)
    .blob()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in retrieveDocumentFromUrl:', err)
      return null
    })
}

export async function retrieveBase64DocumentFromUrl(
  tokenTarget: TokenTargetValues,
  url: string,
  responsePath: string
): Promise<string | undefined | null> {
  return await useWretch(tokenTarget)
    .get(url)
    .json()
    .then((res) => {
      const result = getDynamicPath(
        res as Record<string, unknown>,
        responsePath
      )
      if (typeof result === 'string') {
        return result
      } else {
        return undefined // If it's not a string, return undefined
      }
    })
    .catch((err) => {
      console.error('Error in retrieveBase64DocumentFromUrl:', err)
      return null // Return null in case of an error
    })
}

export async function uploadBase64ImageToPdfDocument(
  region: region,
  uploadBase64ImageToPdfDocumentRequest: DT.UploadBase64ImageToPdfDocumentRequest
): Promise<DT.UploadBase64DocumentResponse | null> {
  return await useWretch('FrAPI')
    .post(
      JSON.stringify(uploadBase64ImageToPdfDocumentRequest),
      `/document/DocumentV2/createFromImageBase64/${region}`
    )
    .json<DT.UploadBase64DocumentResponse>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in uploadBase64ImageToPdfDocument:', err)
      throw new Error(err)
    })
}

export async function uploadBase64Document(
  region: region,
  uploadBase64DocumentRequest: DT.UploadBase64DocumentRequest,
  overWrite: boolean = false
): Promise<DT.UploadBase64DocumentResponse | null> {
  return await useWretch('FrAPI')
    .post(
      JSON.stringify(uploadBase64DocumentRequest),
      `/document/DocumentV2/uploadBase64/${region}?overWrite=${overWrite}`
    )
    .json<DT.UploadBase64DocumentResponse>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in uploadBase64Document:', err)
      throw new Error(err)
    })
}

export async function emailForwardDocument(
  region: region,
  forwardDocumentRequest: DT.ForwardDocumentRequest
): Promise<boolean | null> {
  return await useWretch('FrAPI')
    .post(
      JSON.stringify(forwardDocumentRequest),
      `/document/DocumentV2/forward/${region}`
    )
    .res()
    .then((res) => {
      if (res.status === 204) {
        return true
      } else {
        return false
      }
    })
    .catch((err) => {
      console.error('Error in emailForwardDocument:', err)
      return null
    })
}
