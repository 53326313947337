import { type region } from '~/factories/api/azure.api.types'
import { type BomComponent, type QueryExplodedFlatForRegion } from './types'

export async function getProductBomExplodedFlat(
  stockCode: string
): Promise<BomComponent[] | null> {
  return await useWretch('FrAPI')
    .get(`/product/Product/bomExplodedFlat/${stockCode}?RetailBomOnly=false`)
    .json<BomComponent[]>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getProductBomExplodedFlat:', err)
      return null
    })
}

export async function getProductBomExplodedFlatForRegion(
  region: region,
  stockCode: string,
  query: QueryExplodedFlatForRegion
): Promise<BomComponent[] | null> {
  return await useWretch('FrAPI')
    .query(query)
    .get(`/product/Product/bomExplodedFlat/${region}/${stockCode}`)
    .json<BomComponent[]>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getProductBomExplodedFlatForRegion:', err)
      return null
    })
}

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  getProductBomExplodedFlatForRegion,
  getProductBomExplodedFlat
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {}

/**
 * ================================================
 * Factory
 * ================================================
 */
const productApiFactory = {
  queries,
  mutations
}

export default productApiFactory
