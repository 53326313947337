import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the verify page.
 * @auth Requires authentication
 */
const authVerifyPageMeta: RouteMeta = {
  name: 'verify',
  title: 'Verify',
  path: '/verify',
  description: {
    name: 'description',
    content: 'Verify your account'
  },
  order: 0,
  requiresAuth: true,
  show: true,
  permissions: []
}

export default authVerifyPageMeta
