import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the sales - Product Pricing page.
 */
const salesProductPricingMeta: RouteMeta = {
  name: 'sales.product-pricing',
  title: 'Product Pricing',
  path: '/sales/product-pricing',
  order: 8,
  requiresAuth: true,
  show: true,
  permissions: ['sales product pricing | view']
}

export default salesProductPricingMeta
