import type * as T from '~/factories/api/azure.api.types'
import type * as CT from './customer.types'

export async function getCustomers(
  region: T.region,
  query: CT.GetCustomersQuery
): Promise<CT.Customer[] | null> {
  return await useWretch('FrAPI')
    .query(query)
    .get(`/customer/customer/region/${region}`)
    .json<CT.Customer[]>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getCustomers:', err)
      return null
    })
}

export async function getCustomerAccountDetails(
  region: T.region,
  customerCode: string,
  includeDiscount: boolean
): Promise<CT.CustomerDetails | null> {
  return await useWretch('FrAPI')
    .get(
      `/Customer/customer/region/${region}/account/${customerCode}?includeDiscount=${includeDiscount}`
    )
    .json<CT.CustomerDetails>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getCustomerAccountDetails:', err)
      return null
    })
}
