import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the logout page.
 * @auth Requires authentication
 */
const authLogoutPageMeta: RouteMeta = {
  name: 'auth.logout',
  title: 'Logout',
  path: '/auth/logout',
  description: {
    name: 'description',
    content: 'Logout of your account.'
  },
  order: 0,
  requiresAuth: true,
  show: true,
  permissions: []
}

export default authLogoutPageMeta
