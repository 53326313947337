import { useSysproStore } from '~/features/syspro/store/syspro-store'
import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Factory - Labels - Factory Labels page
 */
const factoryLabelsFactoryLabelsMeta: RouteMeta = {
  name: 'factory.labels.factory-labels',
  path: '/factory/labels/factory-labels',
  title: 'Factory Labels',
  description: {
    name: 'description',
    content: 'Factory - Labels - Factory Labels'
  },
  showOnNav: true,
  order: 0,
  show: true,
  requiresAuth: true,
  permissions: [],
  beforeEnter: () => {
    useSysproStore()
  }
}

export default factoryLabelsFactoryLabelsMeta
