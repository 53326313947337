/**
 * ================================================
 * Warehouse -> Labels -> Outer Labels -> API
 * This factory contains all the methods for interacting with the warehouse outer labels API.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import { type UseMutationOptions, useMutation } from '@tanstack/vue-query'

/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'

/**
 * ================================================
 * API Routes
 * ================================================
 */

/**
 * create outer label - Call
 */
const printOuterLabel = async (
  payload: T.warehouseCreateOuterLabelRequest
): Promise<T.warehouseCreateOuterLabelResponse> =>
  await useWretch('FrAPI')
    .url('/warehouse/Labels/outerLabelFile')
    .query({
      pageSize: payload?.pageSize
    })
    .json({
      stockCode: payload.stockCode,
      qty: payload.qty.toString(),
      copies: 1
    })
    .post()
    .blob()
    .then((res) => {
      const blob = window.URL.createObjectURL(
        new Blob([res], { type: 'application/pdf' })
      )

      return blob
    })
    .catch((err) => err)

/**
 * create outer label - Mutation
 */
const useCreateOuterLabel = (
  options?: UseMutationOptions<
    T.warehouseCreateOuterLabelResponse,
    unknown,
    T.warehouseCreateOuterLabelRequest,
    Error
  >
) =>
  useMutation({
    mutationFn: printOuterLabel,
    ...options
  })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  useCreateOuterLabel
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const warehouseOuterLabelsAPIFactory = {
  queries,
  mutations
}
