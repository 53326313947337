import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the carrier labels page
 */
const warehouseLabelsCarrierLabelsPageMeta: RouteMeta = {
  name: 'warehouse.labels.carrier-labels',
  title: 'Carrier Labels',
  path: '/warehouse/labels/carrier-labels',
  description: {
    name: 'description',
    content: 'Warehouse - Carrier Labels'
  },
  showOnNav: true,
  isBeta: true,
  order: 2,
  requiresAuth: true,
  show: true,
  permissions: []
}

export default warehouseLabelsCarrierLabelsPageMeta
