import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the documents lost and found pod page.
 */
const lostAndFoundPodMeta: RouteMeta = {
  name: 'documents.lost-and-found-pod',
  title: 'Lost and Found POD',
  path: '/documents/lost-and-found-pod',
  description: {
    name: 'description',
    content: 'Document - Lost and Found POD'
  },
  showOnNav: true,
  order: 2,
  requiresAuth: true,
  show: true,
  permissions: ['documents lost and found | view']
}

export default lostAndFoundPodMeta
