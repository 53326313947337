/**
 * ================================================
 * Release notes factory API
 * This factory contains all the methods for interacting with the release/pathnotes feature.
 * ================================================
 */

import type { QueryFunctionContext, UseQueryOptions } from '@tanstack/vue-query'
import { useQuery } from '@tanstack/vue-query'
import type * as T from './types'

const API_PREFIX = '/api/v2/release-notes'

const useGetReleaseNotes = () =>
  useQuery({
    queryKey: ['releaseNotes'],
    queryFn: async (): Promise<T.ReleaseNotesResponse> =>
      useWretch('FsyncAPI')
        .url(API_PREFIX)
        .get()
        .json<T.ReleaseNotesResponse>()
        .then((res) => {
          res.releaseNotes.data.reverse()
          return res
        })
        .catch((err) => err)
  })

const useReleaseNotesBySystem = (payload: { system: ComputedRef<string> }) =>
  useQuery({
    queryKey: ['filteredReleaseNotes', payload.system.value],
    queryFn: async (): Promise<T.ReleaseNotesResponse> =>
      useWretch('FsyncAPI')
        .url(`${API_PREFIX}?system=${payload.system.value}`)
        .get()
        .json<T.ReleaseNotesResponse>()
        .then((res) => {
          res.releaseNotes.data.reverse()

          return res
        })
        .catch((err) => err)
  })

export const queries = {
  useGetReleaseNotes,
  useReleaseNotesBySystem
}

export const ReleaseNotesApiFactory = {
  queries
}
