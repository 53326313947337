/**
 * ================================================
 * Warehouse Picking Dashboard Factory
 * This factory contains all the methods for interacting with the warehouse picking dashboard feature.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import {
  type QueryFunctionContext,
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery
} from '@tanstack/vue-query'
/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'

/**
 * API Routes
 */
const API_ROUTE = '/api/v2/warehouse'

/**
 * getOrders - Call
 */
export async function getPickingDashboardOrders(
  context: QueryFunctionContext
): Promise<T.getOrdersResponse> {
  return await useWretch('FsyncAPI')
    .get(
      `${API_ROUTE}/orders/${context.queryKey[1]}?warehouse=${context.queryKey[2]}`
    )
    .json<T.getOrdersResponse>()
    .then((res) => res)
    .catch((err) => err)
}

/**
 * useGetOrders - Query
 */
function useGetPickingDashboardOrders(payload: {
  country: ComputedRef<string>
  warehouseLocation: ComputedRef<string[]>
  options?: UseQueryOptions<T.getOrdersResponse>
}) {
  return useQuery({
    queryKey: ['getOrders', payload.country, payload.warehouseLocation],
    queryFn: getPickingDashboardOrders,
    ...payload.options
  })
}

/**
 * deleteOrder - Call
 */
export async function deleteOrder(payload: T.deleteOrderRequest) {
  return await useWretch('FsyncAPI')
    .post(JSON.stringify(payload), API_ROUTE + '/orders/delete')
    .json<T.getOrdersResponse>()
    .then(() =>
      notifier({
        message: payload?._id + ' successfully deleted.',
        type: 'success'
      })
    )
    .catch((err) => err)
}

/**
 * useDeleteOrder - Mutation
 */
function useDeleteOrder(
  options?: UseMutationOptions<
    T.deleteOrderResponse,
    unknown,
    T.deleteOrderRequest,
    unknown
  >
) {
  return useMutation({
    mutationFn: deleteOrder,
    ...options
  })
}

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetPickingDashboardOrders
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  useDeleteOrder
}
