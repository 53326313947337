export default {
  "components-expansion-item-component": () => import("/opt/buildhome/repo/layouts/components/expansion-item-component.vue").then(m => m.default || m),
  "components-menu-button-component": () => import("/opt/buildhome/repo/layouts/components/menu-button-component.vue").then(m => m.default || m),
  "components-menu-item-component": () => import("/opt/buildhome/repo/layouts/components/menu-item-component.vue").then(m => m.default || m),
  "components-navbar-component": () => import("/opt/buildhome/repo/layouts/components/navbar-component.vue").then(m => m.default || m),
  "components-theme-components-theme-component": () => import("/opt/buildhome/repo/layouts/components/theme-components/theme-component.vue").then(m => m.default || m),
  "components-theme-components-theme-switch-button": () => import("/opt/buildhome/repo/layouts/components/theme-components/theme-switch-button.vue").then(m => m.default || m),
  "components-theme-components-theme-switch": () => import("/opt/buildhome/repo/layouts/components/theme-components/theme-switch.ts").then(m => m.default || m),
  "components-user-menu-button-component": () => import("/opt/buildhome/repo/layouts/components/user-menu-button-component.vue").then(m => m.default || m),
  default: () => import("/opt/buildhome/repo/layouts/default.vue").then(m => m.default || m),
  "logo-component": () => import("/opt/buildhome/repo/layouts/logo-component.vue").then(m => m.default || m)
}