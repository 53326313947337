import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the price list page.
 */
const priceListMeta: RouteMeta = {
  name: 'customers.price-list',
  title: 'Custom Price List',
  path: '/customers/price-list',
  description: {
    name: 'description',
    content: 'Customers - Custom Price List'
  },
  showOnNav: true,
  order: 2,
  requiresAuth: true,
  show: true,
  permissions: ['customers custom price list | view']
}

export default priceListMeta
