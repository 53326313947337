import axios from 'axios'
// import { APP_ENV } from 'src/config'

// Loging to Logtail
export const logtail = (payload: {
  message: string
  user?: string
  functionName?: string
  statusCode?: number
  appVersion?: string
  apiURL?: string
}) => {
  axios.defaults.headers.common.Authorization =
    'Bearer GGuci76WuoMNvxLjFw2MQsUn'

  const data = {
    message: `🚀 ~ Campfire ${process.env.NODE_ENV} - ${payload.message}`,
    user: payload.user,
    app: 'Campfire',
    status_code: payload.statusCode,
    app_version: payload.appVersion,
    api_url: payload.apiURL
  }

  if (process.env.NODE_ENV === 'development') {
    console.log(data)
  } else {
    axios.post('https://in.logtail.com/', {
      headers: {
        'Content-Type': 'application/json'
      },
      ...data
    })
  }
}

// Generate random string
export function random_string(minLength = 0, acc = ''): string {
  if (acc.length <= minLength) {
    const str = Math.random().toString(36).slice(2)
    return random_string(minLength, acc.concat(str))
  }

  return acc.slice(0, minLength)
}

export function function_error({
  name,
  message
}: {
  name: string
  message: string
}) {
  const error_code = random_string(12)

  logtail({
    message: 'API ERROR \n',
    functionName: `code: ${error_code} \n
    ${name} \n
    ${message.toString()}`
  })
}
