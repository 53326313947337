export default function (
  contents: string,
  file_name: string,
  file_type: string
) {
  const byteCharacters = atob(contents)
  const byteNumbers = new Array(byteCharacters.length)

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const blob = new Blob([byteArray], {
    type: file_type
  })

  return blob
}
