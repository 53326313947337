import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the events gearlist form page.
 */
const eventsGearlistFormMeta: RouteMeta = {
  name: 'events.gearlist.form',
  path: '/events/gearlist/form',
  title: 'Form',
  description: {
    name: 'description',
    content: 'Events - Gearlist Form'
  },
  showOnNav: false,
  order: 0,
  requiresAuth: false,
  show: true,
  permissions: []
}

export default eventsGearlistFormMeta
