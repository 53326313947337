async function getEmployeeImages() {
  return await useWretch('FrAPI')
    .get('/performance_review/employee/images/mosaic')
    .text((text) => text)
    .catch((err) => {
      console.error('Error in getEmployeeImages:', err)
      return null
    })
}

export const employee = {
  getEmployeeImages
}
