import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Warehouse - Picking Dashboard page.
 */
const warehousePickingDashboardMeta: RouteMeta = {
  name: 'warehouse.dashboard-picking',
  title: 'Picking Dashboard',
  path: '/warehouse/picking-dashboard',
  description: {
    name: 'description',
    content: 'Warehouse - Picking Dashboard'
  },
  showOnNav: true,
  keepalive: false,
  order: 0,
  requiresAuth: true,
  show: true,
  permissions: ['warehouse picking dashboard | view']
}

export default warehousePickingDashboardMeta
