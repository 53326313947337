import * as JSPM from 'jsprintmanager'
import type { FileSourceType } from 'jsprintmanager'
import type {
  PrinterResponseType,
  PrinterType,
  sendToPrinterType
} from './types'
import printerConfigs from './printer-configs'
import useAuthStore from '~/features/auth/store'
import { Dialog, Notify } from 'quasar'

type positionTypes =
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right'
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'center'
  | undefined

type statusType = {
  id: number
  message: string
  code: 'connected' | 'not installed' | 'blocked' | 'waiting' | 'disconnected'
  notify: {
    position: positionTypes
    color: string | undefined
    icon: string
    actions?: {
      label: string
      color: string
      handler: () => void
    }[]
    timeout?: number
  }
}
export class CampfirePrinter {
  private licenseUrl =
    'https://fsync.frontrunneroutfitters.com/api/jspm/license'
  private autoReconnect = false
  private jspm

  public store = useAuthStore()

  public printing = computed({
    get: () => this.store.printer.printing,
    set: (value) => (this.store.printer.printing = value)
  })
  public loading = computed({
    get: () => this.store.printer.loading,
    set: (value) => (this.store.printer.loading = value)
  })
  public checking = computed({
    get: () => this.store.printer.checking,
    set: (value) => (this.store.printer.checking = value)
  })
  public models = computed({
    get: () => this.store.printer.modals,
    set: (value) => (this.store.printer.modals = value)
  })
  public status = computed({
    get: () => this.store.printer.status,
    set: (value) => (this.store.printer.status = value)
  })
  public printers = computed({
    get: () => this.store.printer.availablePrinters,
    set: (value) => (this.store.printer.availablePrinters = value)
  })
  public printerConfigs = computed({
    get: () => this.store.printer.printerConfigs,
    set: (value) => (this.store.printer.printerConfigs = value)
  })

  constructor() {
    if (import.meta.client) {
      this.jspm = JSPM
      this.jspm.JSPrintManager.auto_reconnect = this.autoReconnect
      this.jspm.JSPrintManager.license_url = this.licenseUrl

      if (this.checking.value === true) this.checking.value = false
    }
  }

  private async start() {
    if (import.meta.client) {
      this.notify({
        message: 'JS Print Manager is not connected. Attempting to connect...',
        opts: {
          position: 'bottom-right',
          color: undefined,
          icon: 'fal fa-exclamation-triangle'
        }
      })

      try {
        this.loading.value = true

        setTimeout(() => {
          if (this.loading.value) {
            this.notify({
              message: 'JS Print Manager is taking longer than expected.',
              opts: {
                position: 'bottom-right',
                color: 'warning',
                icon: 'fal fa-exclamation-triangle'
              }
            })
            this.loading.value = false
          }
        }, 12000)

        await this.jspm?.JSPrintManager?.start()

        if (this.printerConfigs.value.length !== printerConfigs.length)
          this.setDefaultConfigs(printerConfigs)

        this.printers.value = await this.getAvailablePrinters()

        this.loading.value = false

        this.notify({
          message: 'JS Print Manager is connected.',
          opts: {
            position: 'bottom-right',
            color: 'green',
            icon: 'fal fa-check-circle'
          }
        })
      } catch (error) {
        this.notify({
          message: 'JS Print Manager failed to connect.',
          opts: {
            position: 'bottom-right',
            color: 'warning',
            icon: 'fal fa-exclamation-triangle'
          }
        })
        console.error(error)
      } finally {
        this.loading.value = false
      }
    }
  }

  public async check() {
    if (import.meta.client) {
      const status = await this.WSStatus()
      this.status.value = status.code
      if (!this.checking.value) {
        this.checking.value = true

        if (status.code === 'connected' && this.jspm?.JSPrintManager?.WS) {
          this.printers.value = await this.getAvailablePrinters()
          this.jspm.JSPrintManager.WS.onStatusChanged = async () =>
            (this.printers.value = await this.getAvailablePrinters())
        } else {
          await this.start()
        }

        if (this.printerConfigs.value.length !== printerConfigs.length)
          this.setDefaultConfigs(printerConfigs)

        this.checking.value = false
      } else {
        console.log('Already checking...')
      }
    }
  }

  public async reload() {
    this.loading.value = true
    await this.check()
    this.loading.value = false
  }

  /**
   * Get available printers
   * @returns {PrinterResponseType[]}
   * @memberof CampfirePrinter
   */
  private async getAvailablePrinters() {
    // console.log('Fetching available printers...')

    if (this.jspm?.JSPrintManager) {
      this.printers.value = await this.jspm.JSPrintManager.getPrintersInfo(
        0,
        '',
        this.jspm.PrinterIcon.None
      )
        .then((myPrinters) => {
          return myPrinters as PrinterResponseType[]
        })
        .catch((e) => {
          console.log('Error: ', e)
          console.log('failed to get printers info')

          return []
        })
    }

    // console.log('Available printers: ', this.printers.value.length)

    return this.printers.value
  }

  /**
   * JS Print Manager WS status types and messages
   */
  private statusTypes = {
    connected: {
      id: 0,
      message: 'JS Print Manager 5 is up and running.',
      code: 'connected',
      notify: {
        position: 'bottom-right' as positionTypes,
        color: 'green',
        icon: 'fal fa-check-circle'
      }
    } as statusType,
    notInstalled: {
      id: 1,
      message:
        'JS Print Manager (JSPM) is not installed or not running! Please install JSPM to continue.',
      code: 'not installed',
      notify: {
        position: 'bottom-right' as positionTypes,
        color: 'warning',
        icon: 'fal fa-exclamation-triangle',
        actions: [
          {
            label: 'Retry',
            color: 'white',
            handler: () => {
              window.location.reload()
            }
          },
          {
            label: 'Download JSPM',
            color: 'white',
            handler: () => {
              window.open('https://neodynamic.com/downloads/jspm', '_blank')
            }
          },
          {
            label: 'Close',
            color: 'white',
            handler: () => null
          }
        ],
        timeout: 24000
      }
    } as statusType,
    blocked: {
      id: 2,
      message:
        'JS Print Manager (JSPM) is blocked! Please check for a JSPM notification / popup',
      code: 'blocked',
      notify: {
        position: 'bottom-right' as positionTypes,
        color: 'red',
        icon: 'fal fa-exclamation-triangle',
        actions: [
          {
            label: 'Open JSPM',
            color: 'white',
            handler: () => {
              window.open('http://localhost:8351', '_blank')
            }
          },
          {
            label: 'Close',
            color: 'white',
            handler: () => null
          }
        ],
        timeout: 24000
      }
    } as statusType,
    waiting: {
      id: 3,
      message:
        'JS Print Manager (JSPM) is waiting for user response! Please check for a JSPM notification / popup',
      code: 'waiting',
      notify: {
        position: 'bottom-right' as positionTypes,
        color: 'red',
        icon: 'fal fa-exclamation-triangle',
        actions: [
          {
            label: 'Open JSPM',
            color: 'white',
            handler: () => {
              window.open('http://localhost:8351', '_blank')
            }
          },
          {
            label: 'Close',
            color: 'white',
            handler: () => null
          }
        ],
        timeout: 24000
      }
    } as statusType,
    disconnected: {
      id: 4,
      message:
        'JS Print Manager (JSPM) is disconnected! Please check for a JSPM notification / popup',
      code: 'disconnected',
      notify: {
        position: 'bottom-right' as positionTypes,
        color: 'red',
        icon: 'fal fa-exclamation-triangle',
        actions: [
          {
            label: 'Open JSPM',
            color: 'white',
            handler: () => {
              window.open('http://localhost:8351', '_blank')
            }
          },
          {
            label: 'Close',
            color: 'white',
            handler: () => null
          }
        ],
        timeout: 24000
      }
    } as statusType
  }

  private statuses = {
    status: JSPM.JSPrintManager.websocket_status,
    open: JSPM.WSStatus.Open,
    closed: JSPM.WSStatus.Closed,
    blocked: JSPM.WSStatus.Blocked,
    waiting: JSPM.WSStatus.WaitingForUserResponse
  }

  /**
   *  Check JS Print Manager status
   *  @returns {id: number, message: string, code: string}
   *  @memberof CampfirePrinter
   */
  public async WSStatus() {
    const status = this.statuses.status
    const { open, closed, blocked, waiting } = this.statuses

    // Disconnected
    let currentStatus = this.statusTypes.disconnected

    // Connected
    if (status === open) currentStatus = this.statusTypes.connected

    // Not Connected
    if (status === closed) currentStatus = this.statusTypes.notInstalled

    // Blocked
    if (status === blocked) currentStatus = this.statusTypes.blocked

    // Waiting
    if (status === waiting) currentStatus = this.statusTypes.waiting

    return currentStatus
  }

  /**
   * Notify user
   * @param {string} message
   */
  public notify(payload: {
    message: string
    opts: {
      position: positionTypes
      color: string | undefined
      icon: string
      actions?: {
        label: string
        color: string
        handler: () => void
      }[]
      timeout?: number
    }
  }) {
    Notify.create({
      message: payload.message,
      position: payload.opts.position,
      color: payload.opts.color,
      icon: payload.opts.icon
    })
  }

  /**
   * Print file
   * @param {PrinterType} payload
   */
  public async printFile(payload: sendToPrinterType) {
    if (!this.jspm) {
      return
    }

    if (import.meta.client) {
      if (this.status.value !== 'connected') {
        this.notify({
          message: 'JS Print Manager is not connected. retrying...',
          opts: {
            position: 'bottom-right',
            color: 'warning',
            icon: 'fal fa-exclamation-triangle'
          }
        })

        this.checking.value = false
        await this.check()
      }

      this.store.printer.printing = true

      try {
        // Initiate the client print job
        const cpj = new this.jspm.ClientPrintJob()

        // Set printer to use
        cpj.clientPrinter = new this.jspm.InstalledPrinter(
          payload?.printer?.name || '',
          false, // Print to default if no printer is found
          '', // Tray name
          payload.paper_type ? payload.paper_type : 'A4' // Paper type
        )

        // Set PDF to print
        const file = new this.jspm.PrintFilePDF(
          payload.contents, // Base64 Contents of PDF
          payload.file_type, // Source Type (BLOB, Base64, Text, URL)
          payload.file_name, // File name
          payload.copies ? payload.copies : 1 // Copies
        )

        // Shrink to page
        let paperFit = 'None'
        if (payload.paper_fit) {
          paperFit = 'Fit'
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        file.pageSizing = paperFit

        // Auto Center
        file.printAutoCenter = payload.paper_autocenter
          ? payload.paper_autocenter
          : false

        // Auto Rotate
        file.printAutoRotate = payload.paper_autorotate
          ? payload.paper_autorotate
          : false

        // Print Grayscale
        file.printAsGrayscale = payload.paper_grayscale
          ? payload.paper_grayscale
          : false

        // Show dialog if in development mode
        if (process.env.DEV) {
          Dialog.create({
            title: 'Confirm',
            message: 'Are you sure you want to print this document?',
            ok: {
              color: 'primary',
              push: false,
              unelevated: true,
              label: 'Print Now'
            },
            cancel: {
              color: 'grey',
              push: false,
              unelevated: true,
              label: 'Output to console'
            },
            persistent: true
          }).onOk(() => {
            cpj.files.push(file)
            cpj.sendToClient()

            Notify.create({
              message: `${payload.file_name} has been sent to printer.`,
              caption: `Printer: ${payload.printer?.name}`,
              position: 'bottom-right',
              color: 'green',
              icon: 'fal fa-print'
            })
          })
        } else {
          // Send straight to printer
          cpj.files.push(file)
          cpj.sendToClient()

          Notify.create({
            message: `${payload.file_name} has been sent to printer.`,
            caption: `Printer: ${payload.printer?.name}`,
            position: 'bottom-right',
            color: 'green',
            icon: 'fal fa-print'
          })
        }
      } catch (error) {
        console.log(error)

        this.notify({
          message: 'An error occurred while printing.',
          opts: {
            position: 'bottom-right',
            color: 'red',
            icon: 'fal fa-exclamation-triangle'
          }
        })
      }

      this.printing.value = false
    }
  }

  public setDefaultConfigs(printerConfigs: PrinterType[]) {
    this.printerConfigs.value = printerConfigs
  }
}

type printStrategiesPayloadType = {
  contents: sendToPrinterType['contents']
  file_type: FileSourceType
  file_name: sendToPrinterType['file_name']
}

/**
 * Print Strategies
 * This class is used to print different types of documents to the printer specified in the config
 */
export class printStrategies extends CampfirePrinter {
  public async runPrintJob(
    printer_config: PrinterType['printer_config'],
    payload: printStrategiesPayloadType
  ): Promise<{
    success: boolean
    message: string
    log: string
  }> {
    console.log(printer_config, 'runnning print job')

    let status = {
      success: true,
      message: 'Runnning print job',
      log: ''
    }

    const defaultPrinters = this.printerConfigs.value
    const selectedPrinter = defaultPrinters.find(
      (printer) => printer.printer_config === printer_config
    )
    if (!payload.contents || !payload.file_type || !payload.file_name) {
      status = {
        success: false,
        message: 'An error occurred while printing. message: "Invalid payload"',
        log: `runPrintJob, ${printer_config}, \n${JSON.stringify(payload)}`
      }
    } else if (!selectedPrinter) {
      status = {
        success: false,
        message: 'An error occurred while printing. message: "Invalid printer"',
        log: `runPrintJob, ${printer_config}, \n${JSON.stringify(payload)}`
      }
    } else {
      const printingData = {
        contents: payload.contents,
        file_type: payload.file_type,
        file_name: payload.file_name,
        type: payload.file_type,
        printer_name: selectedPrinter.file_name,
        printer: selectedPrinter.printer,
        paper_size: selectedPrinter.paper_size,
        copies: selectedPrinter.copies,
        paper_type: selectedPrinter.paper_type,
        paper_fit: selectedPrinter.paper_fit,
        paper_autocenter: selectedPrinter.paper_autocenter,
        paper_autorotate: selectedPrinter.paper_autorotate,
        paper_grayscale: selectedPrinter.paper_grayscale
      } as sendToPrinterType

      await this.printFile(printingData)

      console.log(printer_config, 'print job done')

      status = {
        success: true,
        message: 'Print job sent successfully',
        log: `runPrintJob, ${printer_config}, \n${JSON.stringify(payload)}`
      }
    }

    this.notify({
      message: status.message,
      opts: {
        position: 'bottom-right',
        color: status.success ? 'green' : 'red',
        icon: status.success ? 'fal fa-print' : 'fal fa-exclamation-triangle'
      }
    })

    return status
  }

  // Delivery Note
  public async deliveryNote(payload: printStrategiesPayloadType) {
    const status = await this.runPrintJob('delivery_notes', payload)

    return status
  }

  // carrier_labels
  public async carrierLabels(payload: printStrategiesPayloadType) {
    const status = await this.runPrintJob('carrier_labels', payload)

    return status
  }

  // Component Labels
  public async componentLabels(payload: printStrategiesPayloadType) {
    const status = await this.runPrintJob('component_labels', payload)

    return status
  }

  // Collection Labels
  public async collectionLabels(payload: printStrategiesPayloadType) {
    const status = await this.runPrintJob('collection_labels', payload)

    return status
  }

  // receipts
  public async receipts(payload: printStrategiesPayloadType) {
    const status = await this.runPrintJob('receipts', payload)

    return status
  }

  //tax invoices
  public async taxInvoices(payload: printStrategiesPayloadType) {
    const status = await this.runPrintJob('tax_invoices', payload)

    return status
  }

  //quotes
  public async quotes(payload: printStrategiesPayloadType) {
    const status = await this.runPrintJob('quotes', payload)

    return status
  }
}

export type CampfirePrinterType = CampfirePrinter
