import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the sales - affiliates page.
 */
const salesAffiliatesMeta: RouteMeta = {
  name: 'sales.affiliates',
  title: 'Affiliates',
  path: '/sales/affiliates',
  order: 1,
  show: true,
  requiresAuth: true,
  permissions: ['sales affiliates | view'],
  tabs: [
    {
      value: 'accounts',
      label: 'Accounts',
      permissions: ['sales affiliates accounts | view']
    },
    {
      value: 'payments',
      label: 'Payments',
      permissions: ['sales affiliates payments | view']
    },
    {
      value: 'withdrawals',
      label: 'Withdrawals',
      permissions: ['sales affiliates withdrawals | view']
    },
    {
      value: 'transactions',
      label: 'Transactions',
      permissions: ['sales affiliates transactions | view']
    }
  ]
}

export default salesAffiliatesMeta
