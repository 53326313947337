import type * as WT from './warehouse.types'

export async function getStockLocations(
  stockCode: string
): Promise<WT.StockLocation[] | null> {
  return await useWretch('frSysproAPI')
    .get(`/warehouse/api/Locate/StockCode/${stockCode}`)
    .json<WT.StockLocation[]>()
    .then((data) => data)
    .catch((err) => {
      console.error('Error in getStockLocations:', err)
      return null
    })
}
