import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the b2b customers page.
 */
const b2bCustomersMeta: RouteMeta = {
  name: 'customers.b2b',
  title: 'B2B Customers',
  path: '/customers/b2b',
  description: {
    name: 'description',
    content: 'Customers - B2B Customers'
  },
  showOnNav: true,
  order: 0,
  requiresAuth: true,
  show: true,
  permissions: ['customers b2b customers | view']
}

export default b2bCustomersMeta
