/**
 * ================================================
 * Sales Invoices API
 * This factory contains all the methods for interacting with the sales invoices feature.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import {
  type QueryFunctionContext,
  type UseQueryOptions,
  useQuery
} from '@tanstack/vue-query'
import qs from 'qs'
import { exportFile } from 'quasar'

/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'

/**
 * ================================================
 * API Routes
 * ================================================
 */
const API_PREFIX = '/api/v2/sales/invoices'

/**
 * Get Orders - call | Retrieves invoices from the API based on the provided payload.
 * @param payload - An object containing pagination and region filters.
 * @param payload.pagination - An object containing pagination details such as page number, sorting, and filtering.
 * @param payload.pagination.page - The current page number.
 * @param payload.pagination.sortBy - The field to sort by.
 * @param payload.pagination.descending - Whether to sort in descending order.
 * @param payload.pagination.rowsPerPage - The number of rows per page.
 * @param payload.pagination.filter - The filter to apply.
 * @param payload.pagination.rowsNumber - The total number of rows.
 * @param payload.regions - The regions to filter by.
 * @returns A promise that resolves to an invoice response object.
 */
export const getInvoices = async (
  context: QueryFunctionContext
): Promise<T.invoiceResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_PREFIX}?${context.queryKey[2]}`)
    .query(
      qs.stringify(
        {
          ...(context.queryKey[1] as {
            page: number
            sortBy: string
            descending: boolean
            rowsPerPage: number
            filter?: string | null
            rowsNumber: number
          })
        },
        { encode: false, skipNulls: true }
      )
    )
    .get()
    .json<T.invoiceResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Get Orders - Query | Fetches invoices data from the API based on the provided pagination and regions.
 * @param payload An object containing pagination, regions, and options.
 * @param payload.pagination A ComputedRef object containing pagination information.
 * @param payload.pagination.page The current page number.
 * @param payload.pagination.sortBy The field to sort by.
 * @param payload.pagination.descending Whether to sort in descending order.
 * @param payload.pagination.rowsPerPage The number of rows per page.
 * @param payload.pagination.filter The filter string to apply.
 * @param payload.pagination.rowsNumber The total number of rows.
 * @param payload.regions A ComputedRef object containing the regions to fetch data for.
 * @param payload.options An object containing options for the useQuery hook.
 * @returns A useQuery hook result containing the fetched invoices data.
 */
const useGetInvoices = (payload: {
  pagination: ComputedRef<{
    page: number
    sortBy: string
    descending: boolean
    rowsPerPage: number
    filter?: string | null
    rowsNumber: number
  }>
  regions: ComputedRef<string>
  options: UseQueryOptions<T.invoiceResponse>
}) =>
  useQuery({
    queryKey: ['invoices', payload.pagination, payload.regions],
    queryFn: getInvoices,
    ...payload.options
  })

/**
 * getPdfInvoices - call | Retrieves a pdf invoice from the API based on the provided invoiceId.
 */
export const getPdfInvoices = async (context: QueryFunctionContext) =>
  await useWretch('FsyncAPI')
    .url(`${API_PREFIX}/download-pdf/${context.queryKey[1]}`)
    .get()
    .json<{ pdf: string }>()
    .then(({ pdf }) => {
      const fileName = `Invoice ${context.queryKey[2]}.pdf`
      const byteCharacters = atob(pdf)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], {
        type: undefined
      })

      // Download Excel
      exportFile(fileName, blob)
    })

/**
 * getPdfInvoices - Query | Retrieves a pdf invoice from the API based on the provided invoiceId.
 */
const useGetPdfInvoices = (payload: {
  invoiceId: ComputedRef<number>
  incrementId: ComputedRef<string>
  options: UseQueryOptions<T.getPdfInvoicesResponse>
}) =>
  useQuery({
    queryKey: ['invoices', payload.invoiceId, payload.incrementId],
    queryFn: getPdfInvoices,
    ...payload.options
  })

/**
 * exportInvoices - call | Retrieves a pdf invoice from the API based on the provided invoiceId.
 */
export const exportInvoices = async (payload: {
  pagination: {
    page: number
    rowsPerPage: number
    rowsNumber: number
    filter?: string | null
    descending: boolean
    sortBy: string
  }
  invoices: {
    entity_id: number
  }[]
  regions: string
}) => {
  const { invoices = [] } = payload
  const { ...params } = payload.pagination
  const invoicesData = invoices.map(({ entity_id: id }) => `invoices[]=${id}`)

  const query = [...invoicesData, ...payload.regions, 'export=1'].join('&')

  try {
    const data = await useWretch('FsyncAPI')
      .get(`${API_PREFIX}?${query}&${qs.stringify(params)}`)
      .json<{
        contents: string
        file_name: string
      }>()

    const fileName = data.file_name
    const byteCharacters = atob(data.contents)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], {
      type: undefined
    })

    // Download Excel
    exportFile(fileName, blob)
  } catch (err) {
    console.error(err)
  }
}

/**
 * exportInvoices - Query | Retrieves a pdf invoice from the API based on the provided invoiceId.
 */
const useExportInvoices = (payload: {
  pagination: ComputedRef<{
    page: number
    sortBy: string
    descending: boolean
    rowsPerPage: number
    filter?: string | null
    rowsNumber: number
  }>
  regions: ComputedRef<string>
  options: UseQueryOptions
}) =>
  useQuery({
    queryKey: ['invoices', payload.pagination, payload.regions],
    queryFn: () =>
      exportInvoices({
        pagination: payload.pagination.value,
        invoices: [],
        regions: payload.regions.value
      }),
    enabled: false,
    ...payload.options
  })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetInvoices,
  useGetPdfInvoices,
  useExportInvoices
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const salesInvoicesApiFactory = {
  queries,
  mutations
}
