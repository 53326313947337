import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the human resources vacancies page
 */
const HumanResourcesVacanciesPageMeta: RouteMeta = {
  name: 'human-resources.vacancies',
  title: 'Vacancies',
  path: '/human-resources/vacancies',
  description: {
    name: 'description',
    content: 'Vacancies'
  },
  showOnNav: true,
  order: 1,
  show: true,
  requiresAuth: true,
  permissions: ['human resources vacancies | view']
}

export default HumanResourcesVacanciesPageMeta
