import { document } from './document.factory'
import { employee } from './employee.factory'
import { goals } from './goals.factory'
import { kpiSets } from './kpi-sets.factory'
import { performanceReview as performanceReviewFactory } from './performance-review.factory'

export const performanceReview = {
  document,
  employee,
  goals,
  performanceReview: performanceReviewFactory,
  kpiSets
}
