/**
 * ================================================
 * Warehouse Picking Details API Factory
 * This factory contains all the methods for interacting with the warehouse picking details feature.
 * ================================================
 */

/**
 * ================================================
 * NOTES:
 * getPickingDetails() can be written better.
 *
 * Previously used order numbers to test issues:
 * 4000059324SO shipped
 * SOZ00066942 Odd one as no keys
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import { type UseMutationOptions, useMutation } from '@tanstack/vue-query'

/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'
import { version } from '~/package.json'

/**
 * getPickingDetails - Call
 *
 * Retrieves picking details from the warehouse API.
 * @param payload - Optional payload for the request.
 * @returns A promise that resolves to an array of order types.
 */
export const getPickingDetails = async (
  payload?: T.getPickingDetailsRequest
): Promise<T.orderType[]> =>
  await useWretch('FsyncAPI')
    .post(
      JSON.stringify({
        ...payload,
        app_version: `Campfire ${version}`,
        simulate: true
      }),
      '/api/v2/warehouse/picking'
    )
    .json<
      | T.getPickingDetailsResponse[]
      | {
          error: boolean
          message: string
        }
    >()
    .then((data) => {
      // @ts-expect-error - data is not defined
      if (data.error) notifier({ message: data.message, type: 'error' })

      if (Array.isArray(data)) return data

      const pickingDetails = []

      if (Object.keys(data).find((k) => k.search('FIN_') !== -1)) {
        Object.keys(data).forEach((k) => {
          // @ts-expect-error the data can come back with a warehouse name as the key
          pickingDetails.push(data[k])
        })
      } else {
        const orderData = data as unknown as T.orderType

        if (orderData.id) {
          pickingDetails.push(orderData)
        }
      }
      return pickingDetails
    })
    .catch((err) => err)

/**
 * getPickingDetails - Mutation
 */
const useGetPickingDetails = (
  options?: UseMutationOptions<
    T.orderType[],
    Error,
    T.getPickingDetailsRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: getPickingDetails,
    ...options
  })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  useGetPickingDetails
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const pickingDetailsApiFactory = {
  queries,
  mutations
}
