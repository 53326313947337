import type * as KST from './kpi-sets.types'
import { type PerformanceReviewTypeValues } from './performance-review.types'

async function getKpiSetsForUser(
  campfireUserId: string
): Promise<KST.KpiSet[]> {
  return await useWretch('FrAPI')
    .get(`/performance_review/KpiSets/User/${campfireUserId}`)
    .json<KST.KpiSet[]>()
    .then((res) => {
      return res
    })
    .catch((err) => {
      console.error('Error in getKpiSets:', err)
      return []
    })
}

async function getKpiSetsForReviewType(
  performanceReviewType: PerformanceReviewTypeValues
): Promise<KST.KpiSet[]> {
  return await useWretch('FrAPI')
    .get(`/performance_review/KpiSets/Type/${performanceReviewType}`)
    .json<KST.KpiSet[]>()
    .then((res) => {
      return res
    })
    .catch((err) => {
      console.error('Error in getKpiSets:', err)
      return []
    })
}

export const kpiSets = {
  getKpiSetsForUser,
  getKpiSetsForReviewType
}
