import useAuthStore from '~/features/auth/store'
import factories from '~/factories'
import { useServiceDeskStore } from '~/features/service-desk/store'

export default defineNuxtRouteMiddleware(async (to) => {
  const authStore = useAuthStore()
  const serviceDesk = useServiceDeskStore()

  const user = useUser()

  const wrikeUser = computed({
    get: () => serviceDesk.wrikeUser,
    set: (value) => (serviceDesk.wrikeUser = value)
  })

  if (to.meta.requiresAuth) {
    if (!user.value?.id) authStore.showLoginModal = true
  }

  const { data: me } = factories.auth.queries.useFetchMe()

  /**
   * =========================
   * Fetch wrike user if not already fetched and user is verified
   */
  const verifiedMe = computed(() => !!me.value?.user?.id)

  const { data: wrikeUserData } = factories.serviceDesk.queries.useGetWrikeUser(
    {
      enabled: wrikeUser.value === null && verifiedMe
    }
  )

  if (wrikeUserData.value) {
    wrikeUser.value = wrikeUserData.value.wrikeUser
  }
  /**
   * End fetch wrike user
   * =========================
   */
})
