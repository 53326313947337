import {
  type pageType
  // sliderCategoriesType
} from '~/features/content/landing-pages/api/types'

type initialStateType_content_landingPages = {
  page: pageType | null
  // Ids
  sectionId: number | null
  blockId: number | null
  sectionBlockId: number | null

  // Language
  language: { value: string; label: string } | null

  // Sliders
  // sliderCategory: sliderCategoriesType

  // Page builder
  // pageBuildingItems: {
  //   id: number
  //   name: string
  //   label: string
  //   icon: string
  //   color: string
  // sectiontype: sectionType
  // }[]
}

export const initialState_content_landingPages: initialStateType_content_landingPages =
  {
    page: null,
    sectionId: null,
    blockId: null,
    sectionBlockId: null,
    language: {
      value: 'en',
      label: 'English (USA - Default)'
    }
    // block: null,
    // sliderPages: [],
    // sections: [],

    // pageBuildingItems: [
    //   {
    //     id: 1,
    //     name: 'Slider',
    //     label: 'Slider',
    //     icon: 'fas fa-sliders-h',
    //     color: 'primary'
    //   },
    //   {
    //     id: 2,
    //     name: 'Image',
    //     label: 'Image',
    //     icon: 'fas fa-image',
    //     color: 'secondary'
    //   },
    //   {
    //     id: 3,
    //     name: 'Product',
    //     label: 'Product',
    //     icon: 'fas fa-box-open',
    //     color: 'green'
    //   },
    //   {
    //     id: 4,
    //     name: 'Image Grid',
    //     label: 'Image Grid',
    //     icon: 'fas fa-th',
    //     color: 'orange'
    //   },
    //   {
    //     id: 5,
    //     name: 'Block HTML',
    //     label: 'Block HTML',
    //     icon: 'fas fa-code',
    //     color: 'purple'
    //   },
    //   {
    //     id: 6,
    //     name: 'Design Engineering',
    //     label: 'Design Engineering',
    //     icon: 'fas fa-cogs',
    //     color: 'red'
    //   },
    //   {
    //     id: 7,
    //     name: 'Landing Pages Slider',
    //     label: 'Landing Pages Slider',
    //     icon: 'fas fa-sliders-h',
    //     color: 'primary'
    //   },
    //   {
    //     id: 8,
    //     name: 'Outfit Your Vehicle',
    //     label: 'Outfit Your Vehicle',
    //     icon: 'fas fa-car',
    //     color: 'primary'
    //   }
    // ],
  }
