import type { RouteMeta } from 'vue-router'
import { useSysproStore } from '~/features/syspro/store/syspro-store'

/**
 * Represents the metadata for the Warehouse - Movements - Immediate Transfer page
 */
const warehouseMovementsImmediateTransferMeta: RouteMeta = {
  name: 'warehouse.movements.immediate-transfer',
  title: 'Immediate Transfer',
  path: '/warehouse/movements/immediate-transfer',
  order: 2,
  requiresAuth: true,
  keepAlive: false,
  show: true,
  permissions: ['warehouse movements immediate transfer | view'],
  beforeEnter: () => {
    useSysproStore()
  },
  showOnNav: true,
  description: {
    name: 'description',
    content: 'Warehouse - Movements - Immediate Transfer'
  }
}

export default warehouseMovementsImmediateTransferMeta
