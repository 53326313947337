import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the sales - Sales Graph page
 */
const salesSalesGraphMeta: RouteMeta = {
  name: 'sales.graphs',
  title: 'Sales Graph',
  path: '/sales/sales-graphs',
  order: 12,
  requiresAuth: true,
  show: true,
  permissions: ['sales sales graph | view']
}

export default salesSalesGraphMeta
