import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the documents proof of delivery page.
 */
const proofOfDeliveryMeta: RouteMeta = {
  name: 'documents.proof-of-delivery',
  title: 'Proof of Delivery',
  path: '/documents/proof-of-delivery',
  description: {
    name: 'description',
    content: 'Document - Proof of Delivery'
  },
  showOnNav: true,
  order: 1,
  requiresAuth: true,
  show: true,
  permissions: ['documents proof of delivery | view']
}

export default proofOfDeliveryMeta
