import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the events EU subscribers form page.
 */
const euEventsSubscribersFormMeta: RouteMeta = {
  name: 'events.EUsubscribers.form',
  path: '/events/EUSubscribers/form',
  title: 'Form',
  description: {
    name: 'description',
    content: 'Events - EU Subscribers Form'
  },
  showOnNav: false,
  order: 1,
  requiresAuth: false,
  show: true,
  permissions: []
}

export default euEventsSubscribersFormMeta
