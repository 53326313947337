import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Outfit By Vehicle page.
 */
const outfitByVehiclePageMeta: RouteMeta = {
  name: 'vfits.outfit-by-vehicle',
  path: '/vfits/outfit-by-vehicle',
  title: 'Outfit By Vehicle',
  description: {
    name: 'description',
    content: 'Outfit By Vehicle Page'
  },
  order: 0,
  requiresAuth: true,
  show: true,
  permissions: ['vfits outfit by vehicle | view']
}

export default outfitByVehiclePageMeta
