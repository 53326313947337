/**
 * ================================================
 * Content - Gearzette - API Factory
 * This factory contains all the methods for interacting with the content gearzette feature.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import {
  type QueryFunctionContext,
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import qs from 'qs'
import { type WritableComputedRef } from 'vue'
/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'

/**
 * ================================================
 * API ROUTES
 * ================================================
 */
const API_URL = '/api/v2/content/gearzette'

/**
 * ======================================
 * CATEGORIES
 * ======================================
 */

/**
 * Get categories - Call
 */
export const getCategories = async (
  context: QueryFunctionContext
): Promise<T.getCategoriesResponse> =>
  await useWretch('FsyncAPI')
    .query(
      qs.stringify(context.queryKey[1], {
        skipNulls: true
      })
    )
    .get(`${API_URL}/categories`)
    .json<T.getCategoriesResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Get categories - query
 */

const useGetCategories = (payload: {
  pagination: ComputedRef<T.paginationRequestType>
  options?: UseQueryOptions<T.getCategoriesResponse>
}) =>
  useQuery({
    queryKey: ['categories', payload.pagination],
    queryFn: getCategories,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    ...payload.options
  })

/**
 * Get category - Call
 */
export const getCategory = async (
  context: QueryFunctionContext
): Promise<T.categoryType> =>
  await useWretch('FsyncAPI')
    .get(`${API_URL}/categories/${context.queryKey[1]}`)
    .json<T.categoryType>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Get category - query
 */
const useGetCategory = (payload: {
  categoryId: ComputedRef<number>
  options?: UseQueryOptions<T.categoryType>
}) =>
  useQuery({
    queryKey: ['category', payload.categoryId],
    queryFn: getCategory,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    ...payload.options
  })

/**
 * Create category - Call
 */
export const createCategory = async (
  payload: T.createCategoryRequest
): Promise<T.createCategoryResponse> =>
  await useWretch('FsyncAPI')
    .post(JSON.stringify(payload), `${API_URL}/categories`)
    .json<T.categoryType>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Create category - mutation
 */
const useCreateCategory = (
  options?: UseMutationOptions<
    T.createCategoryResponse,
    Error,
    T.createCategoryRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: createCategory,
    ...options
  })

/**
 * Update category - Call
 */
export const updateCategory = async (
  payload: T.updateCategoryRequest
): Promise<T.updateCategoryResponse> =>
  await useWretch('FsyncAPI')
    .put(JSON.stringify(payload), `${API_URL}/categories/${payload.id}`)
    .json<T.updateCategoryResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Update category - mutation
 */
const useUpdateCategory = (
  options?: UseMutationOptions<
    T.updateCategoryResponse,
    Error,
    T.updateCategoryRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: updateCategory,
    ...options
  })

/**
 * Delete category - Call
 */
export const deleteCategory = async (
  payload: T.deleteCategoryRequest
): Promise<T.deleteCategoryResponse> =>
  await useWretch('FsyncAPI')
    .delete(`${API_URL}/categories/${payload.id}`)
    .json<T.deleteCategoryResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Delete category - mutation
 */
const useDeleteCategory = (
  options?: UseMutationOptions<
    T.deleteCategoryResponse,
    Error,
    T.deleteCategoryRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: deleteCategory,
    ...options
  })

/**
 * ======================================
 * STORES
 * ======================================
 */

/**
 * Get Stores - Call
 */
export const getStores = async (): Promise<T.storeType[]> =>
  await useWretch('FsyncAPI')
    .get(`${API_URL}/stores`)
    .json<T.storeType[]>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Get Stores - query
 */
const useGetStores = (options?: UseQueryOptions<T.storeType[]>) =>
  useQuery({
    queryKey: ['stores'],
    queryFn: getStores,
    ...options,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  })

/**
 * ======================================
 * POSTS
 * ======================================
 */

/**
 * Get Posts - Call
 */
export const getPosts = async (
  context: QueryFunctionContext
): Promise<T.getPostsResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_URL}/categories/${context.queryKey[1]}/posts`)
    .query(
      qs.stringify(
        {
          ...(context.queryKey[2] ?? {})
        },
        { encode: false, skipNulls: true }
      )
    )
    .get()
    .json<T.getPostsResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Get Posts - query
 */
const useGetPosts = (payload: {
  categoryId: ComputedRef<number>
  pagination: WritableComputedRef<T.paginationRequestType>
  options?: UseQueryOptions<T.getPostsResponse>
}) =>
  useQuery({
    queryKey: ['posts', payload.categoryId, payload.pagination],
    queryFn: getPosts,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    ...payload.options
  })

/**
 * Get Post - Call
 */
export const getPost = async (
  context: QueryFunctionContext
): Promise<T.postType> =>
  await useWretch('FsyncAPI')
    .url(
      `${API_URL}/categories/${context.queryKey[1]}/posts/${context.queryKey[2]}`
    )
    .query(qs.stringify({ locale: context.queryKey[3] }))
    .get()
    .json<T.postType>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Get Post - query
 */
const useGetPost = (payload: {
  categoryId: ComputedRef<number>
  postId: ComputedRef<number>
  locale: ComputedRef<string>
  options?: UseQueryOptions<T.postType>
}) =>
  useQuery({
    queryKey: ['post', payload.categoryId, payload.postId, payload.locale],
    queryFn: getPost,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    ...(payload?.options || {})
  })

/**
 * Create post - mutation
 */
const useCreatePost = (
  options?: UseMutationOptions<
    T.createPostResponse,
    Error,
    T.createPostRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: async (payload) =>
      await useWretch('FsyncAPI')
        .query({ locale: payload.locale })
        .json(payload)
        .url(`${API_URL}/categories/${payload.categoryId}/posts`)
        .post()
        .json<T.createPostResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...(options || {})
  })

/**
 * Update post - mutation
 */
const useUpdatePost = (
  options?: UseMutationOptions<
    T.updatePostResponse,
    Error,
    T.updatePostRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: async (payload) =>
      await useWretch('FsyncAPI')
        .url(`${API_URL}/categories/${payload.categoryId}/posts/${payload.id}`)
        .query({ locale: payload.locale })
        .json(payload)
        .put()
        .json<T.updatePostResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...(options || {})
  })

/**
 * Delete post - Call
 */
export const deletePost = async (
  payload: T.deletePostRequest
): Promise<T.deletePostResponse> =>
  await useWretch('FsyncAPI')
    .delete(
      `${API_URL}/categories/${payload.categoryId}/posts/${payload.postId}`
    )
    .json<T.deletePostResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Delete post - mutation
 */
const useDeletePost = (
  options?: UseMutationOptions<
    T.deletePostResponse,
    Error,
    T.deletePostRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: deletePost,
    ...options
  })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  /**
   * Categories
   */
  useGetCategories,
  useGetCategory,
  /**
   * Stores
   */
  useGetStores,

  /**
   * Posts
   */
  useGetPosts,
  useGetPost
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  /**
   * Categories
   */
  useCreateCategory,
  useUpdateCategory,
  useDeleteCategory,
  /**
   * Posts
   */
  useCreatePost,
  useUpdatePost,
  useDeletePost
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const contentGearzetteApiFactory = {
  queries,
  mutations
}
