export enum DocumentLanguage {
  english = 'EN',
  german = 'DE'
}

export enum GeneratorDocumentType {
  commercialWarrantyInvoice = 'CommercialWarrantyInvoice',
  deliveryNote = 'DeliveryNote',
  proFormaInvoice = 'ProFormaInvoice',
  quote = 'Quote',
  receipt = 'Receipt',
  taxInvoice = 'TaxInvoice',
  commercialInvoice = 'CommercialInvoice',
  commercialInvoiceWithPackingList = 'CommercialInvoiceWithPackingList',
  exportInvoice = 'ExportInvoice',
  commercialInvoiceByLocation = 'CommercialInvoiceByLocation',
  creditNote = 'CreditNote',
  purchaseOrder = 'PurchaseOrder',
  salesOrder = 'SalesOrder',
  proofOfDelivery = 'POD'
}

export function isValidGeneratorDocumentType(
  documentType: string
): documentType is GeneratorDocumentType {
  return Object.values(GeneratorDocumentType).includes(
    documentType as GeneratorDocumentType
  )
}

export interface Base64DocumentGeneratorQuery {
  wrapDataUri?: boolean
  indexOnly?: boolean
  original?: boolean
}

export interface BlobDocumentGeneratorQuery {
  download?: boolean
  original?: boolean
}

export interface DocumentEmailRequest {
  documentId: string
  documentType: GeneratorDocumentType
  language: DocumentLanguage
  fromEmail: string
  toEmail: string
  ccEmail: string
  subject: string
  body: string
  saveACopy: boolean
}
