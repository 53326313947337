import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Warehouse - Logistics Containers page
 */
const warehouseLogisticsContainersMeta: RouteMeta = {
  name: 'warehouse.logistics-containers',
  title: 'Logistics Containers',
  path: '/warehouse/logistics-containers',
  order: 3,
  requiresAuth: true,
  keepalive: false,
  show: true,
  permissions: ['warehouse logistics containers | view'],
  showOnNav: true,
  description: {
    name: 'description',
    content: 'Warehouse - Logistics Containers'
  }
}

export default warehouseLogisticsContainersMeta
