import type * as T from '~/factories/api/azure.api.types'
import type * as PT from './payment.types'

export async function createPayments(
  region: T.region,
  customerNumber: string,
  documentNumber: string,
  payments: PT.Payment[]
): Promise<PT.CreatePaymentsResponse[] | null> {
  return await useWretch('frSysproAPI')
    .post(
      JSON.stringify(payments),
      `/order/api/Payment/${region}/Pay/${customerNumber}/${documentNumber}`
    )
    .json<PT.CreatePaymentsResponse[]>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in createPayments:', err)
      return null
    })
}
