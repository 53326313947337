/**
 * ================================================
 * Customers Price List API Factory
 * This factory contains all the methods for interacting with the customers price list feature.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import {
  type QueryFunctionContext,
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery
} from '@tanstack/vue-query'

/**
 * ================================================
 * Custom packages
 * ================================================
 */
import type * as T from '../../api/types'

/**
 * API Routes
 */
const API_PREFIX = '/api/v2'
// const CUSTOMER_API = '/customer/Customer'
const CUSTOMER_API_PRICE_LIST = '/customer/Pricelist'

/**
 * getPriceList - Call | Retrieves the price list for a given company code.
 * @param payload - The request payload containing the company code.
 * @returns A promise that resolves to the price list response or rejects with an error.
 * @throws An error if the company code is not provided or is not at least 3 characters long.
 */
export const getPriceList = async (
  context: QueryFunctionContext
): Promise<T.getPriceListResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_PREFIX}/customers/price-list/${context.queryKey[1]}`)
    .get()
    .json<T.getPriceListResponse>()
    .then((res) => res)
    .catch((err) => {
      notifier({
        message: err,
        type: 'error'
      })
      return err
    })

/**
 * getPriceList - Query
 */
const useGetPriceList = (payload: {
  companyCode: ComputedRef<string>
  options?: UseQueryOptions<T.getPriceListResponse, Error>
}) =>
  useQuery({
    queryKey: ['getPriceList', payload.companyCode],
    queryFn: getPriceList,
    refetchOnWindowFocus: false,
    ...payload.options
  })

/**
 * postPriceListItem - Call | POST a new price list item for a customer in the specified region.
 * @param payload - The data for the new price list item.
 * @param region - The region where the customer is located. Defaults to 'ALL'.
 * @returns A Promise that resolves to the new price list item data if successful, or rejects with an error if unsuccessful.
 */
export const postPriceListItem = async ({
  payload,
  region = 'ALL'
}: T.postPriceListItemRequest): Promise<T.postPriceListItemResponse> =>
  await useWretch('FrAPI')
    .url(`${CUSTOMER_API_PRICE_LIST}/custom/${region}`)
    .json(payload)
    .post()
    .res()
    .then((res) => res)
    .catch((err) => err)

/**
 * postPriceListItem - Mutation | A custom hook that returns a function to post a new price list item to the server.
 * @param options - Optional configurations for the mutation.
 * @returns A function to post a new price list item to the server.
 */
const usePostPriceListItem = (
  options?: UseMutationOptions<
    T.postPriceListItemResponse,
    Error,
    T.postPriceListItemRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: postPriceListItem,
    ...options
  })

/**
 * deletePriceListItem - Call | Deletes a price list item for a given customer and stock code in a specified region.
 * @param {Object} request - The request object.
 * @param {string} request.customer - The customer ID.
 * @param {string} request.stockCode - The stock code.
 * @param {string} [request.region='ALL'] - The region to delete the price list item from. Defaults to 'ALL'.
 * @returns {Promise<void>} - A Promise that resolves with no value on success, or rejects with an error on failure.
 */
export const deletePriceListItem = async ({
  customer,
  stockCode,
  region = 'ALL'
}: T.deletePriceListItemRequest): Promise<void> =>
  await useWretch('FrAPI')
    .url(`${CUSTOMER_API_PRICE_LIST}/custom/${region}`)
    .body(
      JSON.stringify({
        customer,
        stockCode
      })
    )
    .delete()
    .json<T.deletePriceListItemResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * deletePriceListItem - Mutation | A hook that returns a function to delete a price list item.
 * @param payload An object containing the parameters for the deletePriceListItem function.
 * @param payload.companyCode The company code for the price list item.
 * @param payload.sku The SKU for the price list item.
 * @param payload.region The region for the price list item.
 * @param payload.index The index of the price list item.
 * @param payload.options The options for the useMutation hook.
 * @returns A function to delete a price list item.
 */
const useDeletePriceListItem = (payload: {
  companyCode?: string
  sku?: string
  region?: string
  index?: number
  options?: UseMutationOptions<
    T.deletePriceListItemResponse,
    Error,
    T.deletePriceListItemRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: deletePriceListItem,
    ...payload.options
  })

/**
 * copyPriceList - Call | Copies a price list from one account to another.
 * @param {Object} request - The request object.
 * @param {string} request.fromAccount - The company code of the account to copy from.
 * @param {string} request.toAccount - The company code of the account to copy to.
 * @returns {Promise<Object>} - A promise that resolves to an object containing the `fromAccount` and `toAccount` properties.
 * @throws {Error} - If either `fromAccount` or `toAccount` is an empty string.
 */
export const copyPriceList = async ({
  fromAccount,
  toAccount
}: T.copyPriceListRequest): Promise<T.copyPriceListResponse> => {
  if (fromAccount === '')
    throw new Error('From Account company code is required')
  if (toAccount === '') throw new Error('To Account company code is required')

  return await useWretch('FsyncAPI')
    .url(`${API_PREFIX}/customers/price-list/${fromAccount}/copy`)
    .json({
      fromAccount,
      toAccount
    })
    .post()
    .json<T.copyPriceListResponse>()
    .then(() => ({
      fromAccount,
      toAccount
    }))
    .catch((err) => err)
}

/**
 * copyPriceList - Mutation | Custom hook that returns a function to copy a price list from one account to another.
 * @param payload - An object containing the `fromAccount`, `toAccount`, and `options` properties.
 * @returns A function that can be used to copy a price list.
 */
const useCopyPriceList = (payload: {
  fromAccount?: string
  toAccount?: string
  options?: UseMutationOptions<
    T.copyPriceListResponse,
    Error,
    T.copyPriceListRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: copyPriceList,
    ...payload.options
  })

/**
 * emptyPriceList - Call | Deletes all prices for a given customer and region.
 * @param {Object} options - The options object.
 * @param {string} options.customer - The customer ID.
 * @param {string} [options.region='ALL'] - The region to delete prices for.
 * @returns {Promise<Object>} A Promise that resolves to the response object or rejects with an error.
 * @throws {Error} If the request fails.
 * @example
 * const response = await emptyPriceList({ customer: '123', region: 'US' });
 */
export const emptyPriceList = async ({
  customer,
  region = 'ALL'
}: T.emptyPriceListRequest): Promise<T.emptyPriceListResponse> =>
  await useWretch('FrAPI')
    .url(`${CUSTOMER_API_PRICE_LIST}/custom/${region}/${customer}`)
    .delete()
    .json<T.emptyPriceListResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * @description emptyPriceList - Mutation | A custom hook that returns a mutation function to empty the price list for a given company.
 * @param payload An object containing the company code and optional mutation options.
 * @param payload.companyCode The code of the company whose price list needs to be emptied.
 * @param payload.options Optional mutation options.
 * @returns A mutation function to empty the price list.
 */
const useEmptyPriceList = (payload: {
  companyCode: string
  options?: UseMutationOptions<
    T.emptyPriceListResponse,
    Error,
    T.emptyPriceListRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: emptyPriceList,
    ...payload.options
  })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetPriceList
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  useDeletePriceListItem,
  usePostPriceListItem,
  useCopyPriceList,
  useEmptyPriceList
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const customersPriceListApiFactory = {
  queries,
  mutations
}
