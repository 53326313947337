/**
 * ================================================
 * Human Resources - Vacancies API
 * This factory contains all the methods for interacting with the vacancies API
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import {
  type QueryFunctionContext,
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import { type WritableComputedRef } from 'vue'

/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'

/**
 * ================================================
 * API Routes
 * ================================================
 */

/**
 * Get all vacancies - Call
 */
const getAllVacancies = async (
  context: QueryFunctionContext
): Promise<T.GetAllVacanciesResponse> =>
  await useWretch('FsyncAPI')
    .url(`/api/v2/vacancies?${context.queryKey[2]}`)
    .query({
      ...(context.queryKey[1] ?? {})
    })
    .get()
    .json<T.GetAllVacanciesResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Get all vacancies - Query
 */
const useGetAllVacancies = (payload: {
  regions: WritableComputedRef<string>
  pagination: WritableComputedRef<{
    page: number
    rowsPerPage: number
    rowsNumber: number
    sortBy: string
    descending: boolean
    filter: string | null
  }>
  options?: UseQueryOptions<T.GetAllVacanciesResponse>
}) =>
  useQuery<T.GetAllVacanciesResponse>({
    queryKey: ['vacancies', payload.pagination, payload.regions],
    queryFn: getAllVacancies,
    ...payload.options
  })

/**
 * Update vacancy - Call
 */
const updateVacancy = async (payload: T.UpdateVacancyRequest) =>
  await useWretch('FsyncAPI')
    .url(`/api/v2/vacancies/${payload.vacancy.id}?${payload.regions}`)
    .json({
      ...payload.vacancy,
      type: payload.vacancy?.type || 0
    })
    .put()
    .json<T.UpdateVacancyResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Update vacancy - Mutation
 */
const useUpdateVacancy = (
  options?: UseMutationOptions<
    T.UpdateVacancyResponse,
    unknown,
    T.UpdateVacancyRequest
  >
) =>
  useMutation({
    mutationFn: updateVacancy,
    ...options
  })

/**
 * Add vacancy - Call
 */
const addVacancy = async (payload: T.AddVacancyRequest) =>
  await useWretch('FsyncAPI')
    .url(`/api/v2/vacancies?${payload.regions}`)
    .json(payload.vacancy)
    .post()
    .json<T.AddVacancyResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Add vacancy - Mutation
 */
const useAddVacancy = (
  options?: UseMutationOptions<
    T.AddVacancyResponse,
    unknown,
    T.AddVacancyRequest
  >
) =>
  useMutation({
    mutationFn: addVacancy,
    ...options
  })

/**
 * Delete vacancy - Call
 */
const deleteVacancy = async (payload: T.AddVacancyRequest) =>
  await useWretch('FsyncAPI')
    .url(`/api/v2/vacancies/${payload.vacancy.id}?${payload.regions}`)
    .delete()
    .json<T.AddVacancyResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Delete vacancy - Mutation
 */
const useDeleteVacancy = (
  options?: UseMutationOptions<
    T.AddVacancyResponse,
    unknown,
    T.AddVacancyRequest
  >
) =>
  useMutation({
    mutationFn: deleteVacancy,
    ...options
  })
/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetAllVacancies
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  useAddVacancy,
  useUpdateVacancy,
  useDeleteVacancy
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const HumanResourcesVacanciesApiFactory = {
  queries,
  mutations
}
