import useAuthStore from '~/features/auth/store'

export default function () {
  // const nuxtApp = useNuxtApp()

  const authStore = useAuthStore()

  return computed({
    get: () => authStore.user,
    set: (value) => (authStore.user = value)
  })
}
