import { httpErrorHelperOnOk } from '~/factories/api/azure.api.error.helper'
import type * as T from '~/factories/api/azure.api.types'
import type * as ST from './sales.types'

export async function getReasons(
  region: T.region
): Promise<ST.ReasonDictionary | null> {
  return await useWretch('frSysproAPI')
    .get(`/order/api/Sales/${region}/reasons`)
    .json<ST.ReasonDictionary>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getReasons:', err)
      return null
    })
}

export async function getTaxCodes(
  region: T.region
): Promise<ST.TaxCode[] | null> {
  return await useWretch('frSysproAPI')
    .get(`/order/api/Sales/${region}/taxCodes`)
    .json<ST.TaxCode[]>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getTaxCodes:', err)
      return null
    })
}

export async function getSalesOrder(
  region: T.region,
  salesOrderNumber: string
): Promise<ST.GenericSalesOrder[] | null> {
  return await useWretch('frSysproAPI')
    .get(`/order/api/Sales/${region}/SalesOrder/${salesOrderNumber}`)
    .json<ST.GenericSalesOrder[]>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getSalesOrder:', err)
      return null
    })
}

export async function getSalesOrders(
  region: T.region,
  query: ST.GetSalesOrdersQuery
): Promise<ST.GetSalesOrdersResponse | null> {
  return await useWretch('frSysproAPI')
    .query(query)
    .get(`/order/api/Sales/${region}/search`)
    .json<ST.GetSalesOrdersResponse>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getSalesOrders:', err)
      return null
    })
}

export async function creditSalesOrder(
  region: T.region,
  salesOrderNumber: string,
  invoiceNumber: string,
  reasonCode: string,
  reference: string
): Promise<ST.CreditNoteResponse | null> {
  const creditNoteRequest: ST.CreditNoteRequest = {
    invoice: invoiceNumber,
    reasonCode: reasonCode,
    reference: reference
  }

  return await useWretch('frSysproAPI')
    .post(
      JSON.stringify(creditNoteRequest),
      `/order/api/Sales/${region}/${salesOrderNumber}/Credit`
    )
    .json<ST.CreditNoteResponse>()
    .then((res) => {
      httpErrorHelperOnOk(res)
      return res
    })
    .catch((err) => {
      console.error('Error in creditSalesOrder:', err)
      return null
    })
}

export async function invoiceSalesOrder(
  region: T.region,
  salesOrderNumber: string
): Promise<ST.InvoiceSalesOrderResponse | null> {
  return await useWretch('frSysproAPI')
    .post(null, `/order/api/Sales/${region}/${salesOrderNumber}/Invoice`)
    .json<ST.InvoiceSalesOrderResponse>()
    .then((res) => {
      httpErrorHelperOnOk(res)
      return res
    })
    .catch((err) => {
      console.error('Error in invoiceSalesOrder:', err)
      return null
    })
}

export async function createSalesOrder(
  region: T.region,
  genericSalesOrderToSave: ST.GenericSalesOrderToSave
): Promise<ST.CreateSalesOrderResponse | null> {
  return await useWretch('frSysproAPI')
    .post(JSON.stringify(genericSalesOrderToSave), `/order/api/Sales/${region}`)
    .json<ST.CreateSalesOrderResponse>()
    .then((res) => {
      httpErrorHelperOnOk(res)
      return res
    })
    .catch((err) => {
      console.error('Error in createSalesOrder:', err)
      return null
    })
}

export async function updateSalesOrder(
  region: T.region,
  salesOrderNumber: string,
  genericSalesOrderToSave: ST.GenericSalesOrderToSave
): Promise<ST.UpdateSalesOrderResponse | null> {
  return await useWretch('frSysproAPI')
    .post(
      JSON.stringify(genericSalesOrderToSave),
      `/order/api/Sales/${region}/${salesOrderNumber}`
    )
    .json<ST.UpdateSalesOrderResponse>()
    .then((res) => {
      httpErrorHelperOnOk(res)
      return res
    })
    .catch((err) => {
      console.error('Error in updateSalesOrder:', err)
      return null
    })
}

export async function cancelSalesOrder(
  region: T.region,
  salesOrderNumber: string,
  cancelReasonId: string
): Promise<ST.CancelSalesOrderResponse | null> {
  return await useWretch('frSysproAPI')
    .url(
      `/order/api/Sales/${region}/${salesOrderNumber}/Cancel?ReasonCode=${cancelReasonId}`
    )
    .patch()
    .json<ST.CancelSalesOrderResponse>()
    .then((res) => {
      httpErrorHelperOnOk(res)
      return res
    })
    .catch((err) => {
      console.error('Error in cancelSalesOrder:', err)
      return null
    })
}

export async function getB2CCustomerDetails(
  region: T.region,
  emailAddress: string
): Promise<ST.B2CCustomer[] | null> {
  return await useWretch('frSysproAPI')
    .get(`/order/api/Sales/${region}/lookupB2CCustomer?Email=${emailAddress}`)
    .json<ST.B2CCustomer[]>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getB2CCustomerDetails:', err)
      return null
    })
}
