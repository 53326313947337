import type { RouteMeta } from 'vue-router'

const serviceDeskPageMeta: RouteMeta = {
  name: 'service-desk',
  path: '/service-desk',
  title: 'Service Desk',
  description: {
    name: 'description',
    content: 'Service Desk'
  },
  icon: 'fad fa-question-circle',
  showOnNav: true,
  keepalive: false,
  order: 2,
  requiresAuth: true,
  show: true,
  permissions: []
}

export default serviceDeskPageMeta
