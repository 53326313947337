import type { RouteMeta } from 'vue-router'
import { useSysproStore } from '~/features/syspro/store/syspro-store'

/**
 * Represents the metadata for the Warehouse - Movements - Bin Transfer page
 */
const warehouseMovementsBinTransferMeta: RouteMeta = {
  name: 'warehouse.movements.bin-transfer',
  title: 'Bin Transfer',
  path: '/warehouse/movements/bin-transfer',
  order: 0,
  requiresAuth: true,
  show: true,
  permissions: ['warehouse movements bin transfer | view'],
  beforeEnter: () => {
    useSysproStore()
  },
  showOnNav: true,
  description: {
    name: 'description',
    content: 'Warehouse - Movements - Bin Transfer'
  }
}

export default warehouseMovementsBinTransferMeta
