/**
 * ================================================
 * Base Imports
 * ================================================
 */
import type { WretchError } from 'wretch/resolver'
/**
 * ================================================
 * Local Imports
 * ================================================
 */
import type {
  Result,
  WarningAndErrorReason
} from '~/factories/api/azure.api.types'
import generalHttpErrorHelper from '~/factories/api/error.helper'

function constructResponseMessage(response: unknown): string {
  let message = ''

  if (response && typeof response === 'object') {
    const errorResponse = response as Result

    message += errorResponse.message ? `${errorResponse.message} ` : ''
    message += errorResponse.error ? `${errorResponse.error} ` : ''

    if (!isArrayNullOrEmpty(errorResponse.reasons)) {
      errorResponse.reasons.forEach((reason: WarningAndErrorReason) => {
        if (reason.warningText) {
          message += `${reason.warningText} `
        } else if (reason.errorText) {
          message += `${reason.errorText} `
        }
      })
    }

    if (errorResponse.errors && typeof errorResponse.errors === 'object') {
      for (const key in errorResponse.errors) {
        if (Array.isArray(errorResponse.errors[key])) {
          message += `${key}: `

          for (const errorMsg of errorResponse.errors[key]) {
            message += `${errorMsg} `
          }
        }
      }
    }
  }

  return message.trim()
}

export function httpErrorHelperOnOk(successResponse: unknown) {
  let errorMessage = ''

  if (successResponse !== null && typeof successResponse === 'object') {
    const response = successResponse as Result

    if (response?.success === false) {
      errorMessage = constructResponseMessage(response)

      if (errorMessage.trim() === '') {
        errorMessage +=
          'There was an error processing your request. Response Status: OK (200).'
      }

      notifier({
        message: errorMessage,
        type: 'error'
      })
    }
  }
}

function constructErrorMessage(
  error: WretchError,
  generalErrorHandlerStatuses = [401, 403, 404, 422]
) {
  let errorMessage = ''
  const errorResponse = error.json

  if (errorResponse) {
    errorMessage = constructResponseMessage(errorResponse)
  }

  if (errorMessage.trim() === '' && errorResponse) {
    errorMessage += `${errorResponse} `
  }

  //if we weren't able to get the error message and the error status is handled by the general http error handler
  if (
    (errorMessage.trim() === '' &&
      generalErrorHandlerStatuses.includes(error?.response?.status)) ||
    generalErrorHandlerStatuses.includes(error?.status)
  ) {
    return ''
  }

  //If we still don't have an error message, we have to return a general error message
  if (errorMessage.trim() === '') {
    errorMessage += 'There was an error processing your request.'
  }

  const status =
    error?.response?.status ||
    error?.response?.code ||
    error?.status ||
    'no status or Network Error'

  errorMessage += ` (${status})`

  return errorMessage.trim()
}

export function httpErrorHelper(error: WretchError) {
  const generalErrorHandlerStatuses = [401, 403, 404, 422]
  const errorMessage = constructErrorMessage(error, generalErrorHandlerStatuses)

  if (errorMessage.trim() !== '') {
    notifier({
      message: errorMessage,
      type: 'error'
    })
  }

  return generalHttpErrorHelper(error)
}
