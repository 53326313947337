/**
 * ================================================
 * AI API Factory
 * This factory contains all the methods for interacting with the AI feature.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import {
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery
} from '@tanstack/vue-query'
/**
 * ================================================
 * Custom packages
 * ================================================
 */
import type * as T from './types'

/**
 * Get daily quote from the server
 *
 * Custom hook for fetching the daily quote.
 * @returns The result of the useQuery hook for fetching the daily quote.
 */
const useGetDailyQuote = () =>
  useQuery({
    queryKey: ['daily_quote'],
    queryFn: async () =>
      await useWretch('permissionsAPI')
        .get('/chatbot/daily_quote')
        .json<T.getDailyQuoteResponse>()
        .then((res) => res.content)
        .catch((err) => err),
    refetchOnWindowFocus: false,
    refetchOnMount: false
  })

/**
 * Goal motivation from the server
 *
 * Custom hook for fetching the goal motivation.
 * @returns The result of the useQuery hook for fetching the goal motivation.
 */
const useGetGoalMotivation = (
  options: UseQueryOptions<T.getGoalMotivationResponse>
) =>
  useQuery<T.getGoalMotivationResponse>({
    queryKey: ['goal_motivation'],
    queryFn: async () =>
      await useWretch('permissionsAPI')
        .get('/chatbot/goal_motivation')
        .json<T.getGoalMotivationResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options
  })

/**
 * Send message to the server
 *
 * Custom hook for sending a message to the server.
 * @param options - The options for the useMutation hook.
 * @returns The result of the useMutation hook for sending a message to the server.
 */
const useSendMessage = (
  options?: UseMutationOptions<
    T.sendMessageResponse,
    unknown,
    T.sendMessageRequest,
    Error
  >
) =>
  useMutation({
    mutationFn: async (payload) =>
      await useWretch('permissionsAPI')
        .post(
          JSON.stringify({
            messages: payload
          }),
          '/chatbot/learning'
        )
        .json<T.sendMessageResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...(options || {})
  })

/**
 * GetTextToSpeech
 *
 * Custom hook for retrieving the text to speech from the server.
 * @param options - The options for the useMutation hook.
 * @returns The result of the useMutation hook for retrieving the text to speech from the server.
 */
const useGetTextToSpeech = (payload?: {
  options?: UseMutationOptions<
    T.textToSpeechResponse,
    unknown,
    T.textToSpeechRequest,
    Error
  >
}) =>
  useMutation({
    mutationFn: async (payload) =>
      await useWretch('permissionsAPI')
        .post(JSON.stringify(payload), '/chatbot/text_to_speech')
        .json<T.textToSpeechResponse>()
        .then((res) => {
          return `${process.env.FR_PERMISSIONS_URL}${res}`
        })
        .catch((err) => err),
    ...(payload?.options || {})
  })

/**
 * Feedback form
 */
const useSendFeedback = (payload: {
  options?: UseMutationOptions<
    T.feedbackFormResponse,
    unknown,
    T.feedbackFormRequest,
    Error
  >
}) =>
  useMutation({
    mutationFn: async (payload) =>
      await useWretch('permissionsAPI')
        .post(JSON.stringify(payload), '/chatbot/feedback')
        .json<T.feedbackFormResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...(payload?.options || {})
  })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetDailyQuote,
  useGetGoalMotivation
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  useSendMessage,
  useSendFeedback,
  useGetTextToSpeech
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const AIApiFactory = {
  queries,
  mutations
}
