import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Clocking Cards page.
 */
const clockingCardsPageMeta: RouteMeta = {
  name: 'human-resources.clocking-cards',
  title: 'Clocking Cards',
  path: '/human-resources/clocking-cards',
  description: {
    name: 'description',
    content: 'Clocking Cards'
  },
  showOnNav: true,
  order: 2,
  show: true,
  requiresAuth: true,
  permissions: ['human resources clocking cards | view']
}

export default clockingCardsPageMeta
