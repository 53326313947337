export default function (target: 'fsync' | 'azure' | 'syspro' = 'fsync') {
  let authToken: string | null

  const fToken = computed(
    () => useCookie('ftoken').value || localStorage.getItem('ftoken')
  )

  const aToken = computed(
    () =>
      useCookie('authorization_token').value ||
      localStorage.getItem('authorization_token')
  )

  const sToken = computed(
    () =>
      useCookie('syspro_authorization_token').value ||
      localStorage.getItem('syspro_authorization_token')
  )

  /**
   * Get token for fsync
   */
  if (target === 'fsync') {
    authToken =
      fToken.value && typeof fToken.value === 'string' ? fToken.value : null

    return authToken
    /**
     * Get token for azure
     */
  } else if (target === 'azure') {
    authToken =
      aToken.value && typeof aToken.value === 'string' ? aToken.value : null

    return authToken
    /**
     * Get token for syspro
     */
  } else if (target === 'syspro') {
    authToken =
      sToken.value && typeof aToken.value === 'string' ? sToken.value : null

    return authToken
  } else {
    return null
  }
}
