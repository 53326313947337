import type * as T from '~/factories/api/azure.api.types'
import type * as PRT from './org-chart.types'

export async function getOrgChartByRegion(
  region: T.region
): Promise<PRT.OrgChartEmployee[] | null> {
  return await useWretch('FrAPI')
    .get(`/performance_review/Employee/orgChart/region/${region}`)
    .json<PRT.OrgChartEmployee[]>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getOrgChartByRegion:', err)
      return null
    })
}

export async function getOrgChartByDepartment(
  department: PRT.Department
): Promise<PRT.OrgChartEmployee[] | null> {
  return await useWretch('FrAPI')
    .get(`/performance_review/Employee/orgChart/department/${department}`)
    .json<PRT.OrgChartEmployee[]>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getOrgChartByDepartment:', err)
      return null
    })
}

export async function getEmployeeSubordinates(
  companyId: string,
  employeeId: string,
  allSubordinates: boolean,
  includeSuperior: boolean
): Promise<PRT.OrgChartEmployee[] | null> {
  return await useWretch('FrAPI')
    .get(
      `/performance_review/Employee/orgChart/employee/${companyId}/${employeeId}/${
        allSubordinates ? 'allSubordinates' : 'directSubordinates'
      }?includeSuperior=${includeSuperior}`
    )
    .json<PRT.OrgChartEmployee[]>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getEmployeeSubordinates:', err)
      return null
    })
}
