import { pickingDetailsApiFactory } from '~/features/warehouse/picking-details/api'
import { warehouseApiFactory } from '~/features/warehouse/api'
import { warehouseCarrierLabelsApiFactory } from '~/features/warehouse/labels/carrier-labels/api'
import { warehouseTransferApiFactory } from '~/features/warehouse/warehouse-transfers/api'

import { pickingDashboard } from './picking-dashboard/index'
import * as factory from './warehouse.factory'

export const warehouse = {
  ...factory,
  pickingDashboard,
  pickingDetailsApiFactory,
  warehouseApiFactory,
  warehouseTransferApiFactory,
  warehouseCarrierLabelsApiFactory
}
