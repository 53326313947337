import { default as indexhs3B0TMkeEMeta } from "/opt/buildhome/repo/pages/admin/permissions/index.vue?macro=true";
import { default as indexgKJzVDs9HKMeta } from "/opt/buildhome/repo/pages/admin/regions/index.vue?macro=true";
import { default as indexXtUngUp57YMeta } from "/opt/buildhome/repo/pages/admin/users/index.vue?macro=true";
import { default as indexQb1jG6kRuwMeta } from "/opt/buildhome/repo/pages/auth/account/index.vue?macro=true";
import { default as indexzLfJUIfcqEMeta } from "/opt/buildhome/repo/pages/auth/login/index.vue?macro=true";
import { default as indexPBZlhuwTh8Meta } from "/opt/buildhome/repo/pages/auth/logout/index.vue?macro=true";
import { default as indexJF6ywDlJR9Meta } from "/opt/buildhome/repo/pages/catalog/product-comparison/index.vue?macro=true";
import { default as indexPuxB7MbkAGMeta } from "/opt/buildhome/repo/pages/catalog/product-details/index.vue?macro=true";
import { default as indexwcPFp1H73VMeta } from "/opt/buildhome/repo/pages/catalog/product-info/index.vue?macro=true";
import { default as indexY1wrn5e9bXMeta } from "/opt/buildhome/repo/pages/catalog/product-positions/index.vue?macro=true";
import { default as indexFbsLlevEP4Meta } from "/opt/buildhome/repo/pages/catalog/product-relationships/index.vue?macro=true";
import { default as indexMLtdGZvr05Meta } from "/opt/buildhome/repo/pages/catalog/product-specs/index.vue?macro=true";
import { default as index8LhBln58nqMeta } from "/opt/buildhome/repo/pages/content/gearzette/index.vue?macro=true";
import { default as indexXAUVkA9HIVMeta } from "/opt/buildhome/repo/pages/content/landing-pages/index.vue?macro=true";
import { default as indexd0XKcfFuKbMeta } from "/opt/buildhome/repo/pages/content/translations/m2pwa/index.vue?macro=true";
import { default as indexpfg4ijoxxKMeta } from "/opt/buildhome/repo/pages/customers/b2b/index.vue?macro=true";
import { default as index3aGEXy454yMeta } from "/opt/buildhome/repo/pages/customers/b2c/index.vue?macro=true";
import { default as indexSrsMttWDDOMeta } from "/opt/buildhome/repo/pages/customers/price-list/index.vue?macro=true";
import { default as indexmuSom7PBInMeta } from "/opt/buildhome/repo/pages/documents/document-search/index.vue?macro=true";
import { default as indexqxe6jfzMqoMeta } from "/opt/buildhome/repo/pages/documents/lost-and-found-pod/index.vue?macro=true";
import { default as indexEc1q05Q506Meta } from "/opt/buildhome/repo/pages/documents/proof-of-delivery/index.vue?macro=true";
import { default as indexn4sVfKUSvzMeta } from "/opt/buildhome/repo/pages/events/euSubscribers/entries/index.vue?macro=true";
import { default as indexZWQIOYpXUkMeta } from "/opt/buildhome/repo/pages/events/euSubscribers/form/index.vue?macro=true";
import { default as indexoYIGQQUZ23Meta } from "/opt/buildhome/repo/pages/events/gearlist/form/index.vue?macro=true";
import { default as indexqXltR7Kt9hMeta } from "/opt/buildhome/repo/pages/events/gearlist/settings/index.vue?macro=true";
import { default as indexZblR0BIC1jMeta } from "/opt/buildhome/repo/pages/events/subscribers/entries/index.vue?macro=true";
import { default as indexu0Yt8ktgnIMeta } from "/opt/buildhome/repo/pages/events/subscribers/form/index.vue?macro=true";
import { default as index6gHVtHXiiRMeta } from "/opt/buildhome/repo/pages/events/subscribers/settings/index.vue?macro=true";
import { default as indexfWaJluH35yMeta } from "/opt/buildhome/repo/pages/factory/assign-barcode/index.vue?macro=true";
import { default as indexw5yX7nfzB7Meta } from "/opt/buildhome/repo/pages/factory/labels/factory-labels/index.vue?macro=true";
import { default as index48LYIExxMeMeta } from "/opt/buildhome/repo/pages/factory/labels/product-labels/index.vue?macro=true";
import { default as indexqudfrvwNixMeta } from "/opt/buildhome/repo/pages/factory/labels/qr-labels/index.vue?macro=true";
import { default as indexDwqZqQNKycMeta } from "/opt/buildhome/repo/pages/factory/shipping-dimensions/index.vue?macro=true";
import { default as index7yMflU7tibMeta } from "/opt/buildhome/repo/pages/fredi/index.vue?macro=true";
import { default as indexLQn5ZVVxvwMeta } from "/opt/buildhome/repo/pages/human-resources/clocking-cards/index.vue?macro=true";
import { default as indextLTmhMamQiMeta } from "/opt/buildhome/repo/pages/human-resources/org-chart/index.vue?macro=true";
import { default as indexvjI9hqrqBfMeta } from "/opt/buildhome/repo/pages/human-resources/performance-reviews/index.vue?macro=true";
import { default as indexYSO8wU6RpMMeta } from "/opt/buildhome/repo/pages/human-resources/vacancies/index.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as indexJJmrlPyAKoMeta } from "/opt/buildhome/repo/pages/patchnotes/index.vue?macro=true";
import { default as indexkC1N7W2RzOMeta } from "/opt/buildhome/repo/pages/sales/affiliates/index.vue?macro=true";
import { default as indexorvTFpFYDsMeta } from "/opt/buildhome/repo/pages/sales/black-friday/index.vue?macro=true";
import { default as indexFzw9ZsP2zIMeta } from "/opt/buildhome/repo/pages/sales/commercial-warranty-invoice/index.vue?macro=true";
import { default as indexQ3QjZX2ityMeta } from "/opt/buildhome/repo/pages/sales/credit-memos/index.vue?macro=true";
import { default as indexBMlID9fK62Meta } from "/opt/buildhome/repo/pages/sales/dealer-map/index.vue?macro=true";
import { default as indexmPTLVFPv6vMeta } from "/opt/buildhome/repo/pages/sales/giftcards/index.vue?macro=true";
import { default as indexGEsA46Z8AOMeta } from "/opt/buildhome/repo/pages/sales/invoices/index.vue?macro=true";
import { default as indexqc9uCyY3WrMeta } from "/opt/buildhome/repo/pages/sales/orders/index.vue?macro=true";
import { default as indexoemBDOBGoCMeta } from "/opt/buildhome/repo/pages/sales/point-of-sale/customer/index.vue?macro=true";
import { default as indexoltHH9zyPLMeta } from "/opt/buildhome/repo/pages/sales/point-of-sale/sales/index.vue?macro=true";
import { default as indexUURJ6fPBmoMeta } from "/opt/buildhome/repo/pages/sales/product-pricing/index.vue?macro=true";
import { default as indexjZtivDP9R2Meta } from "/opt/buildhome/repo/pages/sales/sales-graphs/index.vue?macro=true";
import { default as indexUlhniK9dYLMeta } from "/opt/buildhome/repo/pages/sales/scan-credit-card-slip/index.vue?macro=true";
import { default as index4SgTIVwYSQMeta } from "/opt/buildhome/repo/pages/sales/shipments/index.vue?macro=true";
import { default as indexSjvyctpANpMeta } from "/opt/buildhome/repo/pages/service-desk/index.vue?macro=true";
import { default as index7ptnXGQPZTMeta } from "/opt/buildhome/repo/pages/settings/force-reset/index.vue?macro=true";
import { default as indexwVCDQjrTuDMeta } from "/opt/buildhome/repo/pages/tests/buttons/index.vue?macro=true";
import { default as indexQpOV9BGVAQMeta } from "/opt/buildhome/repo/pages/verify/index.vue?macro=true";
import { default as indexfNwHjRUOJuMeta } from "/opt/buildhome/repo/pages/vfits/makes-models/index.vue?macro=true";
import { default as indexUOLXr9OaL1Meta } from "/opt/buildhome/repo/pages/vfits/outfit-by-product/index.vue?macro=true";
import { default as indexR1vwjSO2tGMeta } from "/opt/buildhome/repo/pages/vfits/outfit-by-vehicle/index.vue?macro=true";
import { default as indexPX3uPrGCb0Meta } from "/opt/buildhome/repo/pages/warehouse/labels/bin-labels/index.vue?macro=true";
import { default as index1FbZTaIlSyMeta } from "/opt/buildhome/repo/pages/warehouse/labels/bulk-bin-labels/index.vue?macro=true";
import { default as indexpv0cLyQNktMeta } from "/opt/buildhome/repo/pages/warehouse/labels/carrier-labels/index.vue?macro=true";
import { default as indexHuQ2yh3OR0Meta } from "/opt/buildhome/repo/pages/warehouse/labels/component-labels/index.vue?macro=true";
import { default as indexag4bOfCwvZMeta } from "/opt/buildhome/repo/pages/warehouse/labels/outer-labels/index.vue?macro=true";
import { default as indexHaPdyjqvDGMeta } from "/opt/buildhome/repo/pages/warehouse/labels/product-labels/index.vue?macro=true";
import { default as indextX28Z41Y01Meta } from "/opt/buildhome/repo/pages/warehouse/labels/qr-labels/index.vue?macro=true";
import { default as indexQBNfhDFjEHMeta } from "/opt/buildhome/repo/pages/warehouse/labels/retail-labels/index.vue?macro=true";
import { default as indexaQWJtnuhcKMeta } from "/opt/buildhome/repo/pages/warehouse/logistics-containers/index.vue?macro=true";
import { default as indexsGnExZJQu5Meta } from "/opt/buildhome/repo/pages/warehouse/movements/bin-transfer/index.vue?macro=true";
import { default as indexWpG7c6UkehMeta } from "/opt/buildhome/repo/pages/warehouse/movements/goods-in-transit/index.vue?macro=true";
import { default as indexVFoj7bkONrMeta } from "/opt/buildhome/repo/pages/warehouse/movements/immediate-transfer/index.vue?macro=true";
import { default as indexpvc760q2PeMeta } from "/opt/buildhome/repo/pages/warehouse/picking-dashboard/index.vue?macro=true";
import { default as indexDSFAp9XZ8PMeta } from "/opt/buildhome/repo/pages/warehouse/picking-details/index.vue?macro=true";
import { default as indexMKAaJU8ckmMeta } from "/opt/buildhome/repo/pages/warehouse/shipping-dashboard/index.vue?macro=true";
import { default as indexKFLEeNQplYMeta } from "/opt/buildhome/repo/pages/warehouse/shipping-details/index.vue?macro=true";
import { default as indexdTXJiFAvTWMeta } from "/opt/buildhome/repo/pages/warehouse/stock-take/index.vue?macro=true";
import { default as indexz3dw7WnMqOMeta } from "/opt/buildhome/repo/pages/warehouse/warehouse-transfers/index.vue?macro=true";
export default [
  {
    name: "admin-permissions",
    path: "/admin/permissions",
    component: () => import("/opt/buildhome/repo/pages/admin/permissions/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-regions",
    path: "/admin/regions",
    component: () => import("/opt/buildhome/repo/pages/admin/regions/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/opt/buildhome/repo/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-account",
    path: "/auth/account",
    component: () => import("/opt/buildhome/repo/pages/auth/account/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    component: () => import("/opt/buildhome/repo/pages/auth/login/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    component: () => import("/opt/buildhome/repo/pages/auth/logout/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-product-comparison",
    path: "/catalog/product-comparison",
    component: () => import("/opt/buildhome/repo/pages/catalog/product-comparison/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-product-details",
    path: "/catalog/product-details",
    component: () => import("/opt/buildhome/repo/pages/catalog/product-details/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-product-info",
    path: "/catalog/product-info",
    component: () => import("/opt/buildhome/repo/pages/catalog/product-info/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-product-positions",
    path: "/catalog/product-positions",
    component: () => import("/opt/buildhome/repo/pages/catalog/product-positions/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-product-relationships",
    path: "/catalog/product-relationships",
    component: () => import("/opt/buildhome/repo/pages/catalog/product-relationships/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-product-specs",
    path: "/catalog/product-specs",
    component: () => import("/opt/buildhome/repo/pages/catalog/product-specs/index.vue").then(m => m.default || m)
  },
  {
    name: "content-gearzette",
    path: "/content/gearzette",
    component: () => import("/opt/buildhome/repo/pages/content/gearzette/index.vue").then(m => m.default || m)
  },
  {
    name: "content-landing-pages",
    path: "/content/landing-pages",
    component: () => import("/opt/buildhome/repo/pages/content/landing-pages/index.vue").then(m => m.default || m)
  },
  {
    name: "content-translations-m2pwa",
    path: "/content/translations/m2pwa",
    component: () => import("/opt/buildhome/repo/pages/content/translations/m2pwa/index.vue").then(m => m.default || m)
  },
  {
    name: "customers-b2b",
    path: "/customers/b2b",
    component: () => import("/opt/buildhome/repo/pages/customers/b2b/index.vue").then(m => m.default || m)
  },
  {
    name: "customers-b2c",
    path: "/customers/b2c",
    component: () => import("/opt/buildhome/repo/pages/customers/b2c/index.vue").then(m => m.default || m)
  },
  {
    name: "customers-price-list",
    path: "/customers/price-list",
    component: () => import("/opt/buildhome/repo/pages/customers/price-list/index.vue").then(m => m.default || m)
  },
  {
    name: "documents-document-search",
    path: "/documents/document-search",
    component: () => import("/opt/buildhome/repo/pages/documents/document-search/index.vue").then(m => m.default || m)
  },
  {
    name: "documents-lost-and-found-pod",
    path: "/documents/lost-and-found-pod",
    component: () => import("/opt/buildhome/repo/pages/documents/lost-and-found-pod/index.vue").then(m => m.default || m)
  },
  {
    name: "documents-proof-of-delivery",
    path: "/documents/proof-of-delivery",
    component: () => import("/opt/buildhome/repo/pages/documents/proof-of-delivery/index.vue").then(m => m.default || m)
  },
  {
    name: "events-euSubscribers-entries",
    path: "/events/euSubscribers/entries",
    component: () => import("/opt/buildhome/repo/pages/events/euSubscribers/entries/index.vue").then(m => m.default || m)
  },
  {
    name: "events-euSubscribers-form",
    path: "/events/euSubscribers/form",
    component: () => import("/opt/buildhome/repo/pages/events/euSubscribers/form/index.vue").then(m => m.default || m)
  },
  {
    name: "events-gearlist-form",
    path: "/events/gearlist/form",
    component: () => import("/opt/buildhome/repo/pages/events/gearlist/form/index.vue").then(m => m.default || m)
  },
  {
    name: "events-gearlist-settings",
    path: "/events/gearlist/settings",
    component: () => import("/opt/buildhome/repo/pages/events/gearlist/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "events-subscribers-entries",
    path: "/events/subscribers/entries",
    component: () => import("/opt/buildhome/repo/pages/events/subscribers/entries/index.vue").then(m => m.default || m)
  },
  {
    name: "events-subscribers-form",
    path: "/events/subscribers/form",
    component: () => import("/opt/buildhome/repo/pages/events/subscribers/form/index.vue").then(m => m.default || m)
  },
  {
    name: "events-subscribers-settings",
    path: "/events/subscribers/settings",
    component: () => import("/opt/buildhome/repo/pages/events/subscribers/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "factory-assign-barcode",
    path: "/factory/assign-barcode",
    component: () => import("/opt/buildhome/repo/pages/factory/assign-barcode/index.vue").then(m => m.default || m)
  },
  {
    name: "factory-labels-factory-labels",
    path: "/factory/labels/factory-labels",
    component: () => import("/opt/buildhome/repo/pages/factory/labels/factory-labels/index.vue").then(m => m.default || m)
  },
  {
    name: "factory-labels-product-labels",
    path: "/factory/labels/product-labels",
    component: () => import("/opt/buildhome/repo/pages/factory/labels/product-labels/index.vue").then(m => m.default || m)
  },
  {
    name: "factory-labels-qr-labels",
    path: "/factory/labels/qr-labels",
    component: () => import("/opt/buildhome/repo/pages/factory/labels/qr-labels/index.vue").then(m => m.default || m)
  },
  {
    name: "factory-shipping-dimensions",
    path: "/factory/shipping-dimensions",
    component: () => import("/opt/buildhome/repo/pages/factory/shipping-dimensions/index.vue").then(m => m.default || m)
  },
  {
    name: "fredi",
    path: "/fredi",
    component: () => import("/opt/buildhome/repo/pages/fredi/index.vue").then(m => m.default || m)
  },
  {
    name: "human-resources-clocking-cards",
    path: "/human-resources/clocking-cards",
    component: () => import("/opt/buildhome/repo/pages/human-resources/clocking-cards/index.vue").then(m => m.default || m)
  },
  {
    name: "human-resources-org-chart",
    path: "/human-resources/org-chart",
    component: () => import("/opt/buildhome/repo/pages/human-resources/org-chart/index.vue").then(m => m.default || m)
  },
  {
    name: "human-resources-performance-reviews",
    path: "/human-resources/performance-reviews",
    component: () => import("/opt/buildhome/repo/pages/human-resources/performance-reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "human-resources-vacancies",
    path: "/human-resources/vacancies",
    component: () => import("/opt/buildhome/repo/pages/human-resources/vacancies/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "patchnotes",
    path: "/patchnotes",
    component: () => import("/opt/buildhome/repo/pages/patchnotes/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-affiliates",
    path: "/sales/affiliates",
    component: () => import("/opt/buildhome/repo/pages/sales/affiliates/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-black-friday",
    path: "/sales/black-friday",
    component: () => import("/opt/buildhome/repo/pages/sales/black-friday/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-commercial-warranty-invoice",
    path: "/sales/commercial-warranty-invoice",
    component: () => import("/opt/buildhome/repo/pages/sales/commercial-warranty-invoice/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-credit-memos",
    path: "/sales/credit-memos",
    component: () => import("/opt/buildhome/repo/pages/sales/credit-memos/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-dealer-map",
    path: "/sales/dealer-map",
    component: () => import("/opt/buildhome/repo/pages/sales/dealer-map/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-giftcards",
    path: "/sales/giftcards",
    component: () => import("/opt/buildhome/repo/pages/sales/giftcards/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-invoices",
    path: "/sales/invoices",
    component: () => import("/opt/buildhome/repo/pages/sales/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-orders",
    path: "/sales/orders",
    component: () => import("/opt/buildhome/repo/pages/sales/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-point-of-sale-customer",
    path: "/sales/point-of-sale/customer",
    component: () => import("/opt/buildhome/repo/pages/sales/point-of-sale/customer/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-point-of-sale-sales",
    path: "/sales/point-of-sale/sales",
    component: () => import("/opt/buildhome/repo/pages/sales/point-of-sale/sales/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-product-pricing",
    path: "/sales/product-pricing",
    component: () => import("/opt/buildhome/repo/pages/sales/product-pricing/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-sales-graphs",
    path: "/sales/sales-graphs",
    component: () => import("/opt/buildhome/repo/pages/sales/sales-graphs/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-scan-credit-card-slip",
    path: "/sales/scan-credit-card-slip",
    component: () => import("/opt/buildhome/repo/pages/sales/scan-credit-card-slip/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-shipments",
    path: "/sales/shipments",
    component: () => import("/opt/buildhome/repo/pages/sales/shipments/index.vue").then(m => m.default || m)
  },
  {
    name: "service-desk",
    path: "/service-desk",
    component: () => import("/opt/buildhome/repo/pages/service-desk/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-force-reset",
    path: "/settings/force-reset",
    component: () => import("/opt/buildhome/repo/pages/settings/force-reset/index.vue").then(m => m.default || m)
  },
  {
    name: "tests.buttons",
    path: "/tests/buttons",
    meta: indexwVCDQjrTuDMeta || {},
    component: () => import("/opt/buildhome/repo/pages/tests/buttons/index.vue").then(m => m.default || m)
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/opt/buildhome/repo/pages/verify/index.vue").then(m => m.default || m)
  },
  {
    name: "vfits-makes-models",
    path: "/vfits/makes-models",
    component: () => import("/opt/buildhome/repo/pages/vfits/makes-models/index.vue").then(m => m.default || m)
  },
  {
    name: "vfits-outfit-by-product",
    path: "/vfits/outfit-by-product",
    component: () => import("/opt/buildhome/repo/pages/vfits/outfit-by-product/index.vue").then(m => m.default || m)
  },
  {
    name: "vfits-outfit-by-vehicle",
    path: "/vfits/outfit-by-vehicle",
    component: () => import("/opt/buildhome/repo/pages/vfits/outfit-by-vehicle/index.vue").then(m => m.default || m)
  },
  {
    name: "warehouse-labels-bin-labels",
    path: "/warehouse/labels/bin-labels",
    component: () => import("/opt/buildhome/repo/pages/warehouse/labels/bin-labels/index.vue").then(m => m.default || m)
  },
  {
    name: "warehouse-labels-bulk-bin-labels",
    path: "/warehouse/labels/bulk-bin-labels",
    component: () => import("/opt/buildhome/repo/pages/warehouse/labels/bulk-bin-labels/index.vue").then(m => m.default || m)
  },
  {
    name: "warehouse-labels-carrier-labels",
    path: "/warehouse/labels/carrier-labels",
    component: () => import("/opt/buildhome/repo/pages/warehouse/labels/carrier-labels/index.vue").then(m => m.default || m)
  },
  {
    name: "warehouse-labels-component-labels",
    path: "/warehouse/labels/component-labels",
    component: () => import("/opt/buildhome/repo/pages/warehouse/labels/component-labels/index.vue").then(m => m.default || m)
  },
  {
    name: "warehouse-labels-outer-labels",
    path: "/warehouse/labels/outer-labels",
    component: () => import("/opt/buildhome/repo/pages/warehouse/labels/outer-labels/index.vue").then(m => m.default || m)
  },
  {
    name: "warehouse-labels-product-labels",
    path: "/warehouse/labels/product-labels",
    component: () => import("/opt/buildhome/repo/pages/warehouse/labels/product-labels/index.vue").then(m => m.default || m)
  },
  {
    name: "warehouse-labels-qr-labels",
    path: "/warehouse/labels/qr-labels",
    component: () => import("/opt/buildhome/repo/pages/warehouse/labels/qr-labels/index.vue").then(m => m.default || m)
  },
  {
    name: "warehouse-labels-retail-labels",
    path: "/warehouse/labels/retail-labels",
    component: () => import("/opt/buildhome/repo/pages/warehouse/labels/retail-labels/index.vue").then(m => m.default || m)
  },
  {
    name: "warehouse-logistics-containers",
    path: "/warehouse/logistics-containers",
    component: () => import("/opt/buildhome/repo/pages/warehouse/logistics-containers/index.vue").then(m => m.default || m)
  },
  {
    name: "warehouse-movements-bin-transfer",
    path: "/warehouse/movements/bin-transfer",
    component: () => import("/opt/buildhome/repo/pages/warehouse/movements/bin-transfer/index.vue").then(m => m.default || m)
  },
  {
    name: "warehouse-movements-goods-in-transit",
    path: "/warehouse/movements/goods-in-transit",
    component: () => import("/opt/buildhome/repo/pages/warehouse/movements/goods-in-transit/index.vue").then(m => m.default || m)
  },
  {
    name: "warehouse-movements-immediate-transfer",
    path: "/warehouse/movements/immediate-transfer",
    component: () => import("/opt/buildhome/repo/pages/warehouse/movements/immediate-transfer/index.vue").then(m => m.default || m)
  },
  {
    name: "warehouse-picking-dashboard",
    path: "/warehouse/picking-dashboard",
    component: () => import("/opt/buildhome/repo/pages/warehouse/picking-dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "warehouse-picking-details",
    path: "/warehouse/picking-details",
    component: () => import("/opt/buildhome/repo/pages/warehouse/picking-details/index.vue").then(m => m.default || m)
  },
  {
    name: "warehouse-shipping-dashboard",
    path: "/warehouse/shipping-dashboard",
    component: () => import("/opt/buildhome/repo/pages/warehouse/shipping-dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "warehouse-shipping-details",
    path: "/warehouse/shipping-details",
    component: () => import("/opt/buildhome/repo/pages/warehouse/shipping-details/index.vue").then(m => m.default || m)
  },
  {
    name: "warehouse-stock-take",
    path: "/warehouse/stock-take",
    component: () => import("/opt/buildhome/repo/pages/warehouse/stock-take/index.vue").then(m => m.default || m)
  },
  {
    name: "warehouse-warehouse-transfers",
    path: "/warehouse/warehouse-transfers",
    component: () => import("/opt/buildhome/repo/pages/warehouse/warehouse-transfers/index.vue").then(m => m.default || m)
  }
]