import wretch from 'wretch'
import { dedupe, throttlingCache } from 'wretch/middlewares'
import FormDataAddon from 'wretch/addons/formData'
import QueryStringAddon from 'wretch/addons/queryString'
import httpErrorHelper from '../factories/api/error.helper'
import { logout } from '~/features/auth/api'
import useAuthStore from '~/features/auth/store'
import * as FrApiErrorHelpers from '~/factories/api/azure.api.error.helper'

export default function (
  target:
    | 'permissionsAPI'
    | 'FsyncAPI'
    | 'FrAPI'
    | 'FrAuthAPI'
    | 'frSysproAPI'
    | 'frSysproAuth'
) {
  let url: string | undefined
  let mode: string | undefined
  let credentials: string | undefined
  let tokenTarget: 'fsync' | 'azure' | 'syspro' | undefined

  const ftoken = useCookie('ftoken')
  const atoken = useTokenSwitch('azure')
  const user = computed(() => useAuthStore().user)

  if (target === 'FsyncAPI') {
    url = process.env.FR_FSYNC_API_URL
    // mode = 'no-cors'
    tokenTarget = 'fsync'
  }

  if (target === 'FrAPI') {
    url = process.env.FR_API_URL
    tokenTarget = 'azure'
  }

  if (target === 'permissionsAPI') {
    url = process.env.FR_PERMISSIONS_URL
    credentials = 'include'
    tokenTarget = 'azure'
  }

  if (target === 'FrAuthAPI') {
    url = process.env.FR_AUTH_URL
    tokenTarget = 'azure'
  }

  if (target === 'frSysproAPI') {
    url = process.env.FR_API_URL
    tokenTarget = 'syspro'
  }

  if (target === 'frSysproAuth') {
    url = process.env.FR_AUTH_URL
    tokenTarget = 'syspro'
  }

  const errorHelper =
    target === 'FsyncAPI' || target === 'permissionsAPI'
      ? httpErrorHelper
      : FrApiErrorHelpers.httpErrorHelper

  return wretch(url)
    .options({ mode, credentials })
    .content('application/json')
    .accept('application/json, text/plain, */*')
    .auth(`Bearer ${useTokenSwitch(tokenTarget)}`)
    .addon(QueryStringAddon)
    .addon(FormDataAddon)
    .middlewares([
      dedupe(),
      throttlingCache({
        throttle: 3000
      }),
      (next) => async (url, opts) => {
        if (target === 'FsyncAPI') {
          if (!ftoken.value) {
            const token = await wretch(process.env.FR_PERMISSIONS_URL)
              .options({
                ...opts.options,
                credentials: 'include'
              })
              .headers({
                Authorization: `Bearer ${atoken}`
              })
              .url('/auth/fsync/token')
              .post()
              .error(401, async () => {
                await logout()
              })
              .json<string>()
              .then((res) => res)
              .catch((err) => err)

            ftoken.value = token
          }

          return await next(url, {
            ...opts,
            headers: {
              ...opts.headers,
              Authorization: `Bearer ${ftoken.value}`
            }
          })
        }
        return await next(url, {
          ...opts,
          headers: opts.headers
        })
      }
    ])
    .catcher(400, errorHelper)
    .catcher(401, (err) => {
      errorHelper(err)
    })
    .catcher(403, errorHelper)
    .catcher(404, errorHelper)
    .catcher(422, errorHelper)
    .catcher(500, errorHelper)
    .catcher(502, errorHelper)
    .defer((w, url, options) => {
      return user.value?.id
        ? w.query({ campfire_user_id: user.value?.id, ...options.query })
        : w
    })
    .catcherFallback((err) => {
      errorHelper(err)
      throw err
    })
}
