// import type { RouteMeta } from 'vue-router'
import type { PageMeta } from 'nuxt/app'

/**
 * Represents the metadata for the permissions admin page
 */
const permissionsAdminPageMeta: PageMeta = {
  name: 'admin.permissions',
  title: 'Permissions',
  path: '/admin/permissions',
  description: {
    name: 'description',
    content: 'Admin - Permissions'
  },
  showOnNav: true,
  order: 3,
  requiresAuth: true,
  show: true,
  icon: 'fas fa-user-shield',
  permissions: ['admin permissions | view', 'admin roles | view']
}

export default permissionsAdminPageMeta
