/**
 * ================================================
 * Warehouse -> Labels -> Bin Labels -> API
 * This factory contains all the methods for interacting with the warehouse bin labels API.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import { type UseMutationOptions, useMutation } from '@tanstack/vue-query'

/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'

/**
 * ================================================
 * API Routes
 * ================================================
 */

/**
 * create bin label - Call
 */
const printBinLabel = async (
  payload: T.warehouseCreateBinLabelRequest
): Promise<T.warehouseCreateBinLabelResponse> =>
  await useWretch('FrAPI')
    .url('/warehouse/Labels/binLabelFile')
    .query({
      pageSize: payload.pageSize,
      Inline: true
    })
    .json(payload.bins)
    .post()
    .blob()
    .then((res) => {
      const blob = window.URL.createObjectURL(
        new Blob([res], { type: 'application/pdf' })
      )
      return blob
    })
    .catch((err) => err)

/**
 * create bin label - Mutation
 */
const useCreateBinLabel = (
  options?: UseMutationOptions<
    T.warehouseCreateBinLabelResponse,
    unknown,
    T.warehouseCreateBinLabelRequest,
    Error
  >
) =>
  useMutation({
    mutationFn: printBinLabel,
    ...options
  })

/**
 * Get bin label - Call
 */
const getBinLabel = async (
  payload: T.warehouseGetBinLabelRequest
): Promise<T.warehouseGetBinLabelResponse> =>
  await useWretch('FrAPI')
    .url('/warehouse/Labels/binLabelFile')
    .query({
      pageSize: payload.pageSize,
      Inline: true
    })
    .json(payload.bins)
    .post()
    .blob()
    .then((res) => {
      const blob = window.URL.createObjectURL(
        new Blob([res], { type: 'application/pdf' })
      )

      return blob
    })
    .catch((err) => err)

/**
 * Get bin label - Mutation
 */
const useGetBinLabels = (
  options?: UseMutationOptions<
    T.warehouseGetBinLabelResponse,
    unknown,
    T.warehouseGetBinLabelRequest,
    Error
  >
) =>
  useMutation({
    mutationFn: getBinLabel,
    ...options
  })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  useCreateBinLabel,
  useGetBinLabels
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const warehouseBinLabelsAPIFactory = {
  queries,
  mutations
}
