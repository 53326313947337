/**
 * ================================================
 * Admin API Factory
 * This factory contains all the methods for interacting with the admin feature.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import {
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery
} from '@tanstack/vue-query'
/**
 * ================================================
 * Custom packages
 * ================================================
 */
import type * as T from './types'

/**
 * Fetch Users
 *
 * Custom hook for fetching users.
 * @param options - Options for the useQuery hook.
 * @returns The result of the useQuery hook.
 */
export const useFetchUsers = (
  options?: UseQueryOptions<T.fetchUsersResponse>
) =>
  useQuery({
    queryKey: ['users'],
    queryFn: () =>
      useWretch('permissionsAPI')
        .get('/users')
        .json<T.fetchUsersResponse>()
        .then((res) => res)
        .catch((err) => err),
    refetchOnWindowFocus: false,
    ...options
  })

/**
 * Fetch User
 *
 * Custom hook for fetching a user.
 * @param payload - The payload object containing the user ID and optional query options.
 * @returns The result of the useQuery hook for fetching the user.
 */
const useFetchUser = (payload: {
  id: ComputedRef<number>
  options?: UseQueryOptions<T.fetchUserResponse>
}) =>
  useQuery({
    queryKey: ['user', payload.id],
    queryFn: () =>
      useWretch('permissionsAPI')
        .get(`/users/${payload.id.value}`)
        .json<T.fetchUserResponse>()
        .then((res) => res)
        .catch((err) => err),
    refetchOnWindowFocus: false,
    ...payload.options
  })

/**
 * Fetch Roles
 *
 * Custom hook for fetching roles.
 * @param options - Options for the useQuery hook.
 * @returns The result of the useQuery hook.
 */
const useFetchRoles = (options?: UseQueryOptions<T.fetchRolesResponse>) =>
  useQuery({
    queryKey: ['roles'],
    queryFn: () =>
      useWretch('permissionsAPI')
        .get('/roles')
        .json<T.fetchRolesResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options
  })

/**
 * Fetch Role
 *
 * Custom hook for fetching role data.
 * @param payload - The payload object.
 * @param payload.id - The ID of the role to fetch.
 * @param payload.options - Optional query options.
 * @returns The result of the useQuery hook.
 */
const useFetchRole = (payload: {
  id: ComputedRef<number>
  options?: UseQueryOptions<T.fetchRoleResponse>
}) =>
  useQuery({
    queryKey: ['role', payload.id],
    queryFn: () =>
      useWretch('permissionsAPI')
        .get(`/roles/${payload.id.value}`)
        .json<T.fetchRoleResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...payload.options
  })

/**
 * Fetch Permissions
 *
 * Custom hook for fetching permissions.
 * @param options - The options for the query.
 * @returns The result of the query.
 */
const useFetchPermissions = (
  options?: UseQueryOptions<T.fetchPermissionsResponse>
) =>
  useQuery({
    queryKey: ['permissions'],
    queryFn: () =>
      useWretch('permissionsAPI')
        .get('/permissions')
        .json<T.fetchPermissionsResponse>()
        .then((res) => {
          return res.map((permission) => {
            return {
              ...permission,
              roleCategory: permission.name.split(' ')[0]
            }
          })
        })
        .catch((err) => err),
    ...options
  })

/**
 * Get Permission
 *
 * Custom hook for fetching permission data.
 * @param payload - The payload object.
 * @param payload.id - The ID of the permission.
 * @param payload.options - Optional query options.
 * @returns The result of the useQuery hook.
 */
const useFetchPermission = (payload: {
  id: ComputedRef<number>
  options?: UseQueryOptions<T.fetchPermissionResponse>
}) =>
  useQuery({
    queryKey: ['permission', payload.id],
    queryFn: () =>
      useWretch('permissionsAPI')
        .get(`/permissions/${payload.id.value}`)
        .json<T.fetchPermissionResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...payload.options
  })

/**
 * =========================
 * Regions
 * =========================
 */
/**
 * Fetch Regions
 *
 * Custom hook for fetching regions.
 * @param options - The options for the query.
 * @returns The result of the query.
 */
const useFetchRegions = (options?: UseQueryOptions<T.fetchRegionsResponse>) =>
  useQuery({
    queryKey: ['regions'],
    queryFn: () =>
      useWretch('permissionsAPI')
        .get('/regions')
        .json<T.fetchRegionsResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options
  })

/**
 * Get Region
 *
 * Custom hook for fetching region data.
 * @param payload - The payload object.
 * @param payload.id - The ID of the region.
 * @param payload.options - Optional query options.
 * @returns The result of the useQuery hook.
 */
const useFetchRegion = (payload: {
  id: ComputedRef<number>
  options?: UseQueryOptions<T.fetchRegionResponse>
}) =>
  useQuery({
    queryKey: ['region', payload.id],
    queryFn: () =>
      useWretch('permissionsAPI')
        .get(`/regions/${payload.id.value}`)
        .json<T.fetchRegionResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...payload.options
  })

/**
 * Add Region
 */
const useAddRegion = (
  options?: UseMutationOptions<
    T.addRegionResponse,
    Error,
    T.addRegionRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('permissionsAPI')
        .url('/regions')
        .json(payload)
        .post()
        .json<T.addRegionResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options,
    onError: (error) =>
      notifier({
        message: error.message,
        type: 'error'
      }),
    onSuccess: () =>
      notifier({
        message: 'Region successfully added.',
        type: 'success'
      })
  })

/**
 * Update Region
 */

const useUpdateRegion = (
  options?: UseMutationOptions<
    T.updateRegionResponse,
    Error,
    T.updateRegionRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('permissionsAPI')
        .url(`/regions/${payload.id}`)
        .json(payload)
        .put()
        .json<T.updateRegionResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options,
    onError: (error) =>
      notifier({
        message: error.message,
        type: 'error'
      }),
    onSuccess: () =>
      notifier({
        message: 'Region successfully updated.',
        type: 'success'
      })
  })

/**
 * Delete Region
 */
const useDeleteRegion = (
  options?: UseMutationOptions<
    T.deleteRegionResponse,
    Error,
    T.deleteRegionRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('permissionsAPI')
        .url(`/regions/${payload.id}`)
        .delete()
        .json<T.deleteRegionResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options,
    onError: (error) =>
      notifier({
        message: error.message,
        type: 'error'
      }),
    onSuccess: () =>
      notifier({
        message: 'Region successfully deleted.',
        type: 'success'
      })
  })

/**
 * Add region to user
 */
const useAddRegionToUser = (
  options?: UseMutationOptions<
    T.addRegionToUserResponse,
    Error,
    T.addRegionToUserRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('permissionsAPI')
        .url('/users/user_region_add')
        .json(payload)
        .post()
        .json<T.addRegionToUserResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options,
    onError: (error) =>
      notifier({
        message: error.message,
        type: 'error'
      })
  })

/**
 * Remove region from user
 */
const useRemoveRegionFromUser = (
  options?: UseMutationOptions<
    T.removeRegionFromUserResponse,
    Error,
    T.removeRegionFromUserRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('permissionsAPI')
        .url('/users/user_region_remove')
        .json(payload)
        .post()
        .json<T.removeRegionFromUserResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options,
    onError: (error) =>
      notifier({
        message: error.message,
        type: 'error'
      })
  })

/**
 * Add User
 *
 * Custom hook for adding a new user.
 * @param payload - The payload object.
 * @param payload.newUser - The new user to add.
 * @param payload.options - Optional mutation options.
 * @returns The result of the useMutation hook.
 */
const useAddUser = (payload: {
  newUser?: T.addUserRequest
  options?: UseMutationOptions<
    T.addUserResponse,
    Error,
    T.addUserRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('permissionsAPI')
        .url('/users')
        .json(payload)
        .post()
        .json<T.addUserResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...payload.options,
    onError: (error) =>
      notifier({
        message: error.message,
        type: 'error'
      }),
    onSuccess: () =>
      notifier({
        message: 'User successfully added.',
        type: 'success'
      })
  })

/**
 * Update User
 *
 * Custom hook for updating a user.
 * @param payload - The payload object.
 * @param payload.updatedUser - The updated user.
 * @param payload.options - Optional mutation options.
 */
const useUpdateUser = (
  options?: UseMutationOptions<
    T.updateUserResponse,
    Error,
    T.updateUserRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('permissionsAPI')
        .url(`/users/${payload.id}`)
        .json(payload)
        .put()
        .json<T.updateUserResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options,
    onError: (error) =>
      notifier({
        message: error.message,
        type: 'error'
      })
  })

/**
 * Update User
 *
 * Custom hook for updating a user.
 * @param payload - The payload object.
 * @param payload.updatedUser - The updated user.
 * @param payload.options - Optional mutation options.
 */
const useUpdateUserTheme = (
  options?: UseMutationOptions<
    T.updateUserThemeResponse,
    Error,
    T.updateUserThemeRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('permissionsAPI')
        .url(`/users/theme/${payload.id}`)
        .json({
          themeStyle: payload.themeStyle,
          themeColor: payload.themeColor
        })
        .put()
        .json<T.updateUserThemeResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options,
    onError: (error) =>
      notifier({
        message: error.message,
        type: 'error'
      })
  })

/**
 * Add permission to user
 *
 * Custom hook for adding a permission to a user.
 * @param payload - The payload object.
 * @param payload.userId - The ID of the user to add the permission to.
 * @param payload.permissionId - The ID of the permission to add.
 * @param payload.options - Optional mutation options.
 * @returns The result of the useMutation hook.
 */
const useAddPermissionToUser = (
  options?: UseMutationOptions<
    T.addPermissionToUserResponse,
    Error,
    T.addPermissionToUserRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('permissionsAPI')
        .url('/users/user_permission_add')
        .json(payload)
        .post()
        .json<T.addPermissionToUserResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options,
    onError: (error) =>
      notifier({
        message: error.message,
        type: 'error'
      })
  })

/**
 * Remove permission from user
 *
 * Custom hook for removing a permission from a user.
 * @param payload - The payload object.
 * @param payload.userId - The ID of the user to remove the permission from.
 * @param payload.permissionId - The ID of the permission to remove.
 * @param payload.options - Optional mutation options.
 * @returns The result of the useMutation hook.
 */
const useRemovePermissionFromUser = (
  options?: UseMutationOptions<
    T.removePermissionFromUserResponse,
    Error,
    T.removePermissionFromUserRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('permissionsAPI')
        .url('/users/user_permission_remove')
        .json(payload)
        .post()
        .json<T.removePermissionFromUserResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options,
    onError: (error) =>
      notifier({
        message: error.message,
        type: 'error'
      })
  })

/**
 * Add role to user
 *
 * Custom hook for adding a role to a user.
 * @param payload - The payload object.
 * @param payload.userId - The ID of the user to add the role to.
 * @param payload.roleId - The ID of the role to add.
 * @param payload.options - Optional mutation options.
 * @returns The result of the useMutation hook.
 */
const useAddRoleToUser = (
  options?: UseMutationOptions<
    T.addRoleToUserResponse,
    Error,
    T.addRoleToUserRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('permissionsAPI')
        .url('/users/user_role_add')
        .json(payload)
        .post()
        .json<T.addRoleToUserResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options,
    onError: (error) =>
      notifier({
        message: error.message,
        type: 'error'
      })
  })

/**
 * Remove role from user
 *
 * Custom hook for removing a role from a user.
 * @param payload - The payload object.
 * @param payload.userId - The ID of the user to remove the role from.
 * @param payload.roleId - The ID of the role to remove.
 * @param payload.options - Optional mutation options.
 * @returns The result of the useMutation hook.
 */
const useRemoveRoleFromUser = (
  options?: UseMutationOptions<
    T.removeRoleFromUserResponse,
    Error,
    T.removeRoleFromUserRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('permissionsAPI')
        .url('/users/user_role_remove')
        .json(payload)
        .post()
        .json<T.removeRoleFromUserResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options,
    onError: (error) =>
      notifier({
        message: error.message,
        type: 'error'
      })
  })

/**
 * submit Users Roles Permissions
 *
 * Custom hook for submitting users, roles, and permissions to the server.
 * @param payload - The payload object.
 * @param payload.userIds - The IDs of the users to update.
 * @param payload.categoryIds - The IDs of the roles to update.
 * @param payload.permissionIds - The IDs of the permissions to update.
 * @param payload.type - The type of update to perform.
 * @param payload.options - Optional mutation options.
 * @returns The result of the useMutation hook.
 */
const useSubmitUsersRolesPermissions = (
  options?: UseMutationOptions<
    T.submitUsersRolesPermissionsResponse,
    Error,
    T.submitUsersRolesPermissionsRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('permissionsAPI')
        .url(`/users/${payload.type}_permissions_roles_to_users`)
        .json({
          userIds: payload.userIds,
          categoryIds: payload.categoryIds,
          permissionIds: payload.permissionIds,
          regionIds: payload.regionIds
        })
        .post()
        .json<T.submitUsersRolesPermissionsResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options,
    onError: (error) =>
      notifier({
        message: error.message,
        type: 'error'
      })
  })

/**
 * add Role
 *
 * Custom hook for adding a new role.
 * @param payload - The payload object.
 * @param payload.newRole - The new role to add.
 * @param payload.options - Optional mutation options.
 * @returns The result of the useMutation hook.
 */
const useAddRole = (
  options?: UseMutationOptions<
    T.addRoleResponse,
    Error,
    T.addRoleRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('permissionsAPI')
        .url('/roles')
        .json(payload)
        .post()
        .json<T.addRoleResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options,
    onError: (error) =>
      notifier({
        message: error.message,
        type: 'error'
      }),
    onSuccess: () =>
      notifier({
        message: 'Role successfully added.',
        type: 'success'
      })
  })

/**
 * update Role
 *
 * Custom hook for updating a role.
 * @param payload - The payload object.
 * @param payload.updatedRole - The updated role.
 * @param payload.options - Optional mutation options.
 * @returns The result of the useMutation hook.
 */
const useUpdateRole = (
  options?: UseMutationOptions<
    T.updateRoleResponse,
    Error,
    T.updateRoleRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('permissionsAPI')
        .url(`/roles/${payload.id}`)
        .json(payload)
        .put()
        .json<T.addRoleResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options,
    onError: (error) =>
      notifier({
        message: error.message,
        type: 'error'
      }),
    onSuccess: () =>
      notifier({
        message: 'Role successfully updated.',
        type: 'success'
      })
  })

/**
 * delete Role
 *
 * Custom hook for deleting a role.
 * @param payload - The payload object.
 * @param payload.id - The ID of the role to delete.
 * @param payload.options - Optional mutation options.
 * @returns The result of the useMutation hook.
 */
const useDeleteRole = (
  options?: UseMutationOptions<
    T.deleteRoleResponse,
    Error,
    T.deleteRoleRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('permissionsAPI')
        .url(`/roles/${payload.id}`)
        .delete()
        .json<T.deleteRoleResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options,
    onError: (error) =>
      notifier({
        message: error.message,
        type: 'error'
      }),
    onSuccess: () =>
      notifier({
        message: 'Role successfully deleted.',
        type: 'success'
      })
  })

/**
 * add Permission To Role
 *
 * Custom hook for adding a permission to a role.
 * @param payload - The payload object.
 * @param payload.roleId - The ID of the role to add the permission to.
 * @param payload.permissionId - The ID of the permission to add.
 * @param payload.options - Optional mutation options.
 * @returns The result of the useMutation hook.
 */
const useAddPermissionToRole = (
  options?: UseMutationOptions<
    T.addPermissionToRoleResponse,
    Error,
    T.addPermissionToRoleRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('permissionsAPI')
        .url('/permissions/add_to_category')
        .json({
          categoryId: payload.roleId,
          permissionId: payload.permissionId
        })
        .post()
        .json<T.addPermissionToRoleResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options,
    onError: (error) =>
      notifier({
        message: error.message,
        type: 'error'
      })
  })

/**
 * removePermissionFromRole
 *
 * Custom hook for removing a permission from a role.
 * @param payload - The payload object.
 * @param payload.roleId - The ID of the role to remove the permission from.
 * @param payload.permissionId - The ID of the permission to remove.
 * @param payload.options - Optional mutation options.
 * @returns The result of the useMutation hook.
 */
const useRemovePermissionFromRole = (
  options?: UseMutationOptions<
    T.removePermissionFromRoleResponse,
    Error,
    T.removePermissionFromRoleRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('permissionsAPI')
        .url('/permissions/remove_from_category')
        .json({
          categoryId: payload.roleId,
          permissionId: payload.permissionId
        })
        .post()
        .json<T.removePermissionFromRoleResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options,
    onError: (error) =>
      notifier({
        message: error.message,
        type: 'error'
      })
  })

/**
 * addPermission
 *
 * Custom hook for adding a permission.
 * @param payload - The payload object.
 * @param payload.newPermission - The new permission to add.
 * @param payload.options - Optional mutation options.
 * @returns The result of the useMutation hook.
 */
const useAddPermission = (
  options?: UseMutationOptions<
    T.addPermissionResponse,
    Error,
    T.addPermissionRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('permissionsAPI')
        .url('/permissions')
        .json(payload)
        .post()
        .json<T.addPermissionResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options,
    onError: (error) =>
      notifier({
        message: error.message,
        type: 'error'
      }),
    onSuccess: () =>
      notifier({
        message: 'Permission successfully added.',
        type: 'success'
      })
  })

/**
 * updatePermission
 *
 * Custom hook for updating a permission.
 * @param payload - The payload object.
 * @param payload.updatedPermission - The updated permission.
 * @param payload.options - Optional mutation options.
 * @returns The result of the useMutation hook.
 */
const useUpdatePermission = (
  options?: UseMutationOptions<
    T.updatePermissionResponse,
    Error,
    T.updatePermissionRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('permissionsAPI')
        .url(`/permissions/${payload.id}`)
        .json(payload)
        .put()
        .json<T.addPermissionResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options,
    onError: (error) =>
      notifier({
        message: error.message,
        type: 'error'
      }),
    onSuccess: () =>
      notifier({
        message: 'Permission successfully updated.',
        type: 'success'
      })
  })

/**
 * updatePermissions
 *
 * Custom hook for updating multiple permissions.
 * @param payload - The payload object.
 * @param payload.updatedPermissions - The updated permissions.
 * @param payload.options - Optional mutation options.
 * @returns The result of the useMutation hook.
 */
const useUpdatePermissions = (
  options?: UseMutationOptions<
    T.addPermissionResponse[],
    Error,
    T.addPermissionRequest[],
    unknown
  >
) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('permissionsAPI')
        .url('/permissions/update_many')
        .json(payload)
        .put()
        .json<T.addPermissionResponse[]>()
        .then((res) => res)
        .catch((err) => err),
    ...options,
    onError: (error) =>
      notifier({
        message: error.message,
        type: 'error'
      })
  })

/**
 * deletePermission
 *
 * Custom hook for deleting a permission.
 * @param payload - The payload object.
 * @param payload.id - The ID of the permission to delete.
 * @param payload.options - Optional mutation options.
 * @returns The result of the useMutation hook.
 */
const useDeletePermission = (
  options?: UseMutationOptions<
    T.deletePermissionResponse,
    Error,
    T.deletePermissionRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('permissionsAPI')
        .url(`/permissions/${payload.id}`)
        .delete()
        .json<T.deletePermissionResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options,
    onError: (error) =>
      notifier({
        message: error.message,
        type: 'error'
      })
  })

/**
 * ================================================
 * Service Messages
 * ================================================
 */

/**
 * Get Service Messages
 */
export const useFetchServiceMessages = (
  options?: UseQueryOptions<T.getServiceMessagesResponse>
) =>
  useQuery<T.getServiceMessagesResponse>({
    queryKey: ['service_messages'],
    queryFn: () =>
      useWretch('permissionsAPI')
        .url('/service_messages')
        .get()
        .json<T.getServiceMessagesResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options
  })

/**
 * Create Service Message
 */
export const useCreateServiceMessage = (
  options?: UseMutationOptions<
    T.createServiceMessageResponse,
    Error,
    T.createServiceMessageRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('permissionsAPI')
        .url('/service_messages')
        .json(payload)
        .post()
        .json<T.createServiceMessageResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options,
    onError: (error) =>
      notifier({
        message: error.message,
        type: 'error'
      }),
    onSuccess: () =>
      notifier({
        message: 'Service message successfully created.',
        type: 'success'
      })
  })

/**
 * Delete Service Message
 */
export const useDeleteServiceMessage = (
  options?: UseMutationOptions<
    T.deleteServiceMessageResponse,
    Error,
    T.deleteServiceMessageRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('permissionsAPI')
        .url('/service_messages/')
        .json({
          id: payload.id
        })
        .delete()
        .json<T.deleteServiceMessageResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options,
    onError: (error) =>
      notifier({
        message: error.message,
        type: 'error'
      }),
    onSuccess: () =>
      notifier({
        message: 'Service message successfully deleted.',
        type: 'success'
      })
  })
/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  /**
   * Users
   */
  useFetchUsers,
  useFetchUser,
  /**
   * Roles
   */
  useFetchRoles,
  useFetchRole,
  /**
   * Permissions
   */
  useFetchPermissions,
  useFetchPermission,
  /**
   * Regions
   */
  useFetchRegions,
  useFetchRegion,
  /**
   * Service Messages
   */
  useFetchServiceMessages
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  /**
   * Users
   */
  useAddUser,
  useUpdateUser,
  useAddPermissionToUser,
  useRemovePermissionFromUser,
  useAddRoleToUser,
  useRemoveRoleFromUser,
  useSubmitUsersRolesPermissions,
  useUpdateUserTheme,
  /**
   * Roles
   */
  useAddRole,
  useUpdateRole,
  useDeleteRole,
  useAddPermissionToRole,
  useRemovePermissionFromRole,
  /**
   * Permissions
   */
  useAddPermission,
  useUpdatePermission,
  useUpdatePermissions,
  useDeletePermission,
  /**
   * Regions
   */
  useAddRegion,
  useUpdateRegion,
  useDeleteRegion,
  useAddRegionToUser,
  useRemoveRegionFromUser,

  /**
   * Service Messages
   */
  useCreateServiceMessage,
  useDeleteServiceMessage
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const adminApiFactory = {
  queries,
  mutations
}
