import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the sales - credit memos page.
 */
const salesCreditMemosMeta: RouteMeta = {
  name: 'sales.credit-memos',
  title: 'Credit Memos',
  path: '/sales/credit-memos',
  order: 7,
  requiresAuth: true,
  show: true,
  permissions: ['sales credit memos | view']
}

export default salesCreditMemosMeta
