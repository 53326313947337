/**
 * ================================================
 * Catalog API Factory
 * This factory contains all the methods for interacting with the catalog feature.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import qs from 'qs'
import type {
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions
} from '@tanstack/vue-query'

import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/vue-query'

/**
 * ================================================
 * Custom packages
 * ================================================
 */
import type * as T from './types'

import useAuthStore from '~/features/auth/store'

/**
 * API Routes
 */
// const API_PREFIX = '/api/v2/catalog'
const VEHICLE_PREFIX = '/api/v1/vehicle'

// Products Categories

/**
 * @description Get Products Categories
 * @returns {Promise<productsCategoriesResponse['categories']>}
 */
export const productsCategories = async (): Promise<
  T.productsCategoriesResponse['categories']
> => {
  const route = await useAuthStore().getRoutesByName(
    'FSYNC',
    'catalog-products-categories'
  )

  return await useWretch('permissionsAPI')
    .get(`/router/FSYNC/${route?.id}`)
    .json<T.productsCategoriesResponse>()
    .then(({ categories }) => categories)
    .catch((err) => err)
}

/**
 * getProductsCategories hook
 */
const useGetProductsCategories = (
  options?: UseQueryOptions<T.productsCategoriesResponse['categories']>
) =>
  useQuery({
    queryKey: ['productsCategories'],
    queryFn: productsCategories,
    // Refetch every 5 minutes
    refetchInterval: 1000 * 60 * 5,
    ...options
  })

/**
 * @description Get Products by Category
 * @param categoryId
 * @param params
 * @returns {Promise<productsByCategoryResponse>}
 */
export const productsByCategory = async (
  params: T.productsByCategoryRequest = {
    categoryId: 3,
    search: null,
    showInactive: 0,
    smartSearch: 0,
    searchBySku: 0,
    sortBy: 'sku',
    desc: 0,
    page: 1,
    skus: null
  }
): Promise<T.productsByCategoryResponse> => {
  const route = await useAuthStore().getRoutesByName(
    'FSYNC',
    'catalog-products-categories-categoryId'
  )

  return await useWretch('permissionsAPI')
    .url(`/router/FSYNC/${route?.id}/${params.categoryId}`)
    .query(qs.stringify(params, { encode: false, skipNulls: true }))
    .get()
    .json<T.productsByCategoryResponse>()
    .then((res) => res)
    .catch((err) => err)
}

/**
 * getProductsByCategory hook
 */
const useGetProductsByCategory = (payload: {
  params: ComputedRef<T.productsByCategoryRequest>
  options?: UseInfiniteQueryOptions<T.productsByCategoryResponse>
}) =>
  useInfiniteQuery({
    queryKey: ['productsByCategory', payload.params],
    queryFn: ({ pageParam = payload.params.value.page }) =>
      productsByCategory({
        ...toRaw(payload.params.value),
        page: (pageParam as number) || 1
      }),
    // @ts-expect-error This works but usequery complains
    select: (data) => ({
      pages: data.pages.flatMap((x) => x),
      pageParams: data.pageParams
    }),
    refetchInterval: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
    initialData: {
      pages: [],
      pageParams: []
    },
    getNextPageParam: (lastPage) => lastPage?.page + 1 || undefined,
    initialPageParam: 1,
    ...payload.options
  })

/**
 * @description Get Products by query - Call
 * @param query
 * @returns {Promise<T.productsByCategoryResponse>}
 */
export const searchProducts = async (
  query: string
): Promise<T.productsByCategoryResponse> => {
  const route = await useAuthStore().getRoutesByName(
    'FSYNC',
    'catalog-products-categories-search'
  )

  return await useWretch('permissionsAPI')
    .url(`/router/FSYNC/${route?.id}`)
    .query(qs.stringify({ query }, { encode: false, skipNulls: true }))
    .get()
    .json<T.productsByCategoryResponse>()
    .then((res) => res)
    .catch((err) => err)
}

/**
 * searchProducts hook
 */
const useSearchProducts = (payload: {
  query: ComputedRef<string>
  options?: UseQueryOptions<T.productsByCategoryResponse>
}) =>
  useQuery({
    queryKey: ['searchProducts', payload.query],
    queryFn: () => searchProducts(payload.query.value),
    ...payload.options
  })

/**
 * Get product visibility reasons - call
 */
export const getProductVisibility =
  async (): Promise<T.productsVisibilityResponse> =>
    await useWretch('FsyncAPI')
      .get('/api/v2/catalog/products/visibility/reasons')
      .json<T.productsVisibilityResponse>()
      .then((res) => res)
      .catch((err) => err)

/**
 * Get product visibility reasons - hook
 */
const useGetProductVisibility = (
  options?: UseQueryOptions<T.productsVisibilityResponse>
) =>
  useQuery({
    queryKey: ['productVisibility'],
    queryFn: getProductVisibility,
    ...options
  })

/**
 * postProductVisibility - call
 */
export const postProductVisibility = async (
  payload: T.postProductVisibilityRequest
) =>
  await useWretch('FsyncAPI')
    .body(payload)
    .post(`/products/visibility/${payload.sku}`)
    .json()
    .then((res) => res)
    .catch((err) => err)

/**
 * postProductVisibility - hook
 */
const usePostProductVisibility = (
  options?: UseMutationOptions<
    unknown,
    unknown,
    T.postProductVisibilityRequest,
    unknown
  >
) =>
  useMutation({
    mutationKey: ['postProductVisibility'],
    mutationFn: postProductVisibility,
    ...options
  })

// Vehicles

/**
 * getVehicleYears - call
 */
export async function getVehicleYears(): Promise<
  T.vehiclesYearsResponse['data']
> {
  return await useWretch('FsyncAPI')
    .get(`${VEHICLE_PREFIX}/years`)
    .json<T.vehiclesYearsResponse>()
    .then(({ data }) => data)
    .catch((err) => err)
}

/**
 * getVehicleYears hook
 */
const useGetVehicleYears = (
  options?: UseQueryOptions<T.vehiclesYearsResponse['data']>
) =>
  useQuery({
    queryKey: ['vehicleYears'],
    queryFn: getVehicleYears,
    ...options
  })

/**
 * getVehicleMakes - call
 */
export const getVehicleMakes = async (
  payload: T.vehiclesMakesRequest
): Promise<T.vehiclesMakesResponse['data']> =>
  await useWretch('FsyncAPI')
    .get(`${VEHICLE_PREFIX}/years/${payload.yearId}/makes`)
    .json<T.vehiclesMakesResponse>()
    .then(({ data }) => data)
    .catch((err) => err)

/**
 * getVehicleMakes hook
 */
const useGetVehicleMakes = (payload: {
  yearId: ComputedRef<number>
  options?: UseQueryOptions<T.vehiclesMakesResponse['data']>
}) =>
  useQuery({
    queryKey: ['vehicleMakes', payload.yearId],
    queryFn: () =>
      getVehicleMakes({
        yearId: payload.yearId.value
      }),
    ...payload.options
  })

/**
 * getVehicleModels - call
 */
export const getVehicleModels = async (
  yearId: number,
  makeId: number
): Promise<T.vehiclesModelsResponse['data']> =>
  await useWretch('FsyncAPI')
    .get(`${VEHICLE_PREFIX}/years/${yearId}/makes/${makeId}/models`)
    .json<T.vehiclesModelsResponse>()
    .then(({ data }) => data)
    .catch((err) => err)

/**
 * getVehicleModels hook
 */
const useGetVehicleModels = (payload: {
  yearId: ComputedRef<number>
  makeId: ComputedRef<number>
  options?: UseQueryOptions<T.vehiclesModelsResponse['data']>
}) =>
  useQuery({
    queryKey: ['vehicleModels', payload.yearId, payload.makeId],
    queryFn: () => getVehicleModels(payload.yearId.value, payload.makeId.value),
    ...payload.options
  })

/**
 * getVehicleOptions - call
 */
export const getVehicleOptions = async (
  payload: T.vehiclesOptionsRequest
): Promise<T.vehiclesOptionsResponse['data']> =>
  await useWretch('FsyncAPI')
    .get(
      `${VEHICLE_PREFIX}/years/${payload.yearId}/makes/${payload.makeId}/models/${payload.modelId}/options`
    )
    .json<T.vehiclesOptionsResponse>()
    .then(({ data }) => data)
    .catch((err) => err)

/**
 * getVehicleOptions hook
 */
const useGetVehicleOptions = (payload: {
  yearId: ComputedRef<number>
  makeId: ComputedRef<number>
  modelId: ComputedRef<number>
  options?: UseQueryOptions<T.vehiclesOptionsResponse['data']>
}) =>
  useQuery({
    queryKey: [
      'vehicleOptions',
      payload.yearId,
      payload.makeId,
      payload.modelId
    ],
    queryFn: () =>
      getVehicleOptions({
        yearId: payload.yearId.value,
        makeId: payload.makeId.value,
        modelId: payload.modelId.value
      }),
    ...payload.options
  })

/**
 * getVehicleProducts - call
 */
export const getVehicleProducts = async (
  payload: T.vehiclesProductsRequest
): Promise<T.vehiclesProductsResponse> =>
  await useWretch('FsyncAPI')
    .get(
      `${VEHICLE_PREFIX}/years/${payload.yearId}/makes/${payload.makeId}/models/${payload.modelId}/options/${payload.optionId}/products`
    )
    .json<T.vehiclesProductsResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * getVehicleProducts hook
 */
const useGetVehicleProducts = (payload: {
  yearId: ComputedRef<number>
  makeId: ComputedRef<number>
  modelId: ComputedRef<number>
  optionId: ComputedRef<number>
  options?: UseQueryOptions<T.vehiclesProductsResponse>
}) =>
  useQuery({
    queryKey: [
      'vehicleProducts',
      payload.yearId,
      payload.makeId,
      payload.modelId,
      payload.optionId
    ],
    queryFn: () =>
      getVehicleProducts({
        yearId: payload.yearId.value,
        makeId: payload.makeId.value,
        modelId: payload.modelId.value,
        optionId: payload.optionId.value
      }),
    ...payload.options
  })
/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetProductsCategories,
  useGetProductsByCategory,
  useSearchProducts,
  // Vehicles
  useGetVehicleYears,
  useGetVehicleMakes,
  useGetVehicleModels,
  useGetVehicleOptions,
  useGetVehicleProducts,
  // Product Specs
  // Product Visibility
  useGetProductVisibility
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  // Product Specs
  // Product Visibility
  usePostProductVisibility
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const catalogAPIFactory = {
  queries,
  mutations
}
