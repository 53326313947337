import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the sales - dealer map page.
 */
const salesDealerMapMeta: RouteMeta = {
  name: 'sales.dealer-map',
  title: 'Dealer Map',
  path: '/sales/dealer-map',
  order: 11,
  requiresAuth: true,
  show: true,
  permissions: ['sales dealer map | view']
}

export default salesDealerMapMeta
