import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the sales - Orders page.
 */
const salesOrdersMeta: RouteMeta = {
  name: 'sales.orders',
  title: 'Orders',
  path: '/sales/orders',
  order: 4,
  show: true,
  requiresAuth: true,
  permissions: ['sales orders | view']
}

export default salesOrdersMeta
