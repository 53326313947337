import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the b2c customers page.
 */
const b2cCustomersMeta: RouteMeta = {
  name: 'customers.b2c',
  title: 'B2C Customers',
  path: '/customers/b2c',
  description: {
    name: 'description',
    content: 'Customers - B2C Customers'
  },
  showOnNav: true,
  order: 1,
  requiresAuth: true,
  show: true,
  permissions: ['customers b2c customers | view']
}

export default b2cCustomersMeta
