import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the sales - scan credit card slip page.
 */
const salesScanCreditCardSlipMeta: RouteMeta = {
  name: 'sales.scan-credit-card-slip',
  title: 'Scan credit card slip',
  path: '/sales/scan-credit-card-slip',
  order: 9,
  requiresAuth: true,
  show: true,
  permissions: ['sales scan credit card slip | view']
}

export default salesScanCreditCardSlipMeta
