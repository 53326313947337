import gsap from 'gsap'

/**
 * ==============================
 * Animation helpers for Vue transitions
 * ==============================
 */

/**
 * ==============================
 * Settings for GSAP animations
 * ==============================
 */
const animDelay = 0.12
const animDuration = 0.4
const animTranslateY = 100

/**
 * ==============================
 * GSAP animations
 * ==============================
 */

/**
 * On enter animation
 */
export const anim_onEnter = (el: Element, done: () => void): void => {
  // @ts-expect-error dataset is missing in Element
  const delay = el.dataset.index * animDelay
  const anim = gsap.to(el, {
    opacity: 1,
    y: 0,
    duration: animDuration,
    delay: delay,
    onComplete: done
  })

  anim.eventCallback('onComplete', done)
}

/**
 * On leave animation
 */
export const anim_onLeave = (el: Element, done: () => void): void => {
  // @ts-expect-error dataset is missing in Element
  const delay = el.dataset.index * animDelay
  const anim = gsap.to(el, {
    opacity: 0,
    y: animTranslateY,
    duration: animDuration,
    delay: delay
  })

  anim.eventCallback('onComplete', done)
}

/**
 * On before enter animation
 */
export const anim_onBeforeEnter = (el: Element): void => {
  gsap.set(el, { opacity: 0, y: animTranslateY })
}

/**
 * On before leave animation
 */
export const anim_onBeforeLeave = (el: Element): void => {
  gsap.set(el, { opacity: 1, y: 0 })
}
