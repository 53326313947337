import type * as PRT from './performance-review.types'

async function createPerformanceReview(
  performanceReviewType: PRT.PerformanceReviewTypeValues,
  createPerformanceReviewRequest: PRT.CreatePerformanceReviewRequest
): Promise<PRT.CreatePerformanceReviewResponse | null> {
  return await useWretch('FrAPI')
    .post(
      JSON.stringify(createPerformanceReviewRequest),
      `/performance_review/PerformanceReviews/create/${performanceReviewType}`
    )
    .json<PRT.CreatePerformanceReviewResponse>()
    .then((res) => {
      return res
    })
    .catch((err) => {
      console.error('Error in createPerformanceReview:', err)
      return null
    })
}

async function updatePerformanceReview(
  performanceReviewId: number,
  updatePerformanceReviewRequest: PRT.UpdatePerformanceReviewRequest
): Promise<PRT.UpdatePerformanceReviewResponse | null> {
  return await useWretch('FrAPI')
    .put(
      JSON.stringify(updatePerformanceReviewRequest),
      `/performance_review/PerformanceReviews/${performanceReviewId}`
    )
    .json<PRT.UpdatePerformanceReviewResponse>()
    .then((res) => {
      return res
    })
    .catch((err) => {
      console.error('Error in updatePerformanceReview:', err)
      return null
    })
}

async function getPerformanceReview(
  performanceReviewId: number
): Promise<PRT.PerformanceReview | null> {
  return await useWretch('FrAPI')
    .get(`/performance_review/PerformanceReviews/${performanceReviewId}`)
    .json<PRT.PerformanceReview>()
    .then((res) => {
      return res
    })
    .catch((err) => {
      console.error('Error in getPerformanceReview:', err)
      return null
    })
}

async function getPerformanceReviewsList(
  performanceReviewsListRequest: PRT.PerformanceReviewsListRequest
): Promise<PRT.PerformanceReviewsListResponse | null> {
  return await useWretch('FrAPI')
    .post(
      JSON.stringify(performanceReviewsListRequest),
      '/performance_review/PerformanceReviews/list'
    )
    .json<PRT.PerformanceReviewsListResponse>()
    .then((res) => {
      return res
    })
    .catch((err) => {
      console.error('Error in getPerformanceReviewsList:', err)
      return null
    })
}

async function cancelPerformanceReview(
  performanceReviewId: number,
  cancelPerformanceReviewRequest: PRT.CancelPerformanceReviewRequest
): Promise<PRT.GenericResponse | null> {
  return await useWretch('FrAPI')
    .put(
      JSON.stringify(cancelPerformanceReviewRequest),
      `/performance_review/PerformanceReviews/${performanceReviewId}/cancel`
    )
    .json<PRT.GenericResponse>()
    .then((res) => {
      return res
    })
    .catch((err) => {
      console.error('Error in cancelPerformanceReview:', err)
      return null
    })
}

async function getEmployeeReviewStatusList(
  query: PRT.EmployeeReviewStatusQuery
): Promise<PRT.EmployeeReviewStatus[] | null> {
  return await useWretch('FrAPI')
    .query(query ?? {})
    .get('/performance_review/PerformanceReviews/employees/reviewStatus')
    .json<PRT.EmployeeReviewStatus[]>()
    .then((res) => {
      return res
    })
    .catch((err) => {
      console.error('Error in getEmployeeReviewStatusList:', err)
      return null
    })
}

export const performanceReview = {
  createPerformanceReview,
  updatePerformanceReview,
  getPerformanceReview,
  getPerformanceReviewsList,
  cancelPerformanceReview,
  getEmployeeReviewStatusList
}
