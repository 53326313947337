import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the catalog product positions page.
 */
const catalogProductPositionsMeta: RouteMeta = {
  name: 'catalog.product-positions',
  path: '/catalog/product-positions',
  title: 'Product Positions',
  description: {
    name: 'description',
    content: 'Catalog - Product Positions'
  },
  showOnNav: true,
  order: 3,
  requiresAuth: true,
  show: true,
  permissions: ['catalog product position | view']
}

export default catalogProductPositionsMeta
