import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Catalog - Product Specs page.
 */
const catalogProductSpecsMeta: RouteMeta = {
  name: 'catalog.product-specs',
  path: '/catalog/product-specs',
  title: 'Product Specs',
  description: {
    name: 'description',
    content: 'Catalog - Product Specs'
  },
  showOnNav: true,
  order: 3,
  show: true,
  requiresAuth: true,
  permissions: ['catalog product specs | view']
}

export default catalogProductSpecsMeta
