import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Sales - Point of Sale - Customer page
 */
const salesPointOfSaleCustomerMeta: RouteMeta = {
  name: 'sales.point-of-sale.customer',
  title: 'Point of Sale (Customer)',
  path: '/sales/point-of-sale/customer',
  order: 1,
  show: true,
  requiresAuth: true,
  permissions: ['sales point of sale customer | view'],
  description: {
    name: 'description',
    content: 'Sales - Point of Sale - Customer'
  }
}

export default salesPointOfSaleCustomerMeta
