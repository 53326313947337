import { type SysproStoreState } from './types'

export const useSysproStore = defineStore('Syspro Store', {
  state: (): SysproStoreState => ({
    sysproLoginModal: {
      sysproOperator: {
        company: null,
        userName: null
      },

      useCampfireUser: false
    },

    token: null,

    sysproCompanies: [
      {
        id: 'FRAU',
        description: 'Australia'
      },
      {
        id: 'FRDE',
        description: 'Germany'
      },
      {
        id: 'FRUS',
        description: 'United States of America'
      },
      {
        id: 'FRZA',
        description: 'South Africa'
      }
    ]
  })
})
