import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Content - Translations - M2PWA.
 */
const contentTranslationsM2PwaMeta: RouteMeta = {
  name: 'content.translations-m2pwa',
  title: 'Translations',
  path: '/content/translations/m2pwa',
  description: {
    name: 'description',
    content: 'Content - Translations - M2PWA'
  },
  showOnNav: true,
  isBeta: true,
  order: 2,
  show: true,
  requiresAuth: true,
  permissions: ['content translations | view']
}

export default contentTranslationsM2PwaMeta
