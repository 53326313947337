import type { RouteMeta } from 'vue-router'
import { useSysproStore } from '~/features/syspro/store/syspro-store'

/**
 * Represents the metadata for the Sales - Point of Sale - Sales Person page
 */
const salesPointOfSaleSalesMeta: RouteMeta = {
  name: 'sales.point-of-sale.sales',
  title: 'Point of Sale (Sales Person)',
  path: '/sales/point-of-sale/sales',
  order: 0,
  show: true,
  requiresAuth: true,
  keepalive: false,
  permissions: ['sales point of sale sales person | view'],
  beforeEnter: () => {
    useSysproStore()
  },
  showOnNav: true,
  description: {
    name: 'description',
    content: 'Sales - Point of Sale - Sales Person'
  }
}

export default salesPointOfSaleSalesMeta
