/**
 * ================================================
 * Warehouse -> Labels -> Product Labels -> API
 * This factory contains all the methods for interacting with the warehouse product labels API.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import {
  type QueryFunctionContext,
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery
} from '@tanstack/vue-query'

/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'

/**
 * ================================================
 * API Routes
 * ================================================
 */

/**
 * Get product info - Call
 */
const getProductInfo = async (
  context: QueryFunctionContext
): Promise<T.warehouseGetProductInfoResponse> =>
  await useWretch('FrAPI')
    .url(`/product/Product/detail/${context.queryKey[1]}`)
    .query({
      RetailOnly: true
    })
    .get()
    .json<T.warehouseGetProductInfoResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Get Product Info - Query
 */
const useGetProductInfo = (payload: {
  sku: ComputedRef<string>
  options?: UseQueryOptions<T.warehouseGetProductInfoResponse>
}) =>
  useQuery({
    queryKey: ['warehouseProductInfo_productLabels', payload.sku],
    queryFn: getProductInfo,
    ...payload.options
  })

/**
 * create product label - Call
 */
const printProductLabel = async (
  payload: T.warehouseCreateOuterLabelRequest
): Promise<T.warehouseCreateOuterLabelResponse> =>
  await useWretch('FrAPI')
    .url('/warehouse/Labels/componentLabelFile')
    .query({
      pageSize: payload?.pageSize
    })
    .json({
      sku: payload.stockCode,
      description: payload.description,
      orderNo: payload.orderNo,
      sizeAndWeight: payload.sizeAndWeight,
      barcode: payload.barcode,
      qty: payload.qty
    })
    .post()
    .blob()
    .then((res) => {
      const blob = window.URL.createObjectURL(
        new Blob([res], { type: 'application/pdf' })
      )

      return blob
    })
    .catch((err) => err)

/**
 * create product label - Mutation
 */
const useCreateProductLabel = (
  options?: UseMutationOptions<
    T.warehouseCreateOuterLabelResponse,
    unknown,
    T.warehouseCreateOuterLabelRequest,
    Error
  >
) =>
  useMutation({
    mutationFn: printProductLabel,
    ...options
  })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetProductInfo
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  useCreateProductLabel
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const warehouseProductLabelsAPIFactory = {
  queries,
  mutations
}
