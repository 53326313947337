import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the documents search page.
 */
const documentSearchMeta: RouteMeta = {
  name: 'documents.document-search',
  title: 'Document Search',
  path: '/documents/document-search',
  description: {
    name: 'description',
    content: 'Document - Search'
  },
  showOnNav: true,
  order: 0,
  requiresAuth: true,
  show: true,
  permissions: ['documents search | view']
}

export default documentSearchMeta
