/**
 * ================================================
 * factory -> Labels -> factory Labels -> API
 * This factory contains all the methods for interacting with the factory labels API.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import type { UseMutationOptions } from '@tanstack/vue-query'
import { useMutation } from '@tanstack/vue-query'

/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'

/**
 * ================================================
 * API Routes
 * ================================================
 */

/**
 * getFactoryLabels - Call
 */
const getFactoryLabels = async (
  payload: T.getFactoryLabelsRequest
): Promise<T.getFactoryLabelsResponse> =>
  await useWretch('FrAPI')
    .url(`/factory/FactoryLabel/${payload.job}/LSR/Base64`)
    .get()
    .text()
    .then((res) => {
      const blob = window.URL.createObjectURL(
        new Blob([blobHelper(res, payload.job, 'application/pdf')], {
          type: 'application/pdf'
        })
      )

      return blob
    })
    .catch((err) => err)

/**
 * getFactoryLabels - Mutation
 */
const useGetFactoryLabel = (
  options?: UseMutationOptions<
    T.getFactoryLabelsResponse,
    unknown,
    T.getFactoryLabelsRequest,
    Error
  >
) =>
  useMutation({
    mutationFn: getFactoryLabels,
    ...options
  })

async function getQrLabel(
  getQrLabelQuery: T.GetQrLabelQuery,
  getQrLabelRequest: T.GetQrLabelRequest
): Promise<Blob | null> {
  return await useWretch('FrAPI')
    .query(getQrLabelQuery ?? {})
    .post(
      JSON.stringify(getQrLabelRequest),
      '/warehouse/Labels/fittingInstructionQRLabelFile'
    )
    .blob()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getQrLabel:', err)
      return null
    })
}

async function getQrLabelBase64(
  getQrLabelBase64Query: T.GetQrLabelBase64Query,
  getQrLabelRequest: T.GetQrLabelRequest
): Promise<string | null> {
  return await useWretch('FrAPI')
    .query(getQrLabelBase64Query ?? {})
    .post(
      JSON.stringify(getQrLabelRequest),
      '/warehouse/Labels/fittingInstructionQRLabel'
    )
    .text()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getQrLabel:', err)
      return null
    })
}

async function getComponentLabelBase64(
  getComponentLabelBase64Query: T.GetComponentLabelBase64Query,
  getComponentLabelRequest: T.GetComponentLabelRequest
): Promise<string | null> {
  return await useWretch('FrAPI')
    .query(getComponentLabelBase64Query ?? {})
    .post(
      JSON.stringify(getComponentLabelRequest),
      '/warehouse/Labels/componentLabel'
    )
    .text()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getQrLabelBase64:', err)
      return null
    })
}

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  useGetFactoryLabel
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const factoryLabelsFactoryLabelsAPIFactory = {
  queries,
  mutations,
  getQrLabel,
  getQrLabelBase64,
  getComponentLabelBase64
}
