export function isArrayNullOrEmpty(arr: unknown) {
  return !Array.isArray(arr) || !arr.length
}

export function isArrayLength(arr: unknown[], length: number) {
  return isArrayNullOrEmpty(arr) ? false : arr.length === length
}

export function isArrayLengthGreaterOrEqual(arr: unknown[], length: number) {
  return isArrayNullOrEmpty(arr) ? false : arr.length >= length
}

export function isArrayLengthGreater(arr: unknown[], length: number) {
  return isArrayNullOrEmpty(arr) ? false : arr.length > length
}

export function filterByString(
  array: unknown[],
  propsToCheck: string[],
  searchQuery: string,
  numElements: number
) {
  const arr = array.filter((o) =>
    propsToCheck.some((k) =>
      // @ts-expect-error this is a general checker for all types
      o[k].toLowerCase().includes(searchQuery.toLowerCase())
    )
  )

  return numElements ? arr.slice(0, numElements) : arr
}

export function filterByStringArray(
  array: unknown[],
  propsToCheck: string[],
  searchQueryArray: string[],
  numElements: number
) {
  const arr = array.filter((o) =>
    propsToCheck.some((k) =>
      searchQueryArray.some((searchQuery) =>
        // @ts-expect-error this is a general checker for all types
        o[k].toLowerCase().includes(searchQuery.toLowerCase())
      )
    )
  )

  return numElements ? arr.slice(0, numElements) : arr
}
