import { type PrinterStateType } from './types'

export default {
  availablePrinters: [],
  printerConfigs: [],
  status: 'disconnected',
  loading: false,
  printing: false,
  checking: false,
  modals: {
    default_printer: false
  }
} as PrinterStateType
