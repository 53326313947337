<template>
  <NuxtPwaManifest />
  <NuxtLoadingIndicator />
  <NuxtLayout>
    <NuxtPage :transition="pageTransition" />
  </NuxtLayout>
</template>

<script setup lang="ts">
/**
 * ==============================
 * Base imports
 * ==============================
 */
import type { TransitionProps } from 'vue'

/**
 * ==============================
 * Page transition animations
 * ==============================
 */

/**
 * Represents the page transition configuration.
 * @typedef {Object} TransitionProps
 * @property {string} name - The name of the transition.
 * @property {string} mode - The mode of the transition.
 * @property {boolean} css - Indicates whether CSS is used for the transition.
 * @property {Function} onBeforeLeave - The function to be called before leaving the page.
 * @property {Function} onLeave - The function to be called when leaving the page.
 * @property {Function} onBeforeEnter - The function to be called before entering the page.
 * @property {Function} onEnter - The function to be called when entering the page.
 */
const pageTransition: TransitionProps = {
  name: 'page-transition',
  mode: undefined,
  css: false,
  onBeforeLeave: anim_onBeforeLeave,
  onLeave: anim_onLeave,
  onBeforeEnter: anim_onBeforeEnter,
  onEnter: anim_onEnter
}

/**
 * ==============================
 * Page Meta
 * ==============================
 */

/**
 * Watches the current route's meta object and updates the document head accordingly.
 *
 * @param {Function} callback - The callback function to be executed when the meta object changes.
 * @param {Object} options - The options object for the watch function.
 * @param {boolean} options.immediate - Whether to immediately invoke the callback function.
 */
watch(
  () => useRouter().currentRoute.value.meta,
  (meta) => {
    useHead({
      title: meta.title,
      titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} - Campfire` : 'Campfire'
      },
      meta: [{ ...meta.description }],
      script: [
        {
          src: '/js/animate.js',
          tagPosition: 'bodyClose',
          defer: 'true'
        }
      ]
    })
  },
  { immediate: true }
)
</script>
