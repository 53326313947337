import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the warehouse transfer page.
 * @auth Requires authentication
 */
const authAccountPageMeta: RouteMeta = {
  name: 'auth.account',
  title: 'My Account',
  path: '/auth/account',
  description: {
    name: 'description',
    content: 'View and manage your account details.'
  },
  order: 0,
  requiresAuth: true,
  show: false,
  permissions: []
}

export default authAccountPageMeta
