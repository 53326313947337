import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the Makes & Models page.
 */
const vfitsMakesModelsPageMeta: RouteMeta = {
  name: 'vfits.makes-models',
  title: 'Makes & Models',
  path: '/vfits/makes-models',
  description: {
    name: 'description',
    content: 'Makes & Models'
  },
  showOnNav: true,
  order: 2,
  requiresAuth: true,
  show: true,
  permissions: ['vfits makes and models | view']
}

export default vfitsMakesModelsPageMeta
