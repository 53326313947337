import type * as SDT from './types'
import type * as T from '~/factories/api/azure.api.types'

async function getSalesOrders(
  region: T.region,
  query: SDT.GetSalesOrdersQuery
): Promise<SDT.GetSalesOrdersResponse | null> {
  return await useWretch('frSysproAPI')
    .query(query)
    .get(`/order/api/Sales/${region}/search`)
    .json<SDT.GetSalesOrdersResponse>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getSalesOrders:', err)
      return null
    })
}

async function updateShippingDetails(
  region: T.region,
  salesOrderNumber: string,
  updateShippingDetailsRequest: SDT.UpdateShippingDetailsRequest
): Promise<boolean | null> {
  return await useWretch('frSysproAPI')
    .post(
      JSON.stringify(updateShippingDetailsRequest),
      `/warehouse/Shipping/Update/${region}/${salesOrderNumber}`
    )
    .res()
    .then((res) => {
      return res.status === 200 || res.status === 202 ? true : false
    })
    .catch((err) => {
      console.error('Error in updateShippingDetails:', err)
      return null
    })
}

export const warehouseShippingDetailsApiFactory = {
  getSalesOrders,
  updateShippingDetails
}
