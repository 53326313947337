import type { RouteMeta } from 'vue-router'

const catalogDetailsPageMeta: RouteMeta = {
  name: 'catalog.product-details',
  title: 'Product Details',
  path: '/catalog/product-details',
  description: {
    name: 'description',
    content: 'Catalog - Product Details'
  },
  showOnNav: true,
  order: 3,
  show: true,
  requiresAuth: true,
  permissions: []
}

export default catalogDetailsPageMeta
