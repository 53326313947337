/**
 * ================================================
 * Customers API Factory
 * This factory contains all the methods for interacting with the customers feature.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import {
  type QueryFunctionContext,
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import qs from 'qs'
import { type WritableComputedRef } from 'vue'
/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'

/**
 * ================================================
 * API ROUTES
 * ================================================
 */
const API_ROUTE = '/api/v2/customers'

/**
 * GetCustomers - Call
 */
const getCustomers = async (
  context: QueryFunctionContext
): Promise<T.getCustomersResponse> =>
  await useWretch('FsyncAPI')

    .url(`${API_ROUTE}/${context.queryKey[1]}?${context.queryKey[2]}`)
    .query(
      qs.stringify(
        {
          ...(context.queryKey[3] ?? {})
        },
        { encode: false, skipNulls: true }
      )
    )
    .get()
    .json<T.getCustomersResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * GetCustomers - Query
 */
const useGetCustomers = (payload: {
  target: 'b2b' | 'b2c'
  regions: WritableComputedRef<string>
  pagination: WritableComputedRef<{
    page: number
    rowsPerPage: number
    rowsNumber: number
    sortBy: string
    descending: boolean
    filter: string | null
  }>
  options?: UseQueryOptions<T.getCustomersResponse, Error>
}) =>
  useQuery({
    queryKey: [
      'customers',
      payload.target,
      payload.regions,
      payload.pagination
    ],
    queryFn: getCustomers,
    ...payload.options
  })

/**
 * getCustomer - Call
 */
const getCustomer = async (
  context: QueryFunctionContext
): Promise<T.getCustomerResponse> =>
  await useWretch('FsyncAPI')

    .url(`${API_ROUTE}/${context.queryKey[1]}/${context.queryKey[2]}`)
    .get()
    .json<T.getCustomerResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * getCustomer - Query
 */
const useGetCustomer = (payload: {
  target: 'b2b' | 'b2c'
  customerId: Ref<number | null>
  options?: UseQueryOptions<T.getCustomerResponse, Error>
}) =>
  useQuery({
    queryKey: ['customer', payload.target, payload.customerId],
    queryFn: getCustomer,
    ...payload.options
  })

/**
 * resetCustomerPassword - Call
 */
const resetCustomerPassword = async (
  payload: T.resetCustomerPasswordRequest
): Promise<T.resetCustomerPasswordResponse> =>
  await useWretch('FsyncAPI')

    .post(JSON.stringify(payload), `${API_ROUTE}/reset-password`)
    .json<T.resetCustomerPasswordResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * resetCustomerPassword - Mutation
 */
const useResetCustomerPassword = (payload: {
  options?: UseMutationOptions<
    T.resetCustomerPasswordResponse,
    Error,
    T.resetCustomerPasswordRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: resetCustomerPassword,
    ...payload.options
  })

/**
 * sendWelcomeEmail - Call
 */
const sendWelcomeEmail = async (
  payload: T.sendWelcomeEmailRequest
): Promise<T.sendWelcomeEmailResponse> =>
  await useWretch('FsyncAPI')

    .post(JSON.stringify(payload), `${API_ROUTE}/send-welcome-email`)
    .json<T.sendWelcomeEmailResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * sendWelcomeEmail - Mutation
 */
const useSendWelcomeEmail = (payload: {
  options?: UseMutationOptions<
    T.sendWelcomeEmailResponse,
    Error,
    T.sendWelcomeEmailRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: sendWelcomeEmail,
    ...payload.options
  })

/**
 * syncCompanyCredit - Call
 */
async function syncCompanyCredit(
  payload: T.syncCompanyCreditRequest
): Promise<T.syncCompanyCreditResponse> {
  return await useWretch('FsyncAPI')

    .post(JSON.stringify(payload), `${API_ROUTE}/sync-credit`)
    .json<T.syncCompanyCreditResponse>()
    .then((res) => res)
    .catch((err) => err)
}

/**
 * syncCompanyCredit - Mutation
 */
function useSyncCompanyCredit(payload: {
  options?: UseMutationOptions<
    T.syncCompanyCreditResponse,
    Error,
    T.syncCompanyCreditRequest,
    unknown
  >
}) {
  return useMutation({
    mutationFn: syncCompanyCredit,
    ...payload.options
  })
}

/**
 * syncCompanyDetails - Call
 */
async function syncCompanyDetails(
  payload: T.syncCompanyDetailsRequest
): Promise<T.syncCompanyDetailsResponse> {
  return await useWretch('FsyncAPI')

    .post(JSON.stringify(payload), `${API_ROUTE}/sync-details`)
    .json<T.syncCompanyDetailsResponse>()
    .then((res) => res)
    .catch((err) => err)
}

/**
 * syncCompanyDetails - Mutation
 */
function useSyncCompanyDetails(payload: {
  options?: UseMutationOptions<
    T.syncCompanyDetailsResponse,
    Error,
    T.syncCompanyDetailsRequest,
    unknown
  >
}) {
  return useMutation({
    mutationFn: syncCompanyDetails,
    ...payload.options
  })
}

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetCustomers,
  useGetCustomer
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  useResetCustomerPassword,
  useSendWelcomeEmail,
  useSyncCompanyCredit,
  useSyncCompanyDetails
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const customersApiFactory = {
  queries,
  mutations
}
