import { type PiniaPluginContext } from 'pinia'
import localForage from 'localforage'


export default defineNuxtPlugin(() => {
  const localForageState = useState<{
    [key: string]: any
  }>('localForageState', () => ({}))

  localForage.config({
    driver: localForage.INDEXEDDB,
    name: 'campfire',
    version: 1.0,
    storeName: 'campfire',
    description: 'Campfire: A Front Runner App'
  })

  async function indexDbPlugin({ store }: PiniaPluginContext) {
    const stored = await localForage.getItem(store.$id + '-state')
    if (stored) {
      store.$patch(stored)
    }
    store.$subscribe(() => {
      localForage.setItem(
        store.$id + '-state',
        JSON.parse(JSON.stringify(store.$state))
      ) // Destructure to transform to plain object
    })
  }

  usePinia().use(
    indexDbPlugin
    // createPersistedState({
    //   // plugin options goes here
    //   storage: {
    //     getItem: (key) => {
    //       if (import.meta.client) {
    //         return localforage.getItem(key)
    //       }

    //       return localForageState.value[key]
    //     },
    //     setItem: (key, value) => {
    //       if (import.meta.client) {
    //         localForageState.value[key] = value
    //         return localforage.setItem(key, value)
    //       }

    //       localForageState.value[key] = value
    //     },
    //   }
    // })
  )
})
