import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the sales - commercial warranty invoice page.
 */
const salesCommercialWarrantyInvoiceMeta: RouteMeta = {
  name: 'sales.commercial-warranty-invoice',
  title: 'Commercial Warranty Invoice',
  path: '/sales/commercial-warranty-invoice',
  order: 5,
  show: true,
  requiresAuth: true,
  permissions: ['sales commercial warranty invoice | view']
}

export default salesCommercialWarrantyInvoiceMeta
