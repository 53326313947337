import type { RouteMeta } from 'vue-router'

const catalogRelationshipsPageMeta: RouteMeta = {
  name: 'catalog.product-relationships',
  path: '/catalog/product-relationships',
  title: 'Product Relationships',
  description: {
    name: 'description',
    content: 'Catalog - Product Relationships'
  },
  showOnNav: true,
  order: 3,
  show: true,
  requiresAuth: true,
  permissions: ['catalog product relationships | view']
}

export default catalogRelationshipsPageMeta
