/**
 * ================================================
 * Factory API
 * This factory contains all the methods for interacting with the factory feature.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import { Notify } from 'quasar'
import qs from 'qs'
import {
  type QueryFunctionContext,
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import { type WritableComputedRef } from 'vue'
/**
 * ================================================
 * Custom packages
 * ================================================
 */
import type * as T from './types'

/**
 * assignBarcode Call
 */
const assignBarcode = async (
  payload: T.assignBarcodeRequest
): Promise<T.assignBarcodeResponse> =>
  await useWretch('FrAPI')
    .post(
      JSON.stringify(payload),
      `/factory/Uom/barcode/assign/${payload.stockCode}`
    )
    .json<T.assignBarcodeResponse>()
    .then((res) => {
      Notify.create({
        message: `Barcode assigned for ${payload.stockCode}`,
        color: 'positive',
        icon: 'check'
      })
      return res
    })
    .then((res) => res)
    .catch((err) => err)

/**
 * assignBarcode mutation
 */
const useAssignBarcode = (payload: {
  options?: UseMutationOptions<
    T.assignBarcodeResponse,
    Error,
    T.assignBarcodeRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: assignBarcode,
    ...payload.options,
    onError: () =>
      Notify.create({
        message: 'Unable to assign barcode',
        color: 'red'
      })
  })

/**
 * getStockCodeInformation Call
 */
const getStockCodeInformation = async (
  context: QueryFunctionContext
): Promise<T.getStockCodeInformationResponse> =>
  await useWretch('FrAPI')
    .query(
      qs.stringify({
        RetailOnly: false
      })
    )
    .get(`/product/product/detail/${context.queryKey[1]}`)
    .json<T.getStockCodeInformationResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * getStockCodeInformation query
 */
const useGetStockCodeInformation = (payload: {
  stockCode?: WritableComputedRef<string>
  options?: UseQueryOptions<T.getStockCodeInformationResponse, Error>
}) =>
  useQuery({
    queryKey: ['getStockCodeInformation', payload.stockCode],
    queryFn: getStockCodeInformation,
    ...payload.options
  })

async function getTopLevelJobs(): Promise<T.WIPOpenMaster[]> {
  return await useWretch('FrAPI')
    .get('/factory/WIP')
    .json<T.WIPOpenMaster[]>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getTopLevelJobs:', err)
      return []
    })
}

async function getProductInformation(
  stockCode: string,
  retailOnly: boolean = true
): Promise<T.ProductInformationResponse | null> {
  return await useWretch('FrAPI')
    .get(`/product/Product/detail/${stockCode}?RetailOnly=${retailOnly}`)
    .json<T.ProductInformationResponse>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getProductDetails:', err)
      return null
    })
}

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetStockCodeInformation
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  useAssignBarcode
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const factoryAPIFactory = {
  queries,
  mutations,
  getTopLevelJobs,
  getProductInformation
}
